import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { CrudService } from 'src/app/services/crud.service';

@Component({
  selector: 'app-documentos-gestion',
  templateUrl: './documentos-gestion.component.html',
  styleUrls: ['./documentos-gestion.component.scss']
})
export class DocumentosGestionComponent implements OnInit, OnDestroy {

  resolucionesTipo: any = {
    resolucion_gerencia: {
      titulo: "Resoluciones de Gerencia General",
      id: 1
    },
    resolucion_gaf: {
      titulo: "Resoluciones GAF",
      id: 2
    },
    convenios: {
      titulo: "Convenios",
      id: 3
    },
    directivas: {
      titulo: "Directivas",
      id: 4
    },
    normatividad: {
      titulo: "Normatividad",
      id: 5
    }
  };

  anios: Array<any> = [
    {
      id: 1,
      nombre: '2020'
    },
    {
      id: 2,
      nombre: '2019'
    },
    {
      id: 3,
      nombre: '2018'
    },
    {
      id: 4,
      nombre: '2017'
    }
  ];

  evento: Subscription;
  request: any = {};

  paramName: any;
  selectResolucionItem: any;

  resoluciones: Array<any> = [];
  isLoading: boolean = true;

  buscar: string;
  itemsPerPage: number = 10;
  pagina: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _crud: CrudService
  ) {
    this.paramName = this.activatedRoute.snapshot.paramMap.get("id");
    this.selectResolucionItem = this.resolucionesTipo[this.paramName];
  }

  ngOnInit() {
    this.evento = this.router.events.subscribe((val) => {
      this.paramName = this.activatedRoute.snapshot.paramMap.get("id");
      this.selectResolucionItem = this.resolucionesTipo[this.paramName];
      this.request.anio = this.anios[0].nombre;
      this.list();
    });
    this.request.anio = this.anios[0].nombre;
    this.list();
  }

  list() {
    this.isLoading = true;
    this.request.slug = this.paramName;
    this._crud.post('resolucion/show', this.request).then((res) => {
      this.resoluciones = res.info;
      this.isLoading = false;
    })
      .catch(() => {
        this.isLoading = false;
      });
  }

  changeAnio(anio) {
    this.request.anio = anio;
    this.list();
  }

  ngOnDestroy() {
    this.evento.unsubscribe();
  }

}
