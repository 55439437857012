import { Component, ElementRef, OnInit, } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { DomSanitizer} from '@angular/platform-browser';
import { BeliService } from 'src/app/services/beli.service';
import Swal from 'sweetalert2';
import { TipoReclamo,SubtipoReclamo} from 'src/app/models/tipoReclamo.interface';
import { TipoReclamosDataService} from 'src/app/services/tipo-reclamos-data.service';

@Component({
  selector: 'app-solicitud-reclamaciones',
  templateUrl: './solicitud-reclamaciones.component.html',
  styleUrls: ['./solicitud-reclamaciones.component.scss'],
  providers:[TipoReclamosDataService]
})

export class SolicitudReclamacionesComponent  implements OnInit {
  html = '<span class="btn btn-danger waves-light">Your HTML here</span>';
    
  public previsualizacion: string;
  public uploadedFiles:any=[];
  invalidFile=false;
  invalidContrato=false;
  invalidDeclaracion=false;
  invalidMedioProbatorio=false;
  mostrarReciboEjemplo=false;
  tipoProblemaValid=false;
  verBotonImprimir=false;
  generateDescarga=false;
  isLoading: boolean = false;
  
  sendMailSolicitud:any;
  getSolicitudPdf:any;
  ruta3:any;

  formModel:FormGroup;
  
  isSending = false;
  isDisable:boolean;
  isDisableSearch : boolean = true;
  isExists:boolean;
  datos:any;
  mensaje : string;
  deshabilitado: boolean = true;
  message : string;
  codigo:any;
  codigo_ficha : string;
  valor : string;
  Ocultar:boolean = false;
  propietario : String;
  respuesta : [];
  response =  {
    success: false,
    message: ''
  }
  tipo_solicitante =[
    {id:1,nombre:'Titular'},
    {id:2,nombre:'Propietario del Predio'},
    {id:3,nombre:'Usuario Efectivo del Servicio'},
    {id:4,nombre:'Representante Legal'},
    {id:5,nombre:'Otro'},
  ];
  // 
  tipo_documento = [
    {id:1,nombre: 'DNI'},
    {id:2,nombre: 'RUC'},
    {id:3,nombre: 'Ce'},
  ];
  generar_codigo(){
    this.beli.get("page/cod_reclamo").then( data => {
      this.respuesta = data.success ? data.info : [];
      this.codigo = Object.values(this.respuesta);
      this.codigo_ficha = this.codigo[1];
    })
    .catch();
  }

  // LLAMAR RYUTA DE DESCARGA PDF
  url: string;
  path = this.beli.path()+'../../public/api/page/imprimir_reclamo_Pdf';


  public selectedTipoReclamo:TipoReclamo={id:0, name:''};
  public tipoReclamo:TipoReclamo[];
  public subTipoReclamo:SubtipoReclamo[];

  constructor(
    public formBuilder : FormBuilder, 
    private beli: BeliService,
    private sanitizer:DomSanitizer,
    private dataTipoReclamos: TipoReclamosDataService,
  ) { 
    
    this.formModel = this.formBuilder.group({
      hoja_reclamacion: [''],
      fecha: [this.formatDate()],
      cut_numero: ['',[
          Validators.required,
          Validators.compose([Validators.required,
            Validators.max(999999999999999)]),
          Validators.pattern(/^[0-9]+(\.?[7-7]+)?$/)
        ]
      ],
      tipo_solicitante_id: [null,Validators.required],
      documento_identidad_id:[null,Validators.required],
      documento_identidad : ['',[
          Validators.compose([Validators.required,
            Validators.max(999999999999999)]),
          Validators.required,
          Validators.pattern(/^[0-9]+(\.?[7-7]+)?$/)
        ]
      ],
      propietario: ['', [
      ]],
      nombres:['',[
        Validators.required,
        Validators.maxLength(60),
        Validators.pattern(/[A-Za-z0-9]{1,20}/),
        Validators.pattern(/^[a-z áãâäàéêëèíîïìóõôöòúûüùçñ]+$/i)
        ]
      ],
      apellido_paterno: ['',[
        Validators.required,
        Validators.maxLength(60),
        Validators.pattern(/[A-Za-z0-9]{1,20}/),
        Validators.pattern(/^[a-z áãâäàéêëèíîïìóõôöòúûüùçñ]+$/i)
        ]
      ],
      apellido_materno: ['',[
        Validators.required,
        Validators.maxLength(60),
        Validators.pattern(/[A-Za-z0-9]{1,20}/),
        Validators.pattern(/^[a-z áãâäàéêëèíîïìóõôöòúûüùçñ]+$/i)
        ]
      ],
      email: ['',[
          Validators.required,
          Validators.maxLength(80),
          Validators.pattern(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
          )
        ]
      ],
      celular:['',[
        Validators.required,
        Validators.compose([Validators.required,
          Validators.min(100000000),
          Validators.max(999999999)]),
        Validators.pattern(/^[0-9]+(\.?[7-7]+)?$/)
      ]],
      telefono: ['',[Validators.maxLength(15)]],

      copia_dni : [null],
      copia_dni_validar:['',[
        Validators.required,
      ]],
      contrato_alquiler : [''],
      contrato_alquiler_validar : [''],
      declaracion_jurada : [''],
      declaracion_jurada_validar : [''],
      medio_probatorio : [''],
      medio_probatorio_validar : [''],

      aceptar_notificacion:['',[Validators.required]],

      razon_social:[''],
      provincia_predio:['',[
        Validators.required,
      ]],
      distrito_predio:['',[
        Validators.required,
      ]],
      direccion_predio:['',[
        Validators.required,
      ]],
      tipo_direccion_predio:['',[
        Validators.required,
      ]],
      n_direccion_predio:['',[
        Validators.required,
      ]],
      mz_predio:['',[
        Validators.required,
      ]],
      lote_predio:['',[
        Validators.required,
      ]],

      provincia_procesal:['',[
        Validators.required,
      ]],
      distrito_procesal:['',[
        Validators.required,
      ]],
      direccion_procesal:['',[
        Validators.required,
      ]],
      tipo_direccion_procesal:['',[
        Validators.required,
      ]],
      n_direccion_procesal:['',[
        Validators.required,
        Validators.maxLength(60),
        Validators.pattern(/[A-Za-z0-9]{1,20}/)
      ]],
      mz_procesal:['',[
        Validators.required,
        Validators.maxLength(60),
        Validators.pattern(/[A-Za-z0-9]{1,20}/)
      ]],
      lote_procesal:['',[
        Validators.required,
      ]],
      cod_postal_procesal:['',[
        Validators.required,
      ]],

      tipo_reclamo:['',[Validators.required]],
      cod_reclamo:['',[Validators.required]],

      descripcion_problema: ['',[
          Validators.required,
          Validators.maxLength(1000),
          Validators.pattern(/[A-Za-z0-9]{1,20}/)
        ]
      ],
      
    });
  }
  
  ngOnInit(): void {
    this.tipoReclamo = this.dataTipoReclamos.getTipoReclamos();
    this.generar_codigo();
  }
  get formValidate(){
    return this.formModel.controls;
  }

  onSelectTipoReclamo(id:number):void{
    this.subTipoReclamo=this.dataTipoReclamos.getSubtipoReclamos()
    .filter(item=>item.tipoReclamoId==id);
  }
  
  onFileChange(event){
    const file = event.target.files[0];
    this.formModel.patchValue({
      copia_dni: file
    });
    const validarFile=event.target.files[0].type=="application/pdf";
    const validarSize=event.target.files[0].size<="2000000"
    if(validarFile&&validarSize){
      this.extraeBase64(file);
      this.formModel.get('copia_dni').updateValueAndValidity();
      this.invalidFile=false;
      this.isSending=false;
    }else{
      this.invalidFile=true;
      this.isSending=true;
    }
    
  }
  onFileContratoAlquiler(event){
    const file = event.target.files[0];
    this.formModel.patchValue({
      contrato_alquiler: file
    });
    const validarFile=event.target.files[0].type=="application/pdf";
    const validarSize=event.target.files[0].size<="2000000"
    if(validarFile&&validarSize){
      this.extraeBase64(file);
      this.formModel.get('contrato_alquiler').updateValueAndValidity();
      this.invalidFile=false;
      this.isSending=false;
    }else{
      this.invalidFile=true;
      this.isSending=true;
    }
  }

  onFileDeclaracionJurada(event){
    const file = event.target.files[0];
    this.formModel.patchValue({
      declaracion_jurada: file
    });
    const validarFile=event.target.files[0].type=="application/pdf";
    const validarSize=event.target.files[0].size<="2000000"
    if(validarFile&&validarSize){
      this.extraeBase64(file);
      this.formModel.get('declaracion_jurada').updateValueAndValidity();
      this.invalidDeclaracion=false;
      this.isSending=false;
    }else{
      this.invalidDeclaracion=true;
      this.isSending=true;
    }
  }
  onFileMedioProbatorio(event){
    const file = event.target.files[0];
    this.formModel.patchValue({
      medio_probatorio: file
    });
    const validarFile=event.target.files[0].type=="application/pdf";
    const validarSize=event.target.files[0].size<="2000000"
    if(validarFile&&validarSize){
      this.extraeBase64(file);
      this.formModel.get('medio_probatorio').updateValueAndValidity();
      this.invalidMedioProbatorio=false;
      this.isSending=false;
    }else{
      this.invalidMedioProbatorio=true;
      this.isSending=true;
    }
  }

  autenticar():any {
    try {
      var formData: any = new FormData();
        formData.append("hoja_reclamacion", this.formModel.get('hoja_reclamacion').value);
        formData.append("fecha", this.formModel.get('fecha').value);
        formData.append("cut_numero", this.formModel.get('cut_numero').value);
        formData.append("tipo_solicitante_id", this.formModel.get('tipo_solicitante_id').value);
        formData.append("documento_identidad_id", this.formModel.get('documento_identidad_id').value);
        formData.append("documento_identidad", this.formModel.get('documento_identidad').value);
        formData.append("nombres", this.formModel.get('nombres').value);
        formData.append("apellido_paterno", this.formModel.get('apellido_paterno').value);
        formData.append("apellido_materno", this.formModel.get('apellido_materno').value);
        formData.append("email", this.formModel.get('email').value);
        formData.append("celular", this.formModel.get('celular').value);
        formData.append("telefono", this.formModel.get('telefono').value);
        formData.append("copia_dni", this.formModel.get('copia_dni').value);        
        formData.append("contrato_alquiler", this.formModel.get('contrato_alquiler').value);
        formData.append("declaracion_jurada", this.formModel.get('declaracion_jurada').value);
        formData.append("medio_probatorio", this.formModel.get('medio_probatorio').value);
        formData.append("descripcion_problema", this.formModel.get('descripcion_problema').value);
        
        formData.append("aceptar_notificacion", this.formModel.get('aceptar_notificacion').value);

        formData.append("razon_social", this.formModel.get('razon_social').value);
        formData.append("provincia_predio", this.formModel.get('provincia_predio').value);
        formData.append("distrito_predio", this.formModel.get('distrito_predio').value);
        formData.append("direccion_predio", this.formModel.get('direccion_predio').value);
        formData.append("tipo_direccion_predio", this.formModel.get('tipo_direccion_predio').value);
        formData.append("n_direccion_predio", this.formModel.get('n_direccion_predio').value);
        formData.append("mz_predio", this.formModel.get('mz_predio').value);
        formData.append("lote_predio", this.formModel.get('lote_predio').value);
        formData.append("provincia_procesal", this.formModel.get('provincia_procesal').value);
        formData.append("distrito_procesal", this.formModel.get('distrito_procesal').value);
        formData.append("direccion_procesal", this.formModel.get('direccion_procesal').value);
        formData.append("tipo_direccion_procesal", this.formModel.get('tipo_direccion_procesal').value);
        formData.append("n_direccion_procesal", this.formModel.get('n_direccion_procesal').value);
        formData.append("mz_procesal", this.formModel.get('mz_procesal').value);
        formData.append("lote_procesal", this.formModel.get('lote_procesal').value);
        formData.append("cod_postal_procesal", this.formModel.get('cod_postal_procesal').value);
        formData.append("tipo_reclamo", this.formModel.get('tipo_reclamo').value);
        formData.append("cod_reclamo", this.formModel.get('cod_reclamo').value);
      
      
      this.sendForm(formData);
        setTimeout(() =>{ 
          this.response.success = false;
        },10000);
      
    } catch (error) {

    }
    
  }

  sendForm (data) {
    this.isSending = true;
    this.beli.post('page/solicitud_reclamos',data).then( data => { 
      this.response = data.success ? data : [];
      this.isSending = false;
      Swal.fire({
        icon: 'success',
        title: 'Se ha enviado su solicitud de reclamo',
        showConfirmButton: false,
        timer: 4000
      });
      this.sendMail(this.sendMailSolicitud);
      this.testgetPdf();
      this.formModel.reset();
    }),
    console.log('sendForm',this.response);
  }
  
  sendMail(sendMailSolicitud){
    this.beli.get('page/solicitud_reclamos').then(data => {
      this.sendMailSolicitud = data.success ? data.info : [];
      Swal.fire({
        icon: 'success',
        title: 'Revise su bandeja de entrada',
        showConfirmButton: false,
        text:  data['message'],
        timer: 8000,
      }).then(function(){
        location.href = '/';
      });
      }, error => console.error(error));
      return sendMailSolicitud;
  }
  // OFF USE
  getPdf () {
    this.isLoading  = true;
    this.beli.get('page/imprimir_reclamo_Pdf').then(data => {
      this.getSolicitudPdf = data.success ? data.info : [];
      }, 
      error => console.error(error)
    );
    var ruta2='../../public/api/page/imprimir_reclamo_Pdf'
    this.getSolicitudPdf=this.getSolicitudPdf+ruta2
    console.log('urlFuera',this.path);
    window.open(this.path);
    // return getSolicitudPdf;
  }
  testgetPdf(){
    window.open(this.path,"","width=200,height=200,menubar=no,toolbar=no,location=no,status=no");
  }

  formatDate() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  formatTime (){
    var d = new Date();
    return d.toLocaleTimeString();
  }

  extraeBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();
      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };

    } catch (e) {
      return null;
    }
  })

  mostrarReciboDeEjemplo(){
    Swal.fire({
      text: 'Recibo de ejemplo',
      imageUrl: 'assets/img/inicio/solicitud-reclamos/reciboEjemplo.png',
      imageHeight: 500,
      imageWidth: 800
    })
  }

  validarSuministro(){
    if(this.formModel.get('cut_numero').value == ''){
      this.capturar();
    }else if (this.formModel.get('cut_numero').value == null){
      this.capturar();
    }else{
      this.capturar();
      this.beli.get("page/consulta_usuario?idsucursal=1&textbusqueda="+this.formModel.get('cut_numero').value).then( data => {
        this.respuesta = data.success ? data.info : [];
        if(this.respuesta.length > 0){
          Swal.fire({
            title: '<small><b>¡SUMINISTRO CORRECTO!</b></small>',
            icon: 'success',
            html: '<p style=font-family: Georgia, Times New Roman, Times, serif;>A continuación llene correctamente el formulario de solicitud de reclamos.</p>',
              
            focusConfirm: false,
            confirmButtonText:
              '<i class="fa fa-thumbs-up"></i> Continuar',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            cancelButtonText:
              '<i class="fa fa-thumbs-down"></i>',
            cancelButtonAriaLabel: 'Thumbs down'
          })
          this.datos= Object.values(this.respuesta);
          this.propietario = (this.datos[0]['propietario']);
          this.isExists = true;
          this.isDisable = true;
          this.Ocultar = true;
        }else{
          this.capturar();
          Swal.fire({
             title: '<small><b>¡ADVERTENCIA!</b></small>',
            icon: 'warning',
            html:
                '<p>El número de suministro ingresado <b>NO EXISTE!&nbsp;&nbsp;</b> Por favor asegurese de haber escrito correctamente su número de suministro y vuelva a intentarlo.</p>' +
                '<small><b>Para mayor información consulte al botón de ayuda <i class="fa fa-question-circle"></i></b></small>',
            allowOutsideClick: false,
            confirmButtonText:
              '<i class="fa fa-arrow-left"></i> Volver',
          })
          this.formModel.get('cut_numero').reset();
        }
    })
    .catch();
    }
  }

  capturar(){
    if(this.formModel.get('cut_numero').value == null){
       this.mensaje = 'Ingrese su número de suministro correctamente. Consulte el botón de ayuda al lado derecho.';
       this.isDisableSearch = true;
    }else if(this.formModel.get('cut_numero').value == ''){
       this.mensaje = 'Ingrese su número de suministro por favor.';
       this.isDisableSearch = true;
    }else{
      this.mensaje = '';
      this.isDisableSearch = false;
    }
  }

  capturarTipo(){
    if(this.formModel.get('documento_identidad_id').value == '1'){
      this.valor = this.formModel.get('documento_identidad').value;
        if((this.valor.toString()).length < 8 || (this.valor.toString()).length > 8){
          this.message = 'El numero de DNI debe de contener 8 dígitos';
          this.deshabilitado = true;
        }else if(this.formModel.get('documento_identidad').value == null || this.formModel.get('documento_identidad').value == ''){
          this.message = 'Ingrese su número de DNI';
          this.deshabilitado = true;
        }
        else{
          this.message = '';
          this.deshabilitado = false;
        }
    }else if(this.formModel.get('documento_identidad_id').value == '2'){
      this.valor = this.formModel.get('documento_identidad').value;
        if((this.valor.toString()).length < 11 || (this.valor.toString()).length > 11){
          this.message = 'El numero de RUC debe de contener 11 digitos';
          this.deshabilitado = true;        
       }else if(this.formModel.get('documento_identidad').value == null || this.formModel.get('documento_identidad').value == ''){
        this.message = 'Ingrese su número de RUC';
        this.deshabilitado = true;
       }
       else{
        this.message = '';
        this.deshabilitado = false;
       }
    }
  }

  capturarDni(){

    if(this.formModel.get('documento_identidad_id').value == null){
      this.message = 'Elija primero el tipo de documento';
      this.deshabilitado = true;
    }else if(this.formModel.get('documento_identidad_id').value == '1'){
      if(this.formModel.get('documento_identidad').value == null || this.formModel.get('documento_identidad').value == ''){
        this.message = 'Ingrese su número de DNI';
        this.deshabilitado = true;
     }else{
        this.valor = this.formModel.get('documento_identidad').value;
        if((this.valor.toString()).length < 8 || (this.valor.toString()).length > 8){
          this.message = 'El numero de DNI debe de contener 8 dígitos';
          this.deshabilitado = true;
       }else{
        this.message = '';
        this.deshabilitado = false;
       }
     }
    }else if(this.formModel.get('documento_identidad_id').value == '2'){
      this.message = ''
      if(this.formModel.get('documento_identidad').value == null || this.formModel.get('documento_identidad').value == ''){
        this.message = 'Ingrese su número de RUC';
        this.deshabilitado = true;
     }else{
        this.valor = this.formModel.get('documento_identidad').value;
        if((this.valor.toString()).length < 11 || (this.valor.toString()).length > 11){
          this.message = 'El numero de RUC debe de contener 11 digitos';
          this.deshabilitado = true;
          
       }else{
        this.message = '';
        this.deshabilitado = false;
       }
     }
    }else{
      this.message = '';
      this.deshabilitado = false;
    }
}


  helpUbicacionPredio(){
    Swal.fire({
      text: 'Indicar la ubicación del medidor',
    })
  }
  helpDomicilioProcesal(){
    Swal.fire({
      text: 'Indicar la ubicación a donde se le enviara la documentacion',
    });
  }

  helpTest(){
    Swal.fire({
      text: 'Tipo - 1',
      imageUrl: 'assets/img/inicio/solicitud-reclamos/fila1.png',
      width:'1500px',
    })
  }
  helpDetalleReclamo(){
    Swal.fire({
      icon:'question',
      title: 'Haga click en el botón "Lista de Problemas" para poder ver'+
      ' el tipo de problema con su respectivo codigo',
  });
  }
  helpReclamoRojo(){
    Swal.fire({
      imageUrl: 'assets/img/inicio/solicitud-reclamos/reclamoRojo.png',
      width:'1200px',
    })
  }
  helpReclamoAmarillo(){
    Swal.fire({
      imageUrl: 'assets/img/inicio/solicitud-reclamos/reclamoAmarillo.png',
      width:'1200px',
    })
  }
  helpListaReclamos(){
    Swal.fire({
      title: '<strong>Ver lista de reclamos</strong>',
      icon: 'info',
      html:
        '<a href="assets/docs/inicio/solicitud-reclamos/132107092021ListaProblemas132107092021.pdf"'+
        'target="_blank" rel="noopener noreferrer"class="advertenciaSolRec">Click para ver la lista</a> ',
      showCloseButton: true,
      focusConfirm: false,
      cancelButtonAriaLabel: 'Thumbs down'
    })
  }
}