<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 style="text-align: justify"> Convocatorias</h2>
        </div>
    </div>
</section>

<section class="about-area ptb-100" style="background-color: #fdfdfd;">

  <div class="container" style="text-align: center;">
    <div class="row">
          <div class="col-md-4 icon-container" style="margin-bottom: 50px;"><a href="#/convocatorias/decreto"><img class="img-responsive" style="max-height: 285px !important" src="assets/img/inicio/home/Imagen11.png"></a></div>
          <div class="col-md-4 icon-container" style="margin-bottom: 50px;"><a href="#/convocatorias/practicas"><img class="img-responsive" style="max-height: 275px !important" src="assets/img/inicio/home/Imagen12.png"></a></div>
      </div>
    </div>
</section>
 
<app-footer></app-footer>