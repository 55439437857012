<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 class="mb-1">Transparencia</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>     
                <li>Transparencia</li>
            </ul>
        </div>
    </div>
</section>

<section class="faq-area ptb-100 bg-f9f9f9">     
    <div class="container">
        <div class="row g-2">
            <div class="col-md">
                <h3 style="font-weight: bold;">PORTAL DE TRANSPARENCIA ESTÁNDAR</h3>
                <br>
                <div>
                    <p>El Portal de Transferencia Estándar es de información única, integral y estandarizado para mejorar y dar mayor transparencia a la gestión pública.</p>
                    <h6>¿Qué es el Portal de Transparencia Estándar (PTE)?</h6>
                    <p>Es una herramienta informática de información estandarizada e integral, para facilitar el acceso a la información sobre el uso de los recursos públicos y de gestión institucional e incrementar los niveles de transparencia.</p>
                    <br>
                    <h6>Visita el Portal de Transparencia de EMAPA HUARAL S.A.</h6>
                    <h6>Haz Click en la imagen → </h6>
                </div>
                
            </div>
            <div class="col-md">
                <a href="https://www.transparencia.gob.pe/enlaces/pte_transparencia_enlaces.aspx?id_entidad=13694&id_tema=1&ver=D#.Ykx34FXMKUk" target="_blank"><img src="assets/img/portal-transparencia.png" alt="Transparencia" border="0"></a>
                <h5 style="font-size: smaller">Los contenidos emitidos en el Portal de transparencia estándar, dependen del rendimiento y disponibilidad de los servidores del Portal del Estado Peruano.</h5>
            </div>
          </div>
    </div>
</section>

<app-footer></app-footer>