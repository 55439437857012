import { Component, OnInit } from '@angular/core';
import { BeliService } from 'src/app/services/beli.service';

declare let $ : any;

@Component({
  selector: 'app-control-interno',
  templateUrl: './control-interno.component.html',
  styleUrls: ['./control-interno.component.scss']
})
export class ControlInternoComponent implements OnInit {

  url: string;
  isLoading: boolean = false;

  // tslint:disable-next-line: variable-name
  control_interno: {
    src_documento: string
  };

  path = this.beli.path();

  constructor(
    private beli: BeliService,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData () {
    this.isLoading  = true;
    this.beli.get('page/control_interno_etico').then( data => {
      this.control_interno = data.success ? data.info : [];
      this.url = this.path + this.control_interno.src_documento;
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }

  displayTest(){
    setTimeout( () => { $("#preloader-control")[0].style.display = "none"; }, 1500);
    setTimeout( () => { $("#iframe-control")[0].style.display = "block"; },1500);
 }

}
