<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2> Formulario Libro de Observaciones </h2>
        </div>
    </div>
</section>

<section class="faq-area ptb-100 bg-f9f9f9" *ngIf="!Ocultar">
    <div class="container">
        <div class="container">
            <div class="container">
                <div class="alert alert-info alert-dismissable">
                    <button type="button" class="close" data-dismiss="alert"><b>x</b></button>
                    <i class="fas fa-exclamation-circle"></i> <strong style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif"><img src="assets/img/info.png" align="right" width="74px" alt="gotas"> ¡INFORMACIÓN!</strong>
                    <div class="container-sm">
                            <p style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; text-align: center;" class="mb-0">Estimado usuario, para acceder al formulario del <b>Libro de Observaciones</b> de EMAPA HUARAL S.A debemos validar su codigo de suministro, por favor ingrese su código de suministro y presione el botón <b>BUSCAR</b> para validar.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row my-4 justify-content-center">
            <div class="col-md-10 card shadow mx-2 border">
                <form class="border-form" [formGroup]="formModel" class="p-4">   
                    <div class="form-group row">            
                    <div class="col-sm-12">
                        <h6 style="font-family: Georgia, 'Times New Roman', Times, serif;" for="select-beast-selectized">NÚMERO DE SUMINISTRO </h6>
                    </div>
                    <div class="col-sm-11">
                        <div class="input-group">
                            <input type="number" class="form-control" 
                             formControlName="cut_numero" placeholder="Ingrese su número de suministro" [attr.disabled]="isDisable" (ngModelChange)="capturar()">
                             <button class="btn btn-secondary" type="button" [disabled]="isDisable" (click)="validarSuministro()"> 
                                <span class="fas fa-search"></span> BUSCAR</button>
                            </div>
                        <small class="text-danger mt-1 px-2 py-1 rounded">
                            {{mensaje}}
                        </small>
                    </div>
                    <div class="col-sm-1">
                        <button type="button" class="btn btn-danger btn-sm borderReciboEjemplo"
                            (click)="mostrarReciboDeEjemplo()">
                            <span class="fa fa-question-circle fa-2x"></span></button>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>
</section>

<section class="doctors-area pt-100 pb-70" *ngIf="Ocultar">
    <div class="container">
        <h5 class ="advertenciaTipoRec">Los campos marcados con (*) son obligatorios</h5>
    </div>
    <div class="container">
        <form class="border-form" (submit)="submit()" [formGroup]="formModel">
            <fieldset  class="fieldset">
                <div class="form-group row">
                    <div class="container">
                        <div class="container">
                            <div class="container">
                                <div class="form-group row">
                                    <div class="col-sm-2">
                                        <label for="colFormLabelSm">
                                            Fecha de Registro
                                        </label>
                                    </div>
                                    <div class="col-sm-3">
                                         <input type="date" disabled class="form-control form-control-sm" formControlName="fecha">
                                    </div>
                                    <div class="col-sm-3">
                                        <label>
                                        Código de Suministro
                                        <span class="advertenciaSolRec">
                                            (*)
                                        </span>
                                        </label>
                                    </div>
                                    <div class="col-sm-3">
                                        <input type="number"  class="form-control form-control-sm" 
                                        formControlName="cut_numero" placeholder="N° de suministro">
                                        <small class="bg-danger text-light mt-1 px-2 py-1 rounded"
                                            *ngIf="(formValidate.cut_numero.dirty || formValidate.cut_numero.touched) 
                                            && formValidate.cut_numero.errors"
                                            >
                                            <ng-container [ngSwitch]="formValidate.cut_numero.value">
                                                <ng-container *ngSwitchCase="''">
                                                    N° de suministro Obligatorio
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    N° de suministro invalido
                                                </ng-container>
                                            </ng-container>
                                        </small>
                                    </div>
                                     <div class="col-sm-1">
                                        <button type="button" class="btn btn-danger btn-sm borderReciboEjemplo"
                                        (click)="mostrarReciboDeEjemplo()">
                                        <span class="fa fa-info"></span>
                                        </button>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>

                        <br>
            <!--DATOS DEL SOLICITANTE-->
            <fieldset  class="fieldset">
                <legend class="legend-border">I. DATOS DEL ADMINISTRADO </legend>
                    <div class="container">
                        <div class="container">
                            <div class="container">
                                <div class="form-group row">
                                    <div class="col-sm-3">
                                        <label for="colFormLabelSm">
                                            Tipo de Persona
                                            <span class="advertenciaSolRec">
                                                (*)
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" formControlName="tipo_persona_id" id="inlineRadio1" value="1">
                                            <label class="form-check-label" for="inlineRadio1">Natural </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" formControlName="tipo_persona_id" id="inlineRadio2" value="2">
                                            <label class="form-check-label" for="inlineRadio2">Jurídica</label>
                                        </div>
                                        <small class="text-danger mt-1 px-2 py-1 rounded"
                                        *ngIf="(formValidate.tipo_persona_id.dirty || formValidate.tipo_persona_id.touched) 
                                        && formValidate.tipo_persona_id.errors"
                                        >
                                        <ng-container [ngSwitch]="formValidate.tipo_persona_id.value">
                                            <ng-container *ngSwitchCase="''">
                                                Tipo de persona es obligatorio
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                Tipo de persona es obligatorio
                                            </ng-container>
                                        </ng-container>
                                    </small>
                                    </div>
                                    <div class="col-sm-3">
                                        <label for="colFormLabelSm">
                                            Documento de Identidad
                                            <span class="advertenciaSolRec">
                                                (*)
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-sm-3">
                                        <select formControlName="documento_identidad_id" class="col-sm-12">
                                            <option *ngFor="let item of tipo_documento ; let i = index" [ngValue]="item.id">
                                                {{item.nombre }}
                                            </option>
                                        </select>
                                        <small class="text-danger mt-1 px-2 py-1 rounded"
                                        *ngIf="(formValidate.documento_identidad_id.dirty || formValidate.documento_identidad_id.touched) 
                                        && formValidate.documento_identidad_id.errors"
                                        >
                                        <ng-container [ngSwitch]="formValidate.documento_identidad_id.value">
                                            <ng-container *ngSwitchCase="''">
                                                Tipo de documento es abligatorio
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                Tipo de documento inválido
                                            </ng-container>
                                        </ng-container>
                                    </small>
                                    </div> 
        
                                    <br>
                                    <br>
        
                                    <div class="col-sm-3">
                                        <label>Fecha Nacimiento
                                            <span class="advertenciaSolRec">
                                                (*)
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-sm-3">
                                        <input type="date" class="form-control form-control-sm"  formControlName="fecha_nacimiento">
                                        <small class="text-danger mt-1 px-2 py-1 rounded"
                                            *ngIf="(formValidate.fecha_nacimiento.dirty || formValidate.fecha_nacimiento.touched) 
                                            && formValidate.fecha_nacimiento.errors"
                                            >
                                            <ng-container [ngSwitch]="formValidate.fecha_nacimiento.value">
                                                <ng-container *ngSwitchCase="''">
                                                    Fecha es obligatoria
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    Fecha es invalida
                                                </ng-container>
                                            </ng-container>
                                        </small>
                                    </div>
                                    <div class="col-sm-3">
                                            <label>Número de Documento
                                                <span class="advertenciaSolRec">
                                                    (*)
                                                </span>
                                            </label>
                                    </div>
                                    <div class="col-sm-3">
                                        <input type="number" class="form-control form-control-sm" 
                                        formControlName="documento_identidad" placeholder="N° de Documento">
                                        <small class="text-danger mt-1 px-2 py-1 rounded"
                                            *ngIf="(formValidate.documento_identidad.dirty || formValidate.documento_identidad.touched) 
                                            && formValidate.documento_identidad.errors"
                                            >
                                            <ng-container [ngSwitch]="formValidate.documento_identidad.value">
                                                <ng-container *ngSwitchCase="''">
                                                    El N° de documento es abligatorio
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    N° de documento inválido
                                                </ng-container>
                                            </ng-container>
                                        </small>
                                    </div>
        
                                        <br>
                                        <br>
        
                                    <div class="col-sm-3">
                                        <label>Nombres
                                            <span class="advertenciaSolRec">
                                                (*)
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control form-control-sm" 
                                        formControlName="nombres" placeholder="Ingrese Nombres">
                                        <small class="text-danger mt-1 px-2 py-1 rounded"
                                            *ngIf="(formValidate.nombres.dirty || formValidate.nombres.touched) 
                                            && formValidate.nombres.errors"
                                            >
                                            <ng-container [ngSwitch]="formValidate.nombres.value">
                                                <ng-container *ngSwitchCase="''">
                                                    Los nombres son abligatorios
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    Nombre inválido
                                                </ng-container>
                                            </ng-container>
                                        </small>
                                    </div>
        
                                        <br>
                                        <br>
                                        
                                    <div class="col-sm-3">
                                        <label>Apellido Paterno
                                            <span class="advertenciaSolRec">
                                                (*)
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control form-control-sm" 
                                        formControlName="apellido_paterno" placeholder="Ingrese Apellido Paterno"> 
                                        <small class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.apellido_paterno.dirty || formValidate.apellido_paterno.touched) 
                                                && formValidate.apellido_paterno.errors"
                                                >
                                                <ng-container [ngSwitch]="formValidate.apellido_paterno.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        Los Apellidos son abligatorios
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Apellido Inválido
                                                    </ng-container>
                                                </ng-container>
                                        </small>
                                    </div>
        
                                        <br>
                                        <br>
        
                                    <div class="col-sm-3">
                                            <label>Apellido Materno
                                                <span class="advertenciaSolRec">
                                                    (*)
                                                </span>
                                            </label>
                                    </div>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control form-control-sm" 
                                        formControlName="apellido_materno" placeholder="Ingrese Apellido Materno"> 
                                        <small class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.apellido_materno.dirty || formValidate.apellido_materno.touched) 
                                                && formValidate.apellido_materno.errors"
                                                >
                                                <ng-container [ngSwitch]="formValidate.apellido_materno.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        Los Apellidos son abligatorios
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Apellido Inválido
                                                    </ng-container>
                                                </ng-container>
                                        </small>
                                    </div>
        
                                        <br>
                                        <br>
        
                                    <div class="col-sm-2">
                                        <label>Departamento
                                        </label>
                                    </div>
                                    <div class="col-sm-2">
                                        <input type="text" class="form-control form-control-sm" 
                                        formControlName="departamento" placeholder="Departamento">
                                        <small class="text-danger mt-1 px-2 py-1 rounded"
                                            *ngIf="(formValidate.departamento.dirty || formValidate.departamento.touched) 
                                            && formValidate.departamento.errors"
                                            >
                                            <ng-container [ngSwitch]="formValidate.departamento.value">
                                                <ng-container *ngSwitchCase="''">
                                                    Departamento es Obligatorio
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    Departamento Invalido
                                                </ng-container>
                                            </ng-container>
                                        </small>
                                    </div>
                                    <div class="col-sm-2">
                                        <label>Provincia
                                        </label>
                                    </div>
                                    <div class="col-sm-2">
                                        <input type="text" class="form-control form-control-sm"  formControlName="provincia" placeholder="Provincia">
                                    </div>
                                    <div class="col-sm-2">
                                        <label>Distrito
                                        </label>
                                    </div>
                                    <div class="col-sm-2">
                                        <input type="text" class="form-control form-control-sm"  formControlName="distrito" placeholder="Distrito">
                                    </div>
        
                                    <br>
                                    <br>
        
                                    <div class="col-sm-3">
                                        <label>Domicilio
                                            <span class="advertenciaSolRec">
                                                (*)
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control form-control-sm" 
                                        formControlName="ubicacion" placeholder="Ingrese su Domicilio">
                                        <small class="text-danger mt-1 px-2 py-1 rounded"
                                            *ngIf="(formValidate.ubicacion.dirty || formValidate.ubicacion.touched) 
                                            && formValidate.ubicacion.errors"
                                            >
                                            <ng-container [ngSwitch]="formValidate.ubicacion.value">
                                                <ng-container *ngSwitchCase="''">
                                                    Domicilio es Obligatorio
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    Domicilio Invalido
                                                </ng-container>
                                            </ng-container>
                                        </small>
                                    </div>
        
                                    <br>
                                    <br>
        
                                    <div class="col-sm-3">
                                        <label for="colFormLabelSm">
                                            Teléfono Celular
                                            <span class="advertenciaSolRec">
                                                (*)
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-sm-3">
                                        <input type="number" class="form-control form-control-sm" 
                                        formControlName="telefono" placeholder="Ingrese Celular">
                                        <small class="text-danger mt-1 px-2 py-1 rounded"
                                            *ngIf="(formValidate.telefono.dirty || formValidate.telefono.touched) 
                                            && formValidate.telefono.errors"
                                            >
                                            <ng-container [ngSwitch]="formValidate.telefono.value">
                                                <ng-container *ngSwitchCase="''">
                                                    El N° de celular es abligatorio
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    Numero incorrecto
                                                </ng-container>
                                            </ng-container>
                                        </small>
                                    </div>
                                    <div class="col-sm-3">
                                        <label for="colFormLabelSm">
                                            Teléfono Fijo
                                        </label>
                                    </div>
                                    <div class="col-sm-3">
                                        <input type="number" class="form-control form-control-sm"  formControlName="telefono_fijo" placeholder="Ingrese Telefono">
                                    </div>

                                    <br>
                                    <br>

                                    <div class="col-sm-3">
                                        <label for="colFormLabelSm">
                                            Correo Electrónico
                                            <span class="advertenciaSolRec">
                                                (*)
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control form-control-sm" 
                                        formControlName="email" placeholder="Ingrese Correo Electrónico">
                                        <small class="text-danger mt-1 px-2 py-1 rounded"
                                            *ngIf="(formValidate.email.dirty || formValidate.email.touched) 
                                            && formValidate.email.errors"
                                            >
                                            <ng-container [ngSwitch]="formValidate.email.value">
                                                <ng-container *ngSwitchCase="''">
                                                    El correo es obligatorio
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    Correo invalido
                                                </ng-container>
                                            </ng-container>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
            </fieldset>
                    
            <br>

            <fieldset class="fieldset">
                    <legend class="legend-border">II. DETALLES DE RECLAMACIÓN </legend>
                        <div class="container">
                            <div class="container">
                                <div class="form-group row">
                                    <div class="col-sm-3">
                                    <label>
                                        Descripción del Reclamo
                                        <span class="advertenciaSolRec">
                                            (*)
                                        </span>
                                    </label>
                                    </div>
                                    <div class="col-sm-9">
                                        <textarea class="form-control form-control-sm" 
                                            name="" id="" cols="6" rows="6" 
                                            formControlName="descripcion_problema" 
                                            placeholder="Ingrese la descripcion del Reclamo">
                                        </textarea>
                                        <small class="text-danger mt-1 px-2 py-1 rounded"
                                            *ngIf="(formValidate.descripcion_problema.dirty || formValidate.descripcion_problema.touched) 
                                            && formValidate.descripcion_problema.errors">
                                                <ng-container [ngSwitch]="formValidate.descripcion_problema.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        La descripción del reclamo es abligatoria
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Texto incorrecto o limite excedido
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                    </div>
                            </div>
                        </div>
                    </div>
            </fieldset>
            <br>
            <fieldset class="fieldset">
                    <ul>
                        <li>
                            Registrada la Hoja de Reclamación, Emapa Huaral S.A dará respuesta al usuario por medio del correo electrónico proporcionado, en un plazo máximo de 30 días hábiles.
                        </li>
                    </ul>
            </fieldset>
            <br>
            <fieldset class="fieldset fieldsetBoton ">
                <div class="form-group row" >
                    <div class="col-sm-12 text-center">
                        <button *ngIf="!isSending" type="submit" class="btn btn-info botonEnv" 
                        data-toggle="tooltip" data-placement="top" title="Completa todos los campos"
                            [disabled]="formModel.invalid"> Enviar                                 
                        </button>
                    </div>
                </div> 
            </fieldset>
        </form>
    </div>
</section>

 
<app-footer></app-footer>