
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BeliService {


  constructor(
    private http: HttpClient
  ) { }
  
  get(route: string): Promise<any> {
    return this.http.get(environment.BELI_SERVER + route).toPromise()
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  post(route: string, data: any): Promise<any> {
    return this.http.post(environment.BELI_SERVER + route, data).toPromise()
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  uploadFile(route: string, data: any, FormData): Promise<any> {
    return this.http.post(environment.BELI_SERVER + route, data,FormData).toPromise()
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  path(){
    return environment.BELI_STORAGE;
  }
}
