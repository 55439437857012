<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2> <i routerLink="/" style="cursor:pointer" class="bx bx-arrow-back"> </i> Estructura Tarifaria</h2>
        </div>
    </div>
</section>



<section class="faq-area ptb-100 bg-f9f9f9">
    <div id="preloader-estructura" class="preloader-iframe"></div>
    <div class="container"  id="iframe-estructura">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <iframe (load)="displayTest();" [src]="url|safe" width="100%" height="900px"></iframe>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>