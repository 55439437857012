import { Component, OnInit } from '@angular/core';
import { BeliService } from 'src/app/services/beli.service';
declare let $ : any;
@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  constructor(
    private beli: BeliService,
    
    ) { }

  listData;
  comunicados;
  url1:string;
  url2:string;
  url3:string;
  existeurl1:boolean
  existeurl2:boolean
  existeurl3:boolean
  path = this.beli.path();
  anios: Array<any> = [
    {
      id: 1,
      nombre: '2020'
    },
    {
      id: 2,
      nombre: '2019'
    },
    {
      id: 3,
      nombre: '2018'
    },
    {
      id: 4,
      nombre: '2017'
    }
  ];

ngOnInit(): void {
  this.getData();
  $("#carouselExampleIndicators").carousel();
  this.getComunicadosEmergentes();
}
  
  
  getData () {
    this.beli.get('page/slider').then( data => { 
      this.listData = data.success ? data.info : [];
    });
  }

  getComunicadosEmergentes () {
    this.beli.get('page/comunicados_emergentes').then( data => { 
      this.comunicados = data.success ? data.info : [];
      if(this.comunicados.length == 3){
        this.url1 = this.comunicados[0]['src_imagen']
        this.url2 = this.comunicados[1]['src_imagen']
        this.url3 = this.comunicados[2]['src_imagen']
        this.existeurl1 = true
        this.existeurl2 = true
        this.existeurl3 = true
        $('#modal3Center').modal('show')    
        $('#modal3Center').modal('hide')
        $("#closemodal3").click(function () {
          $("#modal3Center").removeClass('fade').modal('hide');
          $("#modal3Center").modal("dispose");
  
          $('#modal2Center').modal('show');
          $("#closemodal2").click(function () {
            $("#modal2Center").removeClass('fade').modal('hide');
            $("#modal2Center").modal("dispose");
  
            $('#modal1Center').modal('show');
          });
        });

      }else if(this.comunicados.length == 2){
        this.url1 = this.comunicados[0]['src_imagen']
        this.url2 = this.comunicados[1]['src_imagen']
        this.existeurl1 = true
        this.existeurl2 = true
        this.existeurl3 = false
        $("#modal3Center").remove();
        $('.modal3Center-backdrop').remove();
        $('#modal2Center').modal('show');
        $("#closemodal2").click(function () {
          $("#modal2Center").removeClass('fade').modal('hide');
          $("#modal2Center").modal("dispose");
  
          $('#modal1Center').modal('show');
        });

      }else if(this.comunicados.length == 1){
        this.url1 = this.comunicados[0]['src_imagen']
        this.existeurl1 = true
        this.existeurl2 = false
        this.existeurl3 = false
        $("#modal3Center").remove();
        $('.modal3Center-backdrop').remove();
        $("#modal2Center").remove();
        $('.modal2Center-backdrop').remove();
        $('#modal1Center').modal('show');
    
      }else{
        this.existeurl1 = false
        this.existeurl2 = false
        this.existeurl3 = false
        $("#modal3Center").remove();
        $('.modal3Center-backdrop').remove();
        $("#modal2Center").remove();
        $('.modal2Center-backdrop').remove();
        $("#modal1Center").remove();
        $('.modal1Center-backdrop').remove();
      }
    });
  }

}
