import { Component, OnInit } from '@angular/core';
import { BeliService } from 'src/app/services/beli.service';

declare let $ :any;

@Component({
  selector: 'app-valores-maximos',
  templateUrl: './valores-maximos.component.html',
  styleUrls: ['./valores-maximos.component.scss']
})
export class ValoresMaximosComponent implements OnInit {

  url: string;
  isLoading: boolean = false;
  valores_maximos :{
    src_documento : string
  };
  
  path = this.beli.path();

  constructor(
    private beli: BeliService,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData () {
    this.isLoading  = true;
    this.beli.get('page/valores_maximos').then( data => { 
      this.valores_maximos = data.success ? data.info : [];
      this.url = this.path + this.valores_maximos.src_documento;
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }

  displayTest(){
    setTimeout( () => { $("#preloader-valores")[0].style.display = "none"; }, 2500);
    setTimeout( () => { $("#iframe-valores")[0].style.display = "block"; },2500);
  }

}
