import { Component, OnInit } from '@angular/core';
import { BeliService } from 'src/app/services/beli.service';

declare var $: any;

@Component({
  selector: 'app-g-audiovisual',
  templateUrl: './g-audiovisual.component.html',
  styleUrls: ['./g-audiovisual.component.scss']
})
export class GAudiovisualComponent implements OnInit {

  videos :any; 
  isLoading = false;
  path = this.beli.path();

  constructor(
    private beli :BeliService
  ) { }
  
  ngOnInit(): void {
    this.getVideos();

    setTimeout( () => {  
      this.isLoading = false;
      $('#video-gallery').lightGallery({
        autoplayFirstVideo: true,
        youtubePlayerParams: {
          modestbranding: 1,
          showinfo: 0,
          rel: 0,
          controls: 0,
        }
      });
    },3000);

  }


  getVideos() {
    this.isLoading  = true;
    this.beli.get('page/videos').then( data => { 
      this.videos = data.success ? data.info : [];
    })
    .catch(() => {
      this.isLoading = false;
    }); 
  }

}
