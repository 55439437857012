<app-header></app-header>

<section class="about-area ptb-100" style="background-color: #fdfdfd;">
  <div class="container"  style="margin-bottom: 1.5rem!important;">
    <div class="row"><div class="col-lg-12">
      <h4 class="text-left font-weight-bold">¡Bienvenido(a)!</h4>
      <h4 class="text-left">Estimado(a) usuario(a) a la plataforma digital de EMAPA HUARAL.</h4>
    </div>
  </div>
  </div>
  <div class="container" style="text-align: center;">
    <div class="row">
        <div class="col-md-4 icon-container" style="margin-bottom: 50px;"><a href="#/recibo_digital"><img class="img-responsive" style="max-height: 285px !important" src="assets/img/inicio/home/Imagen4.png"></a></div>
        <div class="col-md-4 icon-container" style="margin-bottom: 50px;"><a href="#/solicitud_reclamaciones"><img class="img-responsive" style="max-height: 285px !important" src="assets/img/inicio/home/Imagen5.png"></a></div>
        <div class="col-md-4 icon-container" style="margin-bottom: 50px;"><a href="#/solicitud_quejas"><img class="img-responsive" style="max-height: 285px !important"  src="assets/img/inicio/home/Imagen6.png"></a></div>
    </div>
    <div class="row">
        <div class="col-md-4 icon-container" style="margin-bottom: 50px;"><a href="#/form_reclamaciones"><img class="img-responsive" style="max-height: 285px !important" src="assets/img/inicio/home/Imagen7.png"></a></div>
        <div class="col-md-4 icon-container" style="margin-bottom: 50px;"><a href="#/consulta_deuda"><img class="img-responsive" style="max-height: 285px !important" src="assets/img/inicio/home/Imagen8.png"></a></div>
        <div class="col-md-4 icon-container" style="margin-bottom: 50px;"><a href="#/convocatorias"><img class="img-responsive" style="max-height: 275px !important" src="assets/img/inicio/home/Imagen10.png"></a></div>
    </div>
  </div>
</section>

<app-footer></app-footer>