import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { BeliService } from 'src/app/services/beli.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-encuesta',
  templateUrl: './encuesta.component.html',
  styleUrls: ['./encuesta.component.scss']
})
export class EncuestaComponent implements OnInit {
  formModel;
  invalid;

  constructor(private formBuilder : FormBuilder, private beli: BeliService,) {
    this.formModel = this.formBuilder.group({
      motivos: ['', [
        Validators.required,
        Validators.compose([Validators.required,
        Validators.max(999999999999999)]),
        Validators.pattern(/[A-Za-z0-9]{1,20}/),
        Validators.pattern(/^[a-z áãâäàéêëèíîïìóõôöòúûüùçñ]+$/i)
      ]],
      puntaje: [''],
      confirmacion: ['', [
        Validators.required,
        Validators.compose([Validators.required]),
      ]],

    });
   }

  ngOnInit(): void {
    this.invalid = true;
  }

  get formValidate(){
    return this.formModel.controls;
  }
  

  selectprimero(){
    var elemento = document.getElementById("valor2");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor3");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor4");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor5");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor1");
    elemento.className += " active";
    this.invalid = false;
    this.formModel.get('puntaje').value = "Muy Insatisfecho";
  }
  selectsegundo(){
    var elemento = document.getElementById("valor1");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor3");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor4");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor5");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor2");
    elemento.className += " active";
    this.invalid = false;
    this.formModel.get('puntaje').value = "Insatisfecho";
  }
  selecttercero(){
    var elemento = document.getElementById("valor1");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor2");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor4");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor5");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor3");
    elemento.className += " active";
    this.invalid = false;
    this.formModel.get('puntaje').value = "Medio Satisfecho";
  }
  selectcuarto(){
    var elemento = document.getElementById("valor1");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor2");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor3");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor5");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor4");
    elemento.className += " active";
    this.invalid = false;
    this.formModel.get('puntaje').value = "Satisfecho";
  }
  selectquinto(){
    var elemento = document.getElementById("valor1");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor2");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor3");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor4");
    elemento.classList.remove("active");
    var elemento = document.getElementById("valor5");
    elemento.className += " active";
     this.invalid = false;
     this.formModel.get('puntaje').value = "Muy Satisfecho";
  }
  submit() {
    this.formModel.get('motivos').value
   console.log(this.formModel.get('motivos').value);
   console.log(this.formModel.get('puntaje').value);
   console.log(this.formModel.get('confirmacion').value);
  }

}
