import { Component, OnInit } from '@angular/core';
import { BeliService } from 'src/app/services/beli.service';

declare let $ : any;

@Component({
  selector: 'app-centro-atencion',
  templateUrl: './centro-atencion.component.html',
  styleUrls: ['./centro-atencion.component.scss']
})
export class CentroAtencionComponent implements OnInit {

  url: string;
  isLoading: boolean = false;
  data :{
    src_documento : string
  };
  
  path = this.beli.path();

  constructor(
    private beli: BeliService,
  ) { }

  ngOnInit(): void {
    this.getData();
  }
 
  getData () {
    this.isLoading  = true;
    this.beli.get('page/centro_atencion_pagos').then( data => { 
      this.data = data.success ? data.info : [];
      this.url = this.path + this.data.src_documento;
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }

  displayTest(){
    setTimeout( () => { $("#preloader-pagos")[0].style.display = "none"; },2000);
    setTimeout( () => { $("#iframe-pagos")[0].style.display = "block"; },2000);
 }

}
