<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2  style="text-align: left;"> Solicitud de Quejas o Sugerencias </h2>
        </div>
    </div>
</section>

<section class="faq-area ptb-100 bg-f9f9f9" *ngIf="!Ocultar">
    <div class="container">
        <div class="container">
            <div class="container">
                <div class="alert alert-info alert-dismissable">
                    <button type="button" class="close" data-dismiss="alert"><b>x</b></button>
                    <i class="fas fa-exclamation-circle"></i> <strong style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif"><img src="assets/img/info.png" align="right" width="74px" alt="gotas"> ¡INFORMACIÓN!</strong>
                    <div class="container-sm">
                            <p style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; text-align: center;" class="mb-0">Estimado usuario, para acceder al formulario de <b>Solicitud de Quejas o Sugerencias</b> de EMAPA HUARAL S.A debemos validar su codigo de suministro, por favor ingrese su código de suministro y presione el botón <b>BUSCAR</b> para validar.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row my-4 justify-content-center">
            <div class="col-md-10 card shadow mx-2 border">
                <form class="border-form" [formGroup]="formModel" class="p-4">   
                    <div class="form-group row">            
                    <div class="col-sm-12">
                        <h6 style="font-family: Georgia, 'Times New Roman', Times, serif;" for="select-beast-selectized">NÚMERO DE SUMINISTRO </h6>
                    </div>
                    <div class="col-lg-11">
                        <div class="input-group">
                            <input type="number" class="form-control" 
                             formControlName="cut_numero" placeholder="Ingrese su número de suministro" [attr.disabled]="isDisable" (ngModelChange)="capturar()">
                             <button class="btn btn-secondary" type="button" [disabled]="isDisableSearch" (click)="validarSuministro()"> 
                                <span class="fas fa-search"></span> BUSCAR</button>
                            </div>
                        <small class="text-danger mt-1 px-2 py-1 rounded">
                            {{mensaje}}
                        </small>
                    </div>
                    <div class="col-lg-1">
                        <button type="button" class="btn btn-danger btn-sm borderReciboEjemplo"
                            (click)="mostrarReciboDeEjemplo()">
                            <span class="fa fa-question-circle fa-2x"></span></button>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>
</section>

<section class="doctors-area pt-100 pb-70" *ngIf="Ocultar">
    <div class="container">
        <div class="col-md-12">
            <div class="shadow mx-2 border border-blue border-darken-4 black">
                <!------- CABECERA ------->
                <div class="card-header card-head-inverse bg-blue bg-darken-4" style="text-align: center;">
                    <br>
                    <h4 class="card-title" style="font-family: Georgia, 'Times New Roman', Times, serif;"> FORMATO PARA LA PRESENTACIÓN DE QUEJAS O SUGERENCIAS - EMAPA HUARAL S.A.</h4>
                </div>
                <!------- CUERPO --------->
                <div class="card-content collapse show">
                    <div class="card-body">
                        <div class="card-text" style="text-align: center;">
                            <small><b>*Llene los datos de este formulario cuidadosamente y con información fidedigna, para enviar correctamente su solicitud de quejas o sugerencias.</b></small>
                        </div>
                        <br>
                        <form class="border-form" (submit)="submit()" [formGroup]="formModel">
                            <div class="form-body">
                                <fieldset  class="fieldset" style="border: 1px solid #088A85;">
                                    <legend class="legend-border"><h5 class="form-section" style="font-family: Georgia, 'Times New Roman', Times, serif;"><i class="far fa-file"></i> DATOS DE LA SOLICITUD</h5></legend>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;">
                                                    FICHA NUMERO:
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true"> 
                                                    <span class="fas fa-hashtag fa-1x" style="color: #000;"></span></button><input type="text" [attr.disabled]="true" class="form-control" value="{{codigo_ficha}}" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;">
                                                    FECHA DEL REGISTRO:
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true"> 
                                                    <span class="fas fa-calendar fa-1x" style="color: #000;"></span></button><input type="date" [attr.disabled]="true" class="form-control" 
                                                    formControlName="fecha" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;">
                                                    CODIGO DE SUMINISTRO:
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true"> 
                                                    <span class="fas fa-list-ol fa-1x" style="color: #000;"></span></button><input type="number" class="form-control" 
                                                    formControlName="cut_numero" placeholder="N° de suministro" [attr.disabled]="isDisable" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;">
                                                    TITULAR DEL RECIBO:
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true"> 
                                                    <span class="fas fa-user fa-1x" style="color: #000;"></span></button><input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="text" class="form-control" formControlName="propietario" [ngModel]="propietario"
                                                    [attr.disabled]="isDisable">
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </fieldset>
                                <br>

                                <!--I DATOS PERSONALES DEL SOLICITANTE -->
                                <fieldset  class="fieldset" style="border: 1px solid #088A85;">
                                    <legend class="legend-border"><h5 class="form-section" style="font-family: Georgia, 'Times New Roman', Times, serif;"><i class="far fa-user"></i> DATOS PERSONALES DEL SOLICITANTE</h5></legend>
                                    <small><b> *Los campos marcados con (*) son obligatorios. </b></small>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="colFormLabelSm" style="font-family: Georgia, 'Times New Roman', Times, serif;">
                                                    TIPO DE DOCUMENTO
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true"> 
                                                    <span class="fas fa-address-book fa-1x" style="color: #000;"></span></button>
                                                <select style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" formControlName="tipo_doc_identidad" class="form-control" (ngModelChange)="capturarTipo()">
                                                    <option disabled value="null" selected> -- SELECCIONE TIPO-- </option>
                                                        <option value="{{ item.nombre }}" *ngFor="let item of tipo_documento;">
                                                            {{ item.nombre }}
                                                        </option>
                                                </select>
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded"
                                                    *ngIf="(formValidate.tipo_doc_identidad.dirty || formValidate.tipo_doc_identidad.touched) && formValidate.tipo_doc_identidad.errors">
                                                <ng-container [ngSwitch]="formValidate.tipo_doc_identidad.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        Por favor seleccione el tipo de documento.
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Por favor seleccione el tipo de documento.
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    NUMERO DE DOCUMENTO
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true"> 
                                                        <span class="fas fa-address-card fa-1x" style="color: #000;"></span></button>
                                                    <input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="number" class="form-control" 
                                                    formControlName="documento_identidad" placeholder="Ingrese su número de documento" (ngModelChange)="capturarDni()">
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded">
                                                    {{message}}
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    NOMBRES
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <input type="text" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" class="form-control" formControlName="nombre" placeholder="Ingrese sus nombres">
                                                <small class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.nombre.dirty || formValidate.nombre.touched) 
                                                && formValidate.nombre.errors"
                                                >
                                                <ng-container [ngSwitch]="formValidate.nombre.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        Los nombres son obligatorios.
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Nombres invalidos.
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    APELLIDO PATERNO
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <input type="text" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" class="form-control" formControlName="apellido_paterno" placeholder="Ingrese su apellido paterno">
                                                <small class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.apellido_paterno.dirty || formValidate.apellido_paterno.touched) 
                                                && formValidate.apellido_paterno.errors"
                                                >
                                                <ng-container [ngSwitch]="formValidate.apellido_paterno.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        El apellido paterno es obligatorio.
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Apellido paterno inválido.
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    APELLIDO MATERNO
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <input type="text" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" class="form-control" formControlName="apellido_materno" placeholder="Ingrese su apellido materno" >
                                                <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.apellido_materno.dirty || formValidate.apellido_materno.touched) && formValidate.apellido_materno.errors">
                                                    <ng-container [ngSwitch]="formValidate.apellido_materno.value">
                                                        <ng-container *ngSwitchCase="''">
                                                            El apellido materno es obligatorio.
                                                        </ng-container>
                                                        <ng-container *ngSwitchDefault>
                                                            Apellido materno inválido.
                                                        </ng-container>
                                                    </ng-container>
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    DIRECCION
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true"> 
                                                    <span class="fas fa-location-arrow fa-1x" style="color: #000;"></span></button>
                                                <input type="text" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" class="form-control" formControlName="direccion" placeholder="Ingrese su direccion de domicilio">
                                                </div>
                                                    <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.direccion.dirty || formValidate.direccion.touched) && formValidate.direccion.errors">
                                                        <ng-container [ngSwitch]="formValidate.direccion.value">
                                                            <ng-container *ngSwitchCase="''">
                                                                La direccion de domicilio es obligatoria.
                                                            </ng-container>
                                                            <ng-container *ngSwitchDefault>
                                                                Direccion invalida.
                                                            </ng-container>
                                                        </ng-container>
                                                    </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    CELULAR
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true"> 
                                                    <span class="fas fa-mobile fa-1x" style="color: #000;"></span></button>
                                                <input type="number" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" class="form-control" formControlName="telefono" placeholder="Ingrese su numero de telefono">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    CORREO ELECTRONICO
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true"> 
                                                    <span class="fas fa-envelope fa-1x" style="color: #000;"></span></button>
                                                    <input type="email" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" class="form-control" formControlName="correo_electronico" placeholder="Ingrese su correo electrónico">
                                                </div>
                                                    <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.correo_electronico.dirty || formValidate.correo_electronico.touched) && formValidate.correo_electronico.errors">
                                                        <ng-container [ngSwitch]="formValidate.correo_electronico.value">
                                                            <ng-container *ngSwitchCase="''">
                                                                El correo electrónico es obligatorio.
                                                            </ng-container>
                                                             <ng-container *ngSwitchDefault>
                                                                Correo electronico es incorrecto.
                                                            </ng-container>
                                                        </ng-container>
                                                    </small>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <br>

                                <!--I DETALLES DE LA SOLICITUD -->
                                <fieldset  class="fieldset" style="border: 1px solid #088A85;">
                                    <legend class="legend-border"><h5 class="form-section" style="font-family: Georgia, 'Times New Roman', Times, serif;"><i class="far fa-file"></i> DETALLES DE LA SOLICITUD</h5></legend>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    TIPO DE SOLICITUD
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <select formControlName="tipo_solicitud" class="form-control" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
                                                    <option disabled value="null" selected> -- SELECCIONE -- </option>
                                                        <option value="{{ item.nombre }}" *ngFor="let item of tipo_solicitud;">
                                                            {{ item.nombre }}
                                                        </option>
                                                </select>
                                                <small class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.tipo_solicitud.dirty || formValidate.tipo_solicitud.touched) 
                                                && formValidate.tipo_solicitud.errors"
                                                >
                                                <ng-container [ngSwitch]="formValidate.tipo_solicitud.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        Por favor seleccione el tipo de solicitud.
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Por favor seleccione el tipo de solicitud.
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    TITULO DE LA SOLICITUD
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input type="text" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" class="form-control" placeholder="Titulo" formControlName="titulo_solicitud">
                                                    <small class="text-danger mt-1 px-2 py-1 rounded"
                                                    *ngIf="(formValidate.titulo_solicitud.dirty || formValidate.titulo_solicitud.touched) 
                                                    && formValidate.titulo_solicitud.errors"
                                                    >
                                                    <ng-container [ngSwitch]="formValidate.titulo_solicitud.value">
                                                        <ng-container *ngSwitchCase="''">
                                                            El titulo de la solicitud es obligatorio.
                                                        </ng-container>
                                                        <ng-container *ngSwitchDefault>
                                                            Titulo de la solicitud es invalido
                                                        </ng-container>
                                                    </ng-container>
                                                </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    DESCRIPCION DE LA SOLICITUD
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <textarea class="form-control" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" placeholder="Descripcion detallada" name="" id="" cols="6" rows="6" formControlName="desc_solicitud"></textarea>
                                                <small class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.desc_solicitud.dirty || formValidate.desc_solicitud.touched) 
                                                && formValidate.desc_solicitud.errors"
                                                >
                                                <ng-container [ngSwitch]="formValidate.desc_solicitud.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        La descripcion de la solicitud es obligatoria.
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Descripcion de la solcitud es invalida.
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>

                                <br>
                                <fieldset class="fieldset" style="border: 1px solid #088A85; text-align: center; font-family: Georgia, 'Times New Roman', Times, serif;">
                                    <ul>
                                        <li>
                                            Registrada la solicitud, Emapa Huaral S.A dará respuesta al usuario por medio del correo electrónico proporcionado, en un plazo máximo de 30 días calendario.
                                        </li>
                                    </ul>
                                </fieldset>

                                <br>
                                <fieldset class="fieldset"  style="border: 1px solid #088A85; text-align: center;">
                                    <div class="form-group row">
                                        <div class="col-sm-12 text-center">
                                            <button *ngIf="!isSending" type="submit" class="btn btn-info botonEnv" 
                                            data-toggle="tooltip" data-placement="top" title="Completa todos los campos"
                                                [disabled]="formModel.invalid || deshabilitado"> Enviar                                 
                                            </button>
                                            <button *ngIf="isSending" class="btn btn-info botonEnv " 
                                                [disabled]="isSending"  routerLink="/solicitud_reclamaciones_pdf"> Enviar 
                                            </button>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>
