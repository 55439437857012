import { Component, OnInit } from '@angular/core';
import { BeliService } from 'src/app/services/beli.service';

@Component({
  selector: 'app-directivas',
  templateUrl: './directivas.component.html',
  styleUrls: ['./directivas.component.scss']
})
export class DirectivasComponent implements OnInit {

  isLoading: boolean = false;
  directivas :any;

  path = this.beli.path();
  
  constructor(
    private beli: BeliService,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData () {
    this.isLoading  = true;
    this.beli.get('page/directivas').then( data => { 
      this.directivas = data.success ? data.info : [];
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }

}
