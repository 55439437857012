<footer class="footer-area pt-4 pb-0">
    <div class="footer-bottom-area m-0">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-12">
                </div>
                <div class="col-lg-6 col-md-12 text-center">
                    <p class="mb-0">Copyright <i class='bx bx-copyright'></i> {{anio}} EMAPA HUARAL S.A   </p>
                </div>
            </div>
        </div>
    </div>
</footer>


<div class="go-top"><i class='bx bx-up-arrow-alt'></i></div>