<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2><i routerLink="/" style="cursor:pointer" class="bx bx-arrow-back"> </i>  Formulario Atención al Cliente</h2>
        </div>
    </div>
</section>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-4 text-center">
                <a routerLink="operacional">
                  <img src="assets/img/atencion.png" width="100" class="rounded-circle" alt="Operacional">
                  <p class="text-center">Operacional</p>
                </a>
              </div>
              <div class="col-md-4 text-center">
                <a routerLink="comercial">
                  <img src="assets/img/book.png" width="100" class="rounded-circle" alt="Comercial">
                  <p class="text-center">Comercial</p>
                </a>
              </div>
        </div>
    </div>
</section>
 
<app-footer></app-footer>