
export const environment = {
  production: false,

  ROOT_SERVER : 'http://web_emapa_huaral.test/admin/Server/public/',
  URL_SERVER : 'http://web_emapa_huaral.test/admin/Server/public/api/',

  BELI_SERVER: 'https://apps.emapahuaral.com.pe/admin/api/public/api/',
  BELI_STORAGE:'https://apps.emapahuaral.com.pe/admin/api/storage/app/',

  //BELI_SERVER: 'http://localhost/mails/admin/api/public/api/',
   //BELI_STORAGE:'http://localhost/mails/admin/api/storage/app/',

  // BELI_SERVER: 'http://localhost:7777/CodFuente/admin/api/public/api/',
  // BELI_STORAGE:'http://localhost:7777/CodFuente/admin/api/storage/app/',
  
};
