import { Component, OnInit } from '@angular/core';
import { BeliService } from 'src/app/services/beli.service';
import * as moment from 'moment';

@Component({
  selector: 'app-notas',
  templateUrl: './notas.component.html',
  styleUrls: ['./notas.component.scss']
})
export class NotasComponent implements OnInit {

  isLoading: boolean = false;
  notas_prensa :any;
  path = this.beli.path();
  
  constructor(
    private beli: BeliService,
  ) { }

  ngOnInit(): void {
    moment.locale('es');
    this.getData();
  }

  getData () {
    this.isLoading  = true;
    this.beli.get('page/notas_prensa').then( data => { 
      this.notas_prensa = data.success ? data.info : [];
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }

  setFecha(fecha) {
    return  moment(fecha).format('LL');
  }
  

  goToNoticia (item) {
    localStorage.setItem('noticia',JSON.stringify(item));
  }
}
