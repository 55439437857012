<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2  style="text-align: left;"> Incidencias </h2>
        </div>
    </div>
</section>

<section class="faq-area ptb-100 bg-f9f9f9" *ngIf="Ocultar" >
    <div class="container" style="width: 500px;">
        <div class="row my-4 justify-content-center">
            <div class="col-md-10 card shadow mx-2 border" style="padding-top: 15px; align-items: center;">
              
                    <div class="form-group row">            
                    <div class="col-sm-12">
                      
                        <a (click)="generar()" style="cursor: pointer;"><img class="img-responsive" src="assets/img/inicio/home/images.jpg" style="width: 120px;"></a>
                          
                        
                      
                    </div>
                    
                </div>
                
            </div>
        </div>
    </div>
</section>

<section class="doctors-area pt-100 pb-70" *ngIf="!Ocultar">
    <div class="container" style="padding: 0px !important;">
        <div class="col-md-12" style="padding: 0px !important;">
            <div class="shadow mx-2 border border-blue border-darken-4 black">
                <!------- CABECERA ------->
                <div class="card-header card-head-inverse bg-blue bg-darken-4" style="text-align: center;">
                    <br>
                    <h4 class="card-title" style="font-family:  francisco, segoe ui, roboto, helvetica neue, sans-serif; font-weight: bold;"> FORMATO DE INCIDENCIAS</h4>
                </div>
                <!------- CUERPO --------->
                <div class="card-content collapse show">
                    <div class="card-body">
                        <form class="border-form" (submit)="submit()" [formGroup]="formModel">
                            <div class="form-body">
                                <fieldset  class="fieldset">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family:  francisco, segoe ui, roboto, helvetica neue, sans-serif; font-weight: bold;">
                                                    Ticket número:
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true" style="border: 1px solid #ced4da;"> 
                                                    <span class="fas fa-hashtag fa-2x" style="color: #000;"></span></button>
                                                    <input type="text" [attr.disabled]="true" class="form-control" value="{{codigo_ficha}}" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; height: 42px;">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label style="font-family:  francisco, segoe ui, roboto, helvetica neue, sans-serif; font-weight: bold;">
                                                    Fecha:
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true" style="border: 1px solid #ced4da;"> 
                                                    <span class="fas fa-calendar fa-2x" style="color: #000;"></span></button><input type="date" [attr.disabled]="true" class="form-control" 
                                                    formControlName="fecha" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; height: 42px;">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label style="font-family:  francisco, segoe ui, roboto, helvetica neue, sans-serif; font-weight: bold;">
                                                    Hora:
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true" style="border: 1px solid #ced4da;">
                                                    <span class="fas fa-clock fa-2x" style="color: #000;"></span></button><input type="datetime" [attr.disabled]="true" class="form-control" 
                                                    formControlName="hora" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; height: 42px;">
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </fieldset>
                                 <!--I DATOS PERSONALES DEL SOLICITANTE -->
                                 <fieldset  class="fieldset">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-family:  francisco, segoe ui, roboto, helvetica neue, sans-serif; font-weight: bold;">
                                                    Nombres
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <input type="text" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" class="form-control" formControlName="nombre" placeholder="Ingrese sus nombres">
                                                <small style="font-family:  francisco, segoe ui, roboto, helvetica neue, sans-serif; font-weight: bold;" class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.nombre.dirty || formValidate.nombre.touched) 
                                                && formValidate.nombre.errors"
                                                >
                                                <ng-container [ngSwitch]="formValidate.nombre.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        * Los nombres son obligatorios.
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Nombres invalidos.
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family:  francisco, segoe ui, roboto, helvetica neue, sans-serif; font-weight: bold;">
                                                     Gerencia
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <select formControlName="gerencia"  class="form-control" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" data-size="5" (change)="onSelectTipoReclamo($event.target.value)">
                                                    <option value="" disabled selected>-- Seleccione su Gerencia --</option>
                                                    <option *ngFor="let item of tipoReclamo" [value]="item.id" >
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                                <small style="font-family:  francisco, segoe ui, roboto, helvetica neue, sans-serif; font-weight: bold;" class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.gerencia.dirty || formValidate.gerencia.touched) 
                                                && formValidate.gerencia.errors"
                                                >
                                                <ng-container [ngSwitch]="formValidate.gerencia.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        * Por favor seleccione su Gerencia.
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Por favor seleccione el tipo de solicitud.
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label *ngIf="Mostrar" style="font-family:  francisco, segoe ui, roboto, helvetica neue, sans-serif; font-weight: bold;">
                                                    Oficina
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <select *ngIf="Mostrar" formControlName="oficina" data-size="5" class="form-control" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
                                                    <option value="" disabled selected>-- Seleccione su Oficina --</option>
                                                    <option *ngFor="let item of subTipoReclamo" [value]="item.name" title="Tipos">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-family:  francisco, segoe ui, roboto, helvetica neue, sans-serif; font-weight: bold;">
                                                    Cargo
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <input type="text" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" class="form-control" formControlName="cargo" placeholder="Ingrese su cargo">
                                                <small  style="font-family:  francisco, segoe ui, roboto, helvetica neue, sans-serif; font-weight: bold;" class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.cargo.dirty || formValidate.cargo.touched) && formValidate.cargo.errors">
                                                <ng-container [ngSwitch]="formValidate.cargo.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        * El cargo que ocupa es obligatorio.
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Apellido paterno inválido.
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-family:  francisco, segoe ui, roboto, helvetica neue, sans-serif; font-weight: bold;">
                                                    Correo electrónico
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <input type="text" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; text-transform: lowercase;" class="form-control" formControlName="correo_electronico" placeholder="Ingrese su correo electrónico">
                                                <small  style="font-family:  francisco, segoe ui, roboto, helvetica neue, sans-serif; font-weight: bold;" class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="( formValidate.correo_electronico.touched) && formValidate.correo_electronico.errors">
                                                <ng-container [ngSwitch]="formValidate.correo_electronico.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        * El correo electrónico es obligatorio.
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Apellido paterno inválido.
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset  class="fieldset">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-family:  francisco, segoe ui, roboto, helvetica neue, sans-serif; font-weight: bold;">
                                                    Titulo
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="form-group">
                                                    <input type="text" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" class="form-control" placeholder="Titulo" formControlName="titulo">
                                                    <small style="font-family:  francisco, segoe ui, roboto, helvetica neue, sans-serif; font-weight: bold;" class="text-danger mt-1 px-2 py-1 rounded"
                                                    *ngIf="(formValidate.titulo.dirty || formValidate.titulo.touched) 
                                                    && formValidate.titulo.errors"
                                                    >
                                                    <ng-container [ngSwitch]="formValidate.titulo.value">
                                                        <ng-container *ngSwitchCase="''">
                                                            * El titulo de la solicitud es obligatorio.
                                                        </ng-container>
                                                        <ng-container *ngSwitchDefault>
                                                            Titulo de la solicitud es invalido
                                                        </ng-container>
                                                    </ng-container>
                                                </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-family:  francisco, segoe ui, roboto, helvetica neue, sans-serif; font-weight: bold;">
                                                    Urgencia
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <select formControlName="urgencia" class="form-control" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
                                                    <option disabled value="null" selected> -- Seleccione la urgencia de la incidencia -- </option>
                                                        <option value="{{ item.nombre }}" *ngFor="let item of tipo_solicitud;">
                                                            {{ item.nombre }}
                                                        </option>
                                                </select>
                                                <small style="font-family:  francisco, segoe ui, roboto, helvetica neue, sans-serif; font-weight: bold;" class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.urgencia.dirty || formValidate.urgencia.touched) 
                                                && formValidate.urgencia.errors"
                                                >
                                                <ng-container [ngSwitch]="formValidate.urgencia.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        * Por favor seleccione la urgencia de la solicitud.
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        * Por favor seleccione la urgencia de la solicitud.
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-family:  francisco, segoe ui, roboto, helvetica neue, sans-serif; font-weight: bold;">
                                                    Descripción
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <textarea class="form-control" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" placeholder="Descripcion detallada" name="" id="" cols="6" rows="6" formControlName="descripcion"></textarea>
                                                <small style="font-family:  francisco, segoe ui, roboto, helvetica neue, sans-serif; font-weight: bold;" class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.descripcion.dirty || formValidate.descripcion.touched) 
                                                && formValidate.descripcion.errors"
                                                >
                                                <ng-container [ngSwitch]="formValidate.descripcion.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        * La descripcion de la solicitud es obligatoria.
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Descripcion de la solcitud es invalida.
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset class="fieldset">
                                    <div class="form-group row">
                                        <div class="col-sm-12 text-center">
                                            <button *ngIf="!isSending" type="submit" class="btn btn-info botonEnv" 
                                data-toggle="tooltip" data-placement="top" title="Completa todos los campos"
                            [disabled]="formModel.invalid"> Generar                       
                        </button>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>
