<body>
    <div class="pageEncuesta">
         <div class="boxContainer">
              <div class="contentBanner relative">
                   <div class="boxLogoFlotante">
                        <a href="/">
                             <img src="https://image.info-enel.com/lib/fe9213727c61027d75/m/11/22370d7d-a8ef-443b-bbe6-a2d8d5f2533e.png" alt="Enel">
                        </a>
                   </div>
                   <div class="fullImages">
                        <img src="https://image.info-enel.com/lib/fe9213727c61027d75/m/11/3cba3187-4a73-4e1f-86c0-596b61068b44.jpg" alt="">
                   </div>
              </div>
              <section class="contentHome">
                   <div class="">
                        <div class="boxContent">
                             <div class="boxTitle">
                                  <h1 class="txtCenter "> TU RESPUESTA HA SIDO ENVIADA<br>
                                       GRACIAS POR COMPARTIR 
                                       TU OPINIÓN
                                  </h1>
                             </div>
                             <div class="d-flex justify-content-center align-items-center separate2">
                                  <div class="boxButton large">
                                       <button type="button" class="btnIrEnel txtUppercase">Ir a la página de EMAPA HUARAL</button>
                                  </div>
                             </div>
                             <div class="boxDescription mt-4">
                                  <p class="txtCenter"> Si ya no desea recibir comunicaciones de Enel sobre encuestas relacionadas con su satisfacción, puede escribir a la dirección de correo electrónico dedicada a la privacidad: encuestassatisfacción@enel.com
                             </p>
                             </div>

                             <div class="boxDescription mt-4">
                                  <p class="txtCenter">
                                  <a href="https://www.enel.pe/content/dam/enel-pe/footer/web_polticadeprivacidad.pdf">Política de Privacidad</a>/<a href="https://www.enel.pe/es/footer/politica-de-cookies.html"> Política de Cookies</a>
                             </p>
                             </div>
                             
                        </div>
                   </div>
              </section>
         </div>
    </div>

</body>