<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 style="text-align: left;"> Recibo Digital </h2>
        </div>
    </div>
</section>

<section class="faq-area ptb-100 bg-f9f9f9" *ngIf="!Ocultar">
    <div class="container">
                <div class="alert alert-info alert-dismissable">
                    <button type="button" class="close" data-dismiss="alert"><b>x</b></button>
                    <i class="fas fa-exclamation-circle" style="font-size: 17px;"></i> <strong style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif"><img src="assets/img/info.png" align="right" width="74px" alt="gotas"> ¡INFORMACIÓN!</strong>
                    <div class="container-sm">
                            <p style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; text-align: center;" class="mb-0">Estimado usuario, para acceder al formulario de afiliación al <b>RECIBO DIGITAL</b> de EMAPA HUARAL S.A por favor ingrese su <b>CÓDIGO DE SUMINISTRO</b> y presione el botón <b>BUSCAR</b> para validar.</p>
                    </div>
                </div>
          
       
    </div>
    <div class="container">
        <div class="row my-4 justify-content-center">
            
            <div class="col-md-10 card shadow mx-2 border" style="padding: 0px;"> 
                <div class="container" style="padding: 0;">
                    <div class="alert alert-primary alert-dismissable" style="background-color: #088A85 !important; color: white;">
                        <i class="fas fa-question-circle" style="font-size: 17px; cursor: pointer;" (click)="mostrarReciboDeEjemplo()"> <strong style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif"> ¿Dónde ubico el código de suministro?</strong></i>
                    </div>
              
            
            </div>
                    <form class="border-form" (submit)="validar()" class="p-4" [formGroup]="formModel">
                    <div class="form-group row">            
                    <div class="col-sm-12" style="text-align: center;">
                        <h6 for="select-beast-selectized" style="font-weight: bold;">Código de Suministro </h6>
                    </div>
                    <div class="col-sm-12">
                        <div class="input-group">
                            <input type="number" class="form-control input-movil"  style="margin-top: 20px;"
                             formControlName="numero_suministro" placeholder="Ejemplo: 386431" [attr.disabled]="isDisable" (ngModelChange)="capturar()" [required]="true">
                            </div>
                        <small class="text-danger mt-1 px-2 py-1 rounded">
                           {{mensaje}}
                        </small>
                    </div>
                    <br>
                    <div class="col-sm-12" style="text-align: center; margin-top: 15px;">
                        <button class="btn btn-secondary" type="submit" [disabled]="isVacio" style="width: 130px;"> 
                            <span class="fas fa-search" style='font-size:17px'></span> BUSCAR</button>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>
</section>


<section class="faq-area ptb-100 bg-f9f9f9" *ngIf="isVerSuscripcion">
    <div class="container">
        <div class="row my-4 justify-content-center">
            
            <div class="col-md-10 card shadow mx-2 border" style="padding: 0px;"> 
                <div class="container" style="padding: 0;">
                    <div class="alert alert-primary alert-dismissable" style="background-color: #088A85 !important; color: white;">
                        <i class="fas fa-sign-out-alt" style="font-size: 17px; cursor: pointer;" (click)="salir()"> <strong style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif"> SALIR</strong></i>
                    </div>
              
            
            </div>
                    <div class="border-form" class="p-4">
                    <div class="form-group row">            
                    <div class="col-sm-12" style="text-align: center;">
                        <h6 for="select-beast-selectized" style="font-weight: bold;">Ya se encuentra afiliado al Recibo Digital.</h6>
                    </div>
                    <br>
                    <div class="container">
                        <ul class="list-group">
                            <li class="list-group-item active" style="background-color: #3C3C3B !important;
                            border-color: #3C3C3B !important; color: white !important;" >Datos del Afiliado</li>
                            <li class="list-group-item" style="font-weight: 500;">Nombres: {{dataNombre}} {{dataApellidoP}} {{dataApellidoM}}</li>
                            <li class="list-group-item" style="font-weight: 500;">DNI: {{dataDNI}}</li>
                            <li class="list-group-item" style="font-weight: 500;">Email: {{dataEmail}}</li>
                            <li class="list-group-item" style="font-weight: 500;">Fecha de Afiliacion: {{dataFecha}}</li>
                          </ul>

 
                      </div>
                      <br>
                      <br>
                      <br>
                      <div class="container">
                        <small>Si cree que hay algún error, por favor comuníquese al correo: <a href = "mailto: atencionalcliente@emapahuaral.com.pe">atencionalcliente@emapahuaral.com.pe</a></small>
                      </div>
            </div>
            </div>
        </div>
    </div>
    </div>
</section>

<section class="doctors-area pt-100 pb-70" *ngIf="MostrarAfiliar">
    <div class="container">
        <div class="content-body">
            <!-- Description -->

    <div class="row">
        <div class="col-md-12">
            <div class="shadow mx-2 border border-blue border-darken-4 black">
                <div class="card-header card-head-inverse bg-blue bg-darken-4" style="text-align: center;">
                    <br>
                    <h4 class="card-title"><i class="fas fa-file"></i> AFILIACION AL RECIBO DIGITAL - EMAPA HUARAL S.A.</h4>
                </div>
                <div class="card-content collapse show">
                    <div class="card-body">
                        <div class="card-text">
                            <small><b>*Llene los datos de este formulario cuidadosamente y con información fidedigna, para enviar su recibo digital correctamente.</b></small>
                        </div>
                        <br>

                        <form class="border-form" (submit)="submit()" [formGroup]="formModel">

                        <div class="form-body">
                            <h5 class="form-section"><i class="far fa-file"></i> DATOS DE LA AFILIACIÓN</h5>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>
                                            FECHA DE AFILIACION
                                        </label>
                                        <input type="date" [attr.disabled]="true" class="form-control" 
                                            formControlName="fecha">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>
                                            CODIGO DE SUMINISTRO
                                        </label>
                                        <input type="number" class="form-control form-control-sm" 
                             formControlName="numero_suministro" placeholder="N° de suministro" [attr.disabled]="isDisable">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>
                                            TITULAR DEL RECIBO
                                        </label>
                                        <input type="text" class="form-control form-control-sm" formControlName="propietario" [ngModel]="propietario"
                                         [attr.disabled]="isDisable">
                                    </div>
                                </div>
                            </div>
                            <h5 class="form-section"><i class="far fa-user"></i> DATOS PERSONALES DEL SOLICITANTE</h5>
                            <small><b>*Los campos marcados con (*) son obligatorios.</b></small>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="colFormLabelSm">
                                            TIPO DE SOLICITANTE
                                            <span class="advertenciaSolRec">
                                                (*)
                                            </span>
                                        </label>
                                        <select class="form-control" formControlName="soy">
                                            <option disabled value="null" selected>-- Seleccione el tipo de Solicitante --</option>
                                            <option value="{{ item.nombre }}" *ngFor="let item of soy_tipo;">
                                                {{ item.nombre }}
                                            </option>
                                   </select>
                                    <small class="text-danger mt-1 px-2 py-1 rounded"
                                        *ngIf="(formValidate.soy.dirty || formValidate.soy.touched) 
                                        && formValidate.soy.errors"
                                        >
                                        <ng-container [ngSwitch]="formValidate.soy.value">
                                            <ng-container *ngSwitchCase="''">
                                                El tipo de solicitante es abligatorio
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                Tipo de Solicitante inválido
                                            </ng-container>
                                        </ng-container>
                                    </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="colFormLabelSm">
                                            TIPO DE DOCUMENTO
                                            <span class="advertenciaSolRec">
                                                (*)
                                            </span>
                                        </label>
                                        <select formControlName="dni" class="form-control">
                                            <option disabled selected value="null">-- Seleccione el tipo de Documento --</option>
                                             <option *ngFor="let item of tipo_documento ;" value="{{ item.nombre }}">
                                                 {{ item.nombre }}
                                             </option>
                                        </select>
                                        <small class="text-danger mt-1 px-2 py-1 rounded"
                                        *ngIf="(formValidate.dni.dirty || formValidate.dni.touched) 
                                        && formValidate.dni.errors"
                                        >
                                        <ng-container [ngSwitch]="formValidate.dni.value">
                                            <ng-container *ngSwitchCase="''">
                                                El tipo de documento es abligatorio
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                                Tipo de documento inválido
                                            </ng-container>
                                        </ng-container>
                                    </small>
                                        
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="colFormLabelSm">
                                            N° DE DOCUMENTO
                                            <span class="advertenciaSolRec">
                                                (*)
                                            </span>
                                        </label>
                                        <input type="number" class="form-control" 
                                        formControlName="numero_documento" placeholder="N° de Documento">
                                        <small class="text-danger mt-1 px-2 py-1 rounded"
                                            *ngIf="(formValidate.numero_documento.dirty || formValidate.numero_documento.touched) 
                                            && formValidate.numero_documento.errors">
                                            <ng-container [ngSwitch]="formValidate.numero_documento.value">
                                                <ng-container *ngSwitchCase="''">
                                                    El N° de documento es abligatorio
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    N° de documento inválido
                                                </ng-container>
                                            </ng-container>
                                        </small>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="colFormLabelSm">
                                            NOMBRES
                                            <span class="advertenciaSolRec">
                                                (*)
                                            </span>
                                        </label>
                                        <input type="text" class="form-control" 
                                            formControlName="nombres" placeholder="Ingrese Nombres">
                                        <small class="text-danger mt-1 px-2 py-1 rounded"
                                            *ngIf="(formValidate.nombres.dirty || formValidate.nombres.touched) 
                                                && formValidate.nombres.errors">
                                <ng-container [ngSwitch]="formValidate.nombres.value">
                                    <ng-container *ngSwitchCase="''">
                                        Los nombres son abligatorios
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        Nombre inválido
                                    </ng-container>
                                </ng-container>
                            </small>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="colFormLabelSm">
                                            APELLIDO PATERNO
                                            <span class="advertenciaSolRec">
                                                (*)
                                            </span>
                                        </label>
                                        <input type="text" class="form-control" 
                                        formControlName="apellidos_paternos" placeholder="Ingrese Apellido Paterno"> 
                                        <small class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.apellidos_paternos.dirty || formValidate.apellidos_paternos.touched) 
                                                && formValidate.apellidos_paternos.errors"
                                                >
                                                <ng-container [ngSwitch]="formValidate.apellidos_paternos.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        Los Apellidos son abligatorios
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Apellido Inválido
                                                    </ng-container>
                                                </ng-container>
                                        </small>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="colFormLabelSm">
                                            APELLIDO MATERNO
                                            <span class="advertenciaSolRec">
                                                (*)
                                            </span>
                                        </label>
                                        <input type="text" class="form-control" 
                            formControlName="apellidos_maternos" placeholder="Ingrese Apellido Materno"> 
                            <small class="text-danger mt-1 px-2 py-1 rounded"
                                    *ngIf="(formValidate.apellidos_maternos.dirty || formValidate.apellidos_maternos.touched) 
                                    && formValidate.apellidos_maternos.errors"
                                    >
                                    <ng-container [ngSwitch]="formValidate.apellidos_maternos.value">
                                        <ng-container *ngSwitchCase="''">
                                            Los Apellidos son abligatorios
                                        </ng-container>
                                        <ng-container *ngSwitchDefault>
                                            Apellido Inválido
                                        </ng-container>
                                    </ng-container>
                            </small>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="colFormLabelSm">
                                            DIRECCION
                                            <span class="advertenciaSolRec">
                                                (*)
                                            </span>
                                        </label>
                                        <input type="text" class="form-control form-control-sm" 
                                            formControlName="direccion" placeholder="Ingrese Direccion"> 
                                        <small class="text-danger mt-1 px-2 py-1 rounded"
                                            *ngIf="(formValidate.direccion.dirty || formValidate.direccion.touched) 
                                                && formValidate.direccion.errors">
                                        <ng-container [ngSwitch]="formValidate.direccion.value">
                                    <ng-container *ngSwitchCase="''">
                                        La direccion es abligatoria
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        Direccion Inválida
                                        </ng-container>
                                        </ng-container>
                                    </small>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="colFormLabelSm">
                                            TELEFONO
                                            <small class="text-danger">
                                                (Opcional)
                                            </small>
                                        </label>
                                        <input type="text"  class="form-control form-control-sm" 
                                            formControlName="telefono_fijo" placeholder="Ingrese Telefono">
                                        <small class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="formValidate.telefono_fijo.errors">
                                            <ng-container >
                                                    Formato de número incorrecto
                                            </ng-container>
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="celular">CELULAR
                                            <span class="advertenciaSolRec">
                                                (*)
                                            </span>
                                        </label>
                                        <input type="number" class="form-control" 
                            formControlName="telefono_celular" placeholder="Ingrese Celular">
                            <small class="text-danger mt-1 px-2 py-1 rounded"
                                *ngIf="(formValidate.telefono_celular.dirty || formValidate.telefono_celular.touched) 
                                && formValidate.telefono_celular.errors"
                                >
                                <ng-container [ngSwitch]="formValidate.telefono_celular.value">
                                    <ng-container *ngSwitchCase="''">
                                        El N° de celular es abligatorio
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        Formato de número incorrecto
                                    </ng-container>
                                </ng-container>
                            </small>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="e-mail">CORREO ELECTRÓNICO
                                            <span class="advertenciaSolRec">
                                                (*)
                                            </span>
                                        </label>
                                        <input type="text" class="form-control" 
                                        formControlName="email" placeholder="Ingrese Correo Electrónico">
                                        <small class="text-danger mt-1 px-2 py-1 rounded"
                                            *ngIf="(formValidate.email.dirty || formValidate.email.touched) 
                                            && formValidate.email.errors"
                                            >
                                            <ng-container [ngSwitch]="formValidate.email.value">
                                                <ng-container *ngSwitchCase="''">
                                                    El correo es obligatorio
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    Formato de correo invalido
                                                </ng-container>
                                            </ng-container>
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="form-actions">
                        </div>


                        <div class="col-md-12">

                            <div class="bs-callout-warning callout-bordered mt-1">
                                <div class="media align-items-stretch">
                                    
                                    <div class="media-body p-1">
                                        <strong>Términos y condiciones</strong>
                                        <ul>
                                            <li>
                                                <p>Autorizo a EMAPA HUARAL S.A me remita información a través del correo electrónico proporcionado.</p>    
                                            </li>
                                            <li>
                                                <p>Acepto la afilicación al Recibo Digital de EMAPA HUARAL S.A.</p>
                                            </li>
                                        </ul>
                                        <p>
                                            <input name="acepta" type="checkbox">
                                            Marque el recuadro como señal de haber tomado conocimiento de los términos y condiciones.
                                        </p>
                                    </div>
                                    <div class="d-flex align-items-center bg-warning p-2">
                                        <i class="fas fa-exclamation-circle white fa-3x"></i>
                                    </div>
                                </div>
                                <br>
                                <div class="container">
                                    <ul>
                                        <li>
                                            <small><b style="font-style: 12px;"></b></small>
                                            <small><b style="font-weight: bold;">Registrada la Solicitud del Recibo Digital, Emapa Huaral S.A dará respuesta al usuario por medio del correo electrónico proporcionado, en un plazo máximo de 30 días calendario.</b></small>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>



                        <div class="text-center">
                            <button *ngIf="!isSending" type="submit" class="btn btn-info botonEnv" 
                                data-toggle="tooltip" data-placement="top" title="Completa todos los campos"
                            [disabled]="formModel.invalid"><i class="far fa-save"></i> Afiliar                            
                        </button>
                        </div>



                        
                    </div></form>
                </div>
            </div>
        </div>
    </div>

        </div>
    </div>
    </div>

</section>

<app-footer>

</app-footer>



