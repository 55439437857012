<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>{{ selectResolucionItem.titulo }}</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li>{{ selectResolucionItem.titulo }}</li>
            </ul>
        </div>
    </div>
</section>

<section class="faq-area ptb-100 bg-f9f9f9">
    <div class="container">
        <div class="row">
            <!-- <div class="col-md-12 mb-3">
                <form>
                    <button class="btn btn-info mr-2" *ngFor="let anio of anios"
                        (click)="changeAnio(anio.nombre)">{{anio.nombre}}</button>
                </form>
            </div> -->
            <div class="col-md-12" *ngIf="isLoading">
                Cargando...
            </div>
            <div class="col-md-12" *ngIf="!isLoading">
                <div class="row">
                    <div class="col-md-12" *ngIf="resoluciones.length == 0">
                        No hay items para {{ selectResolucionItem.titulo }}
                    </div>
                    <div class="col-md-12" *ngIf="resoluciones.length > 0">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Año</th>
                                        <th>Descripción</th>
                                        <th>Ver</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let resolucion of resoluciones">
                                        <td scope="row">
                                            {{resolucion.fecha | date:'y'}}
                                        </td>
                                        <td>
                                            {{resolucion.descripcion}}
                                        </td>
                                        <td>
                                            <a target="_blank" href="{{resolucion.url}}">
                                                <img src="assets/img/pdf.png" width="40">
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>