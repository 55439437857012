import { Component, OnInit } from '@angular/core';
import { BeliService } from 'src/app/services/beli.service';

@Component({
  selector: 'app-quienes-somos',
  templateUrl: './quienes-somos.component.html',
  styleUrls: ['./quienes-somos.component.scss']
})
export class QuienesSomosComponent implements OnInit {

  path = this.beli.path();

  isLoading: boolean = true;
  
  objetivos     :  any;
  vision        : any;
  mision        : any;
  quienes_somos : any;
  
  constructor(
    private beli: BeliService,
  ) { }


  ngOnInit(): void {
    this.getObjectivos();
    this.getMision();
    this.getVision();
    this.getQuienesSomos();
  }

  getObjectivos () {
    this.beli.get('page/quienes_somos/objetivos').then( data => { 
      this.objetivos = data.success ? data.info : [];
      this.twoArrays(this.objetivos.items);
      this.isLoading = false;
    });
  }

  getVision () {
    this.beli.get('page/quienes_somos/vision').then( data => { 
      this.vision = data.success ? data.info : [];
      this.isLoading = false;
    });
  }

  getMision () {
    this.beli.get('page/quienes_somos/mision').then( data => { 
      this.mision = data.success ? data.info : [];
      this.isLoading = false;
    });
  }

  getQuienesSomos () {
    this.beli.get('page/quienes_somos/quienes_somos').then( data => { 
      this.quienes_somos = data.success ? data.info : [];
      this.isLoading = false;
    });
  }
 
  twoArrays(array) {
    if(Array.isArray(array)) {
      let index = array.length;
      this.objetivos.array_1 = array.slice(0, index/2);
      this.objetivos.array_2 = array.slice(index/2,index);
    }
  }
  
}
