import { Component, OnInit } from '@angular/core';
import { Location} from '@angular/common';
import { BeliService } from 'src/app/services/beli.service';
import * as moment from 'moment';
@Component({
  selector: 'app-noticia',
  templateUrl: './noticia.component.html',
  styleUrls: ['./noticia.component.scss']
})
export class NoticiaComponent implements OnInit {

  location: Location;
  isLoading: boolean = false;
  noticia :any;
  noticias : any;
  path = this.beli.path();
  
  constructor(location: Location,private beli: BeliService,) {
    this.location = location;
   }

  ngOnInit(): void {
    this.getData();
    this.getNoticias();
    moment.locale('es');
  }

  getNoticia (id) {
    this.isLoading  = true;
    this.beli.get('page/noticia/'+id).then( data => { 
      this.noticia = data.success ? data.info : [];
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }

  getNoticias() 
  {
    this.isLoading  = true;
    this.beli.get('page/notas_prensa').then( data => { 
      this.noticias = data.success ? data.info : [];
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }

  getData() {
    if (localStorage.getItem('noticia')  !== undefined) {
      let noticia = JSON.parse(localStorage.getItem('noticia'));
      this.getNoticia(noticia.id);
    }
  }
  setNoticiaflat = true;

  setNoticia(item) {
    this.setNoticiaflat = false;
    
    this.getNoticia(item.id);
    window.scrollTo(0, 135);
    setTimeout( () => {
      this.setNoticiaflat = true;
    } ,1500);

  }


  setFecha(fecha) {
    return  moment(fecha).format('LL');
  }
}
