<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
         <h2>  <i routerLink="/atencion_clientes" style="cursor:pointer" class="bx bx-arrow-back"> </i>  
            Formulario Atención al Cliente - Operacional </h2>
        </div>
    </div>
</section>

<section class="doctors-area pt-100 pb-70">
    <div class="container">
        <form class="border-form" (submit)="submit()" [formGroup]="formModel">
            <div class="row border-section">
                <div class="noticia">
                    <img class="izquierda" src="assets/img/emapa.png" width="60px">
                    <aside class="cabecera-titulo">
                        Formato 1<strong>(Reclamo Operacional)</strong><br>
                        Formato de Solicitud de Atención de Problemas Particulares<br>
                        Comerciales no relativos a la facturación y Problemas Operacionales
                    </aside>
                    <div class="reset"></div>
                </div>
            </div>
            <hr>
            <div class="form-group row">
                <label class="col-sm-8 col-form-label text-end col-form-label-sm">
                    CÓDIGO SOLICITUD N°
                </label>
                <div class="col-sm-4">
                  <input type="text" class="form-control form-control-sm" disabled formControlName="codigo_solicitud">
                </div>
            </div>
            
            <div class="form-group row">
                <label class="col-sm-5 col-form-label col-form-label-sm">
                    NÚMERO DE SUMINISTRO   
                </label>
                <div class="col-sm-7">
                  <input type="text" class="form-control form-control-sm" formControlName="numero_suministro">
                </div>
            </div>

            <div class="form-group row">
                <label for="colFormLabelSm" class="col-sm-5 col-form-label col-form-label-sm">
                    MODALIDAD DE ATENCIÓN DEL SOLICITUD (ESCRITO/TELEFÓNICO/WEB)
                </label>
                <div class="col-sm-7">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" formControlName="modalidad_atencion" id="inlineRadio1" value="escrito">
                        <label class="form-check-label" for="inlineRadio1">Escrito </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" formControlName="modalidad_atencion" id="inlineRadio2" value="telefono">
                        <label class="form-check-label" for="inlineRadio2">Telefónico</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" formControlName="modalidad_atencion" id="inlineRadio3" value="web">
                        <label class="form-check-label" for="inlineRadio3">Web</label>
                      </div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-5 col-form-label col-form-label-sm">
                    MOMENTO DE REGISTRO DE LA SOLICITUD 
                </label>
                <div class="col-sm-4">
                  <input type="date" class="form-control form-control-sm" placeholder="Fecha"  formControlName="fecha">
                </div>
                <div class="col-sm-3">
                    <input type="text" class="form-control form-control-sm" placeholder="Hora"  formControlName="hora">
                  </div>
            </div>
            
            <hr>
            <div class="form-group row">
                <label class="col-sm-12 col-form-label col-form-label-sm">
                <strong>NOMBRE DEL SOLICITANTE O REPRESENTANTE</strong>
                </label>
            </div>
            <div class="form-group row">
                <div class="col-sm-4">
                  <input type="text" class="form-control form-control-sm"  formControlName="apellido_paterno">
                  <small class="form-text text-center text-muted">Apellido Paterno</small>
                </div>
                <div class="col-sm-4">
                    <input type="text" class="form-control form-control-sm" formControlName="apellido_materno">
                    <small class="form-text text-center text-muted">Apellido Materno</small>
                </div>
                <div class="col-sm-4">
                    <input type="text" class="form-control form-control-sm" formControlName="nombres">
                    <small class="form-text text-center text-muted">Nombres</small>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label col-form-label-sm">
                    NÚMERO DEL DOCUMENTO DE IDENTIDAD
                </label>
                <div class="col-sm-8">
                    <input type="text" class="form-control form-control-sm" formControlName="documento_identidad">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label col-form-label-sm">
                   RAZÓN SOCIAL
                </label>
                <div class="col-sm-8">
                    <input type="text" class="form-control form-control-sm" formControlName="razon_social">
                  </div>
            </div>
            <hr>
            <div class="form-group row">
                <label class="col-sm-12 col-form-label col-form-label-sm">
                   <strong>DATOS DEL SOLICITANTE</strong>
                </label>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                  <input type="text" class="form-control form-control-sm" formControlName="ubicacion">
                  <small class="form-text text-center text-muted">(Calle, Jirón, Avenida)</small>
                </div>
                <div class="col-sm-2">
                    <input type="text" class="form-control form-control-sm" formControlName="ubicacion_numero">
                    <small class="form-text text-center text-muted">N°</small>
                </div>
                <div class="col-sm-2">
                    <input type="text" class="form-control form-control-sm" formControlName="ubicacion_manzana">
                    <small class="form-text text-center text-muted">MZ</small>
                </div>
                <div class="col-sm-2">
                    <input type="text" class="form-control form-control-sm" formControlName="ubicacion_lote">
                    <small class="form-text text-center text-muted">Lote</small>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-4">
                  <input type="text" class="form-control form-control-sm" formControlName="urbanizacion">
                  <small class="form-text text-center text-muted">Urbanización</small>
                </div>
                <div class="col-sm-4">
                    <input type="text" class="form-control form-control-sm" formControlName="distrito">
                    <small class="form-text text-center text-muted">Distrito</small>
                </div>
                <div class="col-sm-4">
                    <input type="text" class="form-control form-control-sm" formControlName="provincia">
                    <small class="form-text text-center text-muted">Provincia</small>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-4">
                  <input type="text" class="form-control form-control-sm" formControlName="telefono">
                  <small class="form-text text-center text-muted">Teléfono</small>
                </div>
                <div class="col-sm-4">
                    <input type="text" class="form-control form-control-sm" formControlName="email">
                    <small class="form-text text-center text-muted">Correo electrónico</small>
                </div>
                <div class="col-sm-4">
                </div>
            </div>
            <hr>
            <div class="form-group row">
                <label class="col-sm-12 col-form-label col-form-label-sm">
                   <strong>INFORMACIÓN DE LA SOLICITUD</strong>
                </label>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 ">
                    TIPO DEL PROBLEMA 
                </label>
                <div class="col-sm-8 for-group">
                    <input type="text" class="form-control form-control-sm" formControlName="tipo_problema">
                    <!--
                    <select class="form-control" name="miselect" formControlName="tipo_problema">
                        <option [ngValue]="item.id" *ngFor="let item of listProblems">
                            {{item.nombre}}
                        </option>
                    </select>
                    -->
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-12 col-form-label col-form-label-sm">
                  BREVE DESCRIPCIÓN DEL PROBLEMA
                </label>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <textarea row="4" class="form-control form-control-sm" formControlName="descripcion_problema"></textarea>
                </div>
            </div>
            <hr>
            <div class="form-group row">
                <div class="col-sm-12">
                    <strong>CONFORMIDAD DEL SOLICITANTE</strong>
                </div>
            </div>
            <div class="form-group row">
                <!--
                <label class="col-sm-12">
                    Mediante el presente yo {{full_name}} , identificado con DNI Nº  {{dni}}    declaro
estar conforme con la solución de la EPS  al problema presentado, descrito en la presente solicitud.
                </label>
                -->
                <label class="col-sm-12">
                    Mediante el presente, declaro estar conforme con la solución de la EPS  al problema presentado, descrito en la presente solicitud.
                </label>
            </div>
            <div class="form-group row" *ngIf="response?.success">
                <div class="col-sm-12 alert alert-success" role="alert">
                    <h4 class="alert-heading">Atención</h4>
                    <p>Solicitud registada correctamente. 
                        <br>
                    </p>
                    <hr>
                  </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-12 text-center">
                    <button *ngIf="!isSending" type="submit" class="btn btn-success" [disabled]="formModel.invalid" > Enviar </button>
                    <button *ngIf="isSending" class="btn btn-success" [disabled]="isSending"> enviando </button>
                    
                </div>
            </div>
            
        </form>
    </div>

</section>

 
<app-footer></app-footer>