import { Injectable } from '@angular/core';
import {Gerencia,Oficina} from '../models/tipoGerencia.interface';

@Injectable()

export class GerenciasServicesService {
private gerencia:Gerencia[]=[
  {
    id:1,
    name:'Gerencia General'
  },
  {
    id:2,
    name:'Gerencia de Administración y Finanzas'
  },
  {
    id:3,
    name:'Gerencia de Asesoría Jurídica'
  },
  {
    id:4,
    name:'Gerencia de Operaciones y Mantenimiento'
  },
  {
    id:5,
    name:'Gerencia Comercial'
  },
];
private oficina:Oficina[]=[
  // GERENCIA GENERAL
  {
    id:1,
    gerenciaId:1,
    name:'Oficina de Aseguramiento de la Calidad'
  },
  {
    id:2,
    gerenciaId:1,
    name:'Oficina de Desarrollo y Presupuesto'
  },
  {
    id:3,
    gerenciaId:1,
    name:'Oficina de Imagen Corporativa y Gestión Social'
  },
  // GERENCIA DE ADMINISTRACION
  {
    id:4,
    gerenciaId:2,
    name:'Equipo de Finanzas'
  },
  {
    id:5,
    gerenciaId:2,
    name:'Oficina de Contabilidad'
  },
  {
    id:6,
    gerenciaId:2,
    name:'Oficina de Logística y Control Patrimonial'
  },
  {
    id:7,
    gerenciaId:2,
    name:'Oficina de Recursos Humanos'
  },
  {
    id:8,
    gerenciaId:2,
    name:'Equipo de Tecnología de la Información y Comunicación'
  },
  // GERENCIA DE ASESORIA
  //gerencia operacioes
  {
    id:9,
    gerenciaId:4,
    name:'Oficina de Producción de Agua Potable y Tratamiento de Aguas Residuales'
  },
  {
    id:10,
    gerenciaId:4,
    name:'Oficina de Distribución y Recolección'
  },
  {
    id:11,
    gerenciaId:4,
    name:'Oficina de Ingeniería Proyectos y Obras'
  },
   //gerencia comercial
  {
    id:12,
    gerenciaId:5,
    name:'Oficina de Cobranzas y Control Comercial'
  },
  {
    id:13,
    gerenciaId:5,
    name:'Oficina de Catastro Comercial, Medición y Facturación'
  },
  {
    id:14,
    gerenciaId:5,
    name:'Oficina de Atención al Cliente'
  },
];

getGerencias():Gerencia[]{
  return this.gerencia;
}
getOficinas():Oficina[]{
  return this.oficina;
}
}
