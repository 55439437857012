<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2><i routerLink="/" style="cursor:pointer" class="bx bx-arrow-back"> </i> Nosotros</h2>
        </div>
    </div>
</section>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <h2 class="text-center">
                        {{quienes_somos?.titulo}}
                    </h2>
                    <p class="text-justify">
                        {{quienes_somos?.texto}}
                    </p>
                    <blockquote>
                        <p> {{ quienes_somos?.frase }} <br><em style="font-size: 13px;">AUTOR: Leonardo da Vinci</em></p>
                        
                    </blockquote>
                    
                </div>
            </div>
        </div>
    </div>
</section>
    
<section class="symptoms-area pt-100 pb-100">
    <div class="container">

        <div class="row">
            <div class="col-md-6">
                <div class="symptoms-content">
                    <h2 class="text-center">{{ vision?.titulo }}</h2>
                    <p class="text-justify">{{ vision?.texto }}</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="symptoms-content">
                    <h2 class="text-center"> {{ mision?.titulo }}</h2>
                    <p class="text-justify">{{ mision?.texto }}</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="funfacts-area ptb-100 jarallax">
    <div class="container">
        <div class="section-title mb-3">
            <h2 class="mb-2">
                {{ objetivos?.titulo }}
            </h2>
            <div class="col-lg-12 col-md-12">
                <p class="text-white text-justify">
                    {{ objetivos?.texto }}
                </p>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="funfacts-list">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="row">
                                <div class="col-md-12" *ngFor="let item of objetivos?.array_1">
                                    <p class="text-white text-justify pb-2 pr-2 pl-2">
                                        <i class="flaticon-tick"></i> 
                                        {{ item?.titulo }}
                                    </p>                                    
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="row">
                                <div class="col-md-12" *ngFor="let item of objetivos?.array_2">
                                    <p class="text-white text-justify pb-2 pr-2 pl-2">
                                        <i class="flaticon-tick"></i> 
                                        {{ item?.titulo }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>