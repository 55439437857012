<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2><i routerLink="/" style="cursor:pointer" class="bx bx-arrow-back"> </i> Comunicados</h2>
        </div>
    </div>
</section>

<section class="faq-area ptb-100 bg-f9f9f9">
    <div class="container">
        <div class="row">
            <div id="preloader-notas" class="preloader-iframe" *ngIf="isLoading"></div>
            <div class="col-sm-12 col-md-6 col-lg-4"  *ngIf="comunicados?.length == 0">
                No hay comunicados para mostrar
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4" *ngFor="let item of comunicados">
                <div class="box image-boxes imgboxes_style4 kl-title_style_left" >
                    <a class="imgboxes4_link imgboxes-wrapper" routerLink="/comunicados/comunicado/{{item.id}}" (click)="goToComunicado(item)">
                        <img loading="lazy" [src]=" path + item.src_imagen"  class="img-fluid imgbox_image cover-fit-img" alt="item.src_imagen" title="item.src_imagen">
                        <span class="imgboxes-border-helper"></span>
                        <h4 class="m_title imgboxes-title">
                           {{item.titulo}}
                        </h4> 
                    </a>
                    <h6 style="font-weight: bold; text-align: center;" *ngIf="item.id < 10">COMUNICADO N°0{{item.id}} - 2022</h6>
                    <h6 style="font-weight: bold; text-align: center;" *ngIf="item.id >= 10">COMUNICADO N°{{item.id}} - 2022</h6>
                    <p>
                        {{item.titulo_informativo}} 
                    </p>
                    <div class="container notas-assets">
                        <div>
                            <em class="latest_posts-acc-details">
                                {{setFecha(item.fecha_publicacion)}} por {{ item.autor }}
                            </em>
                        </div>
                        <div>
                            <a routerLink="/comunicados/comunicado/{{item.id}}" class="latest_posts-acc-more">
                                VER MÁS +
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>