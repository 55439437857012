import { Component, OnInit } from '@angular/core';
import { BeliService } from 'src/app/services/beli.service';

@Component({
  selector: 'app-resoluciones-gerenciales',
  templateUrl: './resoluciones-gerenciales.component.html',
  styleUrls: ['./resoluciones-gerenciales.component.scss']
})
export class ResolucionesGerencialesComponent implements OnInit {

  isLoading: boolean = false;
  resoluciones_gerenciales :any;
  path = this.beli.path();
  
  constructor(
    private beli: BeliService,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData () {
    this.isLoading  = true;
    this.beli.get('page/resoluciones_gerenciales').then( data => { 
      this.resoluciones_gerenciales = data.success ? data.info : [];
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }

  getDocuments(i) {

  }
}
