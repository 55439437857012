<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 style="text-align: left;"> <i routerLink="/convocatorias" style="cursor:pointer" class="bx bx-arrow-back"> </i>  Convocatoria de plazas según Decreto Legislativo N° 728</h2>
        </div>
    </div>
</section>

<section class="faq-area bg-f9f9f9">
    <div class="container">
        <div class="row">
             <div class="col-md-12" *ngIf="isLoading">
                Cargando...
            </div>
            <div class="col-md-12" *ngIf="!isLoading">
                <div class="row">
      
                    <main style="width: 100%; padding-bottom: 100px;">
	  
                      <div class="container my-3 px-0">
                          
                    </div>  
                        
                        
                        <div class="col-lg-12 pt-5 text-p">
                        <!--<img src="images/banner_dl728.jpg" class="img-fluid mb-5" alt="banner"> -->	
                            <p class="text-p my-5" style="font-weight: 500;">Convocatorias para la contratación de plazas según el Decreto Legislativo N° 728</p>
                        </div>
                        
                       <div class="container-fluid px-0">
                          
                       <div class="tab-pane fade in show active" id="panel71" role="tabpanel">
                    
                                      <div class="container-fluid">
                                        <!-- Nav tabs -->
                                       <div class="container tabs mb-4">
                                             <div class="row">
                                                   <div class="col-md-8 col-sm-6 mx-auto">
                                                         <ul class="nav md-pills nav-justified">
                                                          <li class="nav-item">
                                                            <a class="nav-link active" data-toggle="tab" href="#panel1" role="tab" aria-selected="false"><h2>VIGENTES</h2></a>
                                                          </li>
                                                          <li class="nav-item">
                                                            <a class="nav-link " data-toggle="tab" href="#panel2" role="tab" text-red="" aria-selected="true"><h2>CONCLUIDAS</h2></a>
                                                          </li>
                                                         </ul>    
                                                 </div>
                    
                                           </div> 
                                        </div>
                    
                                        <!-- Tab panels -->
                                        <div class="tab-content px-0 pt-0" style="background-color: #F9F9F9 !important;
                                        border: none !important;">
                    
                                <!-- Panel 1 -->
                                  <div class="tab-pane fade active show" id="panel1" role="tabpanel">
                                  <div *ngIf="items2024.length > 0" id="collapseOne0" class="collapse show" role="tabpanel" aria-labelledby="headingOne0" data-parent="#accordionEx" >
                                    <div class="card-body px-0">
                                      <!--<p class="my-5 text-p red-text">En este momento, no tenemos convocatorias en el marco del Decreto Legislativo N° 728.</p>-->
                                       <div class="card-body px-0">
                                        <div class="table-responsive">
                                          <table class="table table-sm table-bordered table-striped table-hover stacktable small-only">
                                            <tbody *ngFor="let item of items2024">
                                            <tr class="  ">
                                              <td class="st-key">N°</td>
                                              <td class="st-val " style="font-weight: 500;">
                                                N°{{item.id}}
                                              </td>
                                            </tr>
                                            <tr class="">
                                              <td class="st-key">Descripción</td>
                                              <td class="st-val" style="font-weight: 500;">
                                                {{item.nombre}}
                                            </td>
                                          </tr>
                                          <tr class="">
                                            <td class="st-key">Publicado</td>
                                            <td class="st-val" style="font-weight: 500;">
                                                {{item.created_at|date:"dd/MM/yyyy"}}
                                          </td>
                                        </tr>
                                          <tr class="">
                                            <td class="st-key">Bases</td>
                                            <td class="st-val ">
                                              <a *ngIf="item.url_bases_convocatoria; else templateName" target="_blank" href="{{path + item.url_bases_convocatoria}}">
                                                <img src="assets/img/pdf.png" width="20">
                                            </a>
                                            <ng-template #templateName>
                                                 <img src="assets/img/remove.png" width="20">
                                            </ng-template>
                                          
                                            </td>
                                          </tr>
                                         <tr class=""><td class="st-key">Evaluación Curricular</td>
                                          <td class="st-val ">
                                            <a *ngIf="item.url_evaluacion_hojavida; else templateName" target="_blank" href="{{path + item.url_evaluacion_hojavida}}">
                                              <img src="assets/img/pdf.png" width="20">
                                          </a>
                                          <ng-template #templateName>
                                            <img src="assets/img/remove.png" width="20">
                                          </ng-template>
                                            </td></tr><tr class=""><td class="st-key">Entrevista Personal</td>
                                              <td class="st-val ">
                                                <a *ngIf="item.url_evaluacion_entrevista; else templateName" target="_blank" href="{{path + item.url_evaluacion_entrevista}}">
                                                  <img src="assets/img/pdf.png" width="20">
                                              </a>
                                              <ng-template #templateName>
                                                   <img src="assets/img/remove.png" width="20">
                                              </ng-template>
                                            
                                            </td></tr><tr class=""><td class="st-key">Resultados Finales</td>
                                              <td class="st-val ">
                                                <a *ngIf="item.url_resultados_convocatoria; else templateName" target="_blank" href="{{path + item.url_resultados_convocatoria}}">
                                                  <img src="assets/img/pdf.png" width="20">
                                              </a>
                                              <ng-template #templateName>
                                                   <img src="assets/img/remove.png" width="20">
                                              </ng-template>
                                            
                                            </td></tr>
                                            <tr><th colspan="2" class="st-head-row">
                                             
                                            </th></tr>
                                          </tbody>
                                         </table>
                                       
                                            <table summary="Esta tabla muestra las convocatorias vigentes del Decreto legislativo número 728 junto a sus documentos" id="tabla1" class="table table-sm table-bordered table-striped table-hover stacktable large-only">
                                          <thead class="thead-dark">
                                            <tr>
                                            <th>N°</th>
                                            <th>Descripción</th>
                                            <th>Publicado</th>
                                            <th>Bases</th>
                                            <th>Evaluación Curricular</th>
                                            <th>Entrevista Personal</th>
                                            <th>Resultados Finales</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          <tr *ngFor="let item of items2024">
                                            <td style="font-weight: 500;">
                                              N°{{item.id}}
                                            </td>
                                            <td style="font-weight: 500;">
                                              {{item.nombre}}
                                            </td>
                                            <td style="font-weight: 500;">
                                                {{item.created_at|date:"dd/MM/yyyy"}}
                                              </td>
                                              <td style="text-align: center;">
                                                <a *ngIf="item.url_bases_convocatoria; else templateName" target="_blank" href="{{path + item.url_bases_convocatoria}}">
                                                    <img src="assets/img/pdf.png" width="20">
                                                </a>
                                                <ng-template #templateName>
                                                  <img src="assets/img/remove.png" width="20">
                                                </ng-template>
                                              
                                                
                                            </td>
                                            <td style="text-align: center;">
                                              <a *ngIf="item.url_evaluacion_hojavida; else templateName" target="_blank" href="{{path + item.url_evaluacion_hojavida}}">
                                                  <img src="assets/img/pdf.png" width="20">
                                              </a>
                                              <ng-template #templateName>
                                                <img src="assets/img/remove.png" width="20">
                                              </ng-template>
                                            
                                              
                                          </td>
                                          <td style="text-align: center;">
                                            <a *ngIf="item.url_evaluacion_entrevista; else templateName" target="_blank" href="{{path + item.url_evaluacion_entrevista}}">
                                                <img src="assets/img/pdf.png" width="20">
                                            </a>
                                            <ng-template #templateName>
                                              <img src="assets/img/remove.png" width="20">
                                            </ng-template>
                                          
                                            
                                        </td>
                                        <td style="text-align: center;">
                                          <a *ngIf="item.url_resultados_convocatoria; else templateName" target="_blank" href="{{path + item.url_resultados_convocatoria}}">
                                              <img src="assets/img/pdf.png" width="20">
                                          </a>
                                          <ng-template #templateName>
                                            <img src="assets/img/remove.png" width="20">
                                          </ng-template>
                                        
                                          
                                      </td>
                                           </tr>		
 
                                          </tbody>
                                          </table>
                                          <small style="font-weight: 500;">Para mayor información, comuníquese al correo: <a href = "mailto: recursoshumanos@emapahuaral.com.pe">recursoshumanos@emapahuaral.com.pe</a></small>

                                               </div>
                                      </div> 
                                        
                                        
                                      </div>
                                    </div>
                                    <div class="col-md-12" style="background-color: #F9F9F9 !important; " *ngIf="items2024.length == 0">
                                      <div class="container" style="padding-top: 30px;  text-align: center;">
                                        <h6 style="font-weight: bold; color: #088A85; "> Por el momento no contamos con convocatorias vigentes.</h6>
                                        <h6 style="color: #000; "> Para más información y actualización de convocatorias, síguenos en <a href="https://m.facebook.com/huaralemapa?mibextid=ZbWKwL&wtsid=rdr_0SSE5kZN3Q8zwUCEB" target="_blank">Facebook</a></h6>
                                    </div>
                                  </div>
                                  
                                  </div>


                                


                                          <!-- Panel 2 -->
                                          <div class="tab-pane fade show" id="panel2" role="tabpanel">
                                  
                                  <div class="accordion md-accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                    
                                    <div class="card" style="background-color: #F9F9F9 !important;
                                    border: none !important;">
                                    <div class="card-header" role="tab" id="heading2023" style="border-radius: 30px;">
                                      <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseO" aria-expanded="false" aria-controls="collapseO">
                                      <h5 class="mb-0" style="font-weight: bold;">
                                        Convocatorias 2024 <i class="fas fa-chevron-circle-down rotate-icon"></i>				 
                                      </h5>
                                      </a>
                                    </div>
                                    <div id="collapseO" class="collapse show" role="tabpanel" aria-labelledby="heading2023" data-parent="#accordionEx" >
                                      <div class="card-body px-0">
                                         <div class="table-responsive">
                                          <table class="table table-sm table-bordered table-striped table-hover stacktable small-only">
                                            <tbody *ngFor="let item of concluidas2024">
                                            <tr class="  ">
                                              <td class="st-key" style="font-weight: 500;">N°</td>
                                              <td class="st-val " style="font-weight: 500;">
                                                N°{{item.id}}
                                              </td>
                                            </tr>
                                            <tr class="">
                                              <td class="st-key" >Descripcion</td>
                                              <td class="st-val" style="font-weight: 500;">
                                                {{item.nombre}}
                                            </td>
                                          </tr>
                                          <tr class="">
                                            <td class="st-key" style="font-weight: 500;">Publicado</td>
                                            <td class="st-val" style="font-weight: 500;">
                                               
                                                    {{item.created_at|date:"dd/MM/yyyy"}}
                                                
                                          
                                            </td>
                                          </tr>
                                          <tr class="">
                                            <td class="st-key">Bases</td>
                                            <td class="st-val ">
                                              <a *ngIf="item.url_bases_convocatoria; else templateName" target="_blank" href="{{path + item.url_bases_convocatoria}}">
                                                <img src="assets/img/pdf.png" width="20">
                                            </a>
                                            <ng-template #templateName>
                                                 <img src="assets/img/remove.png" width="20">
                                            </ng-template>
                                          
                                            </td>
                                          </tr>
                                         <tr class=""><td class="st-key">Evaluación Curricular</td>
                                          <td class="st-val ">
                                            <a *ngIf="item.url_evaluacion_hojavida; else templateName" target="_blank" href="{{path + item.url_evaluacion_hojavida}}">
                                              <img src="assets/img/pdf.png" width="20">
                                          </a>
                                          <ng-template #templateName>
                                            <img src="assets/img/remove.png" width="20">
                                          </ng-template>
                                            </td></tr><tr class=""><td class="st-key">Entrevista Personal</td>
                                              <td class="st-val ">
                                                <a *ngIf="item.url_evaluacion_entrevista; else templateName" target="_blank" href="{{path + item.url_evaluacion_entrevista}}">
                                                  <img src="assets/img/pdf.png" width="20">
                                              </a>
                                              <ng-template #templateName>
                                                <img src="assets/img/remove.png" width="20">
                                              </ng-template>
                                            
                                            </td></tr><tr class=""><td class="st-key">Resultados Finales</td>
                                              <td class="st-val ">
                                                <a *ngIf="item.url_resultados_convocatoria; else templateName" target="_blank" href="{{path + item.url_resultados_convocatoria}}">
                                                  <img src="assets/img/pdf.png" width="20">
                                              </a>
                                              <ng-template #templateName>
                                                <img src="assets/img/remove.png" width="20">
                                              </ng-template>
                                            
                                            </td></tr>
                                            <tr><th colspan="2" class="st-head-row">
                                             
                                            </th></tr>
                                          </tbody>
                                         </table>
                                            <table summary="Esta tabla muestra las convocatorias vigentes del Decreto legislativo número 728 junto a sus documentos" id="tabla1" class="table table-sm table-bordered table-striped table-hover stacktable large-only">
                                          <thead class="thead-dark">
                                            <tr>
                                            <th style="font-weight: bold;">N°</th>
                                            <th style="font-weight: bold;">Descripción</th>
                                            <th style="font-weight: bold;">Publicado</th>
                                            <th style="font-weight: bold;">Bases</th>
                                            <th style="font-weight: bold;">Evaluación Curricular</th>
                                            <th style="font-weight: bold;">Entrevista Personal</th>
                                            <th style="font-weight: bold;">Resultados Finales</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          <tr *ngFor="let item of concluidas2024">
                                            <td style="font-weight: 500;">
                                              N°{{item.id}}
                                            </td>
                                            <td style="font-weight: 500;">
                                              {{item.nombre}}
                                            </td>
                                            <td style="font-weight: 500;">
                                                {{item.created_at|date:"dd/MM/yyyy"}}
                                              </td>

                                              <td style="text-align: center;">
                                                <a *ngIf="item.url_bases_convocatoria; else templateName" target="_blank" href="{{path + item.url_bases_convocatoria}}">
                                                    <img src="assets/img/archivo-pdf.png" width="25">
                                                </a>
                                                <ng-template #templateName>
                                                     <img src="assets/img/remove.png" width="20">
                                                </ng-template>
                                              
                                                
                                            </td>
                                            <td style="text-align: center;">
                                              <a *ngIf="item.url_evaluacion_hojavida; else templateName" target="_blank" href="{{path + item.url_evaluacion_hojavida}}">
                                                  <img src="assets/img/archivo-pdf.png" width="25">
                                              </a>
                                              <ng-template #templateName>
                                                   <img src="assets/img/remove.png" width="20">
                                              </ng-template>
                                            
                                              
                                          </td>
                                          <td style="text-align: center;">
                                            <a *ngIf="item.url_evaluacion_entrevista; else templateName" target="_blank" href="{{path + item.url_evaluacion_entrevista}}">
                                                <img src="assets/img/archivo-pdf.png" width="25">
                                            </a>
                                            <ng-template #templateName>
                                                 <img src="assets/img/remove.png" width="20">
                                            </ng-template>
                                          
                                            
                                        </td>
                                        <td style="text-align: center;">
                                          <a *ngIf="item.url_resultados_convocatoria; else templateName" target="_blank" href="{{path + item.url_resultados_convocatoria}}">
                                              <img src="assets/img/archivo-pdf.png" width="25">
                                          </a>
                                          <ng-template #templateName>
                                               <img src="assets/img/remove.png" width="20">
                                          </ng-template>
                                        
                                          
                                      </td>
                                           </tr>		
 
                                          </tbody>
                                          </table>
                                          <small style="font-weight: 500;">Para mayor información, comuníquese al correo: <a href = "mailto: recursoshumanos@emapahuaral.com.pe">recursoshumanos@emapahuaral.com.pe</a></small>
                                          </div>
                                      </div>
                                    </div>
                                    </div>	
                                  <!-- Accordion card -->
                                    <div class="card" style="background-color: #F9F9F9 !important;
                                    border: none !important;">
                                    <div class="card-header" role="tab" id="heading2023" style="border-radius: 30px;">
                                      <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseO" aria-expanded="false" aria-controls="collapseO">
                                      <h5 class="mb-0" style="font-weight: bold;">
                                        Convocatorias 2023 <i class="fas fa-chevron-circle-down rotate-icon"></i>				 
                                      </h5>
                                      </a>
                                    </div>
                                    <div id="collapseO" class="collapse" role="tabpanel" aria-labelledby="heading2023" data-parent="#accordionEx" >
                                      <div class="card-body px-0">
                                         <div class="table-responsive">
                                          <table class="table table-sm table-bordered table-striped table-hover stacktable small-only">
                                            <tbody *ngFor="let item of items2023">
                                            <tr class="  ">
                                              <td class="st-key" style="font-weight: 500;">N°</td>
                                              <td class="st-val " style="font-weight: 500;">
                                                N°{{item.id}}
                                              </td>
                                            </tr>
                                            <tr class="">
                                              <td class="st-key" >Descripcion</td>
                                              <td class="st-val" style="font-weight: 500;">
                                                {{item.nombre}}
                                            </td>
                                          </tr>
                                          <tr class="">
                                            <td class="st-key" style="font-weight: 500;">Publicado</td>
                                            <td class="st-val" style="font-weight: 500;">
                                               
                                                    {{item.created_at|date:"dd/MM/yyyy"}}
                                                
                                          
                                            </td>
                                          </tr>
                                          <tr class="">
                                            <td class="st-key">Bases</td>
                                            <td class="st-val ">
                                              <a *ngIf="item.url_bases_convocatoria; else templateName" target="_blank" href="{{path + item.url_bases_convocatoria}}">
                                                <img src="assets/img/pdf.png" width="20">
                                            </a>
                                            <ng-template #templateName>
                                                 <img src="assets/img/remove.png" width="20">
                                            </ng-template>
                                          
                                            </td>
                                          </tr>
                                         <tr class=""><td class="st-key">Evaluación Curricular</td>
                                          <td class="st-val ">
                                            <a *ngIf="item.url_evaluacion_hojavida; else templateName" target="_blank" href="{{path + item.url_evaluacion_hojavida}}">
                                              <img src="assets/img/pdf.png" width="20">
                                          </a>
                                          <ng-template #templateName>
                                            <img src="assets/img/remove.png" width="20">
                                          </ng-template>
                                            </td></tr><tr class=""><td class="st-key">Entrevista Personal</td>
                                              <td class="st-val ">
                                                <a *ngIf="item.url_evaluacion_entrevista; else templateName" target="_blank" href="{{path + item.url_evaluacion_entrevista}}">
                                                  <img src="assets/img/pdf.png" width="20">
                                              </a>
                                              <ng-template #templateName>
                                                <img src="assets/img/remove.png" width="20">
                                              </ng-template>
                                            
                                            </td></tr><tr class=""><td class="st-key">Resultados Finales</td>
                                              <td class="st-val ">
                                                <a *ngIf="item.url_resultados_convocatoria; else templateName" target="_blank" href="{{path + item.url_resultados_convocatoria}}">
                                                  <img src="assets/img/pdf.png" width="20">
                                              </a>
                                              <ng-template #templateName>
                                                <img src="assets/img/remove.png" width="20">
                                              </ng-template>
                                            
                                            </td></tr>
                                            <tr><th colspan="2" class="st-head-row">
                                             
                                            </th></tr>
                                          </tbody>
                                         </table>
                                            <table summary="Esta tabla muestra las convocatorias vigentes del Decreto legislativo número 728 junto a sus documentos" id="tabla1" class="table table-sm table-bordered table-striped table-hover stacktable large-only">
                                          <thead class="thead-dark">
                                            <tr>
                                            <th style="font-weight: bold;">N°</th>
                                            <th style="font-weight: bold;">Descripción</th>
                                            <th style="font-weight: bold;">Publicado</th>
                                            <th style="font-weight: bold;">Bases</th>
                                            <th style="font-weight: bold;">Evaluación Curricular</th>
                                            <th style="font-weight: bold;">Entrevista Personal</th>
                                            <th style="font-weight: bold;">Resultados Finales</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          <tr *ngFor="let item of items2023">
                                            <td style="font-weight: 500;">
                                              N°{{item.id}}
                                            </td>
                                            <td style="font-weight: 500;">
                                              {{item.nombre}}
                                            </td>
                                            <td style="font-weight: 500;">
                                                {{item.created_at|date:"dd/MM/yyyy"}}
                                              </td>

                                              <td style="text-align: center;">
                                                <a *ngIf="item.url_bases_convocatoria; else templateName" target="_blank" href="{{path + item.url_bases_convocatoria}}">
                                                    <img src="assets/img/archivo-pdf.png" width="25">
                                                </a>
                                                <ng-template #templateName>
                                                     <img src="assets/img/remove.png" width="20">
                                                </ng-template>
                                              
                                                
                                            </td>
                                            <td style="text-align: center;">
                                              <a *ngIf="item.url_evaluacion_hojavida; else templateName" target="_blank" href="{{path + item.url_evaluacion_hojavida}}">
                                                  <img src="assets/img/archivo-pdf.png" width="25">
                                              </a>
                                              <ng-template #templateName>
                                                   <img src="assets/img/remove.png" width="20">
                                              </ng-template>
                                            
                                              
                                          </td>
                                          <td style="text-align: center;">
                                            <a *ngIf="item.url_evaluacion_entrevista; else templateName" target="_blank" href="{{path + item.url_evaluacion_entrevista}}">
                                                <img src="assets/img/archivo-pdf.png" width="25">
                                            </a>
                                            <ng-template #templateName>
                                                 <img src="assets/img/remove.png" width="20">
                                            </ng-template>
                                          
                                            
                                        </td>
                                        <td style="text-align: center;">
                                          <a *ngIf="item.url_resultados_convocatoria; else templateName" target="_blank" href="{{path + item.url_resultados_convocatoria}}">
                                              <img src="assets/img/archivo-pdf.png" width="25">
                                          </a>
                                          <ng-template #templateName>
                                               <img src="assets/img/remove.png" width="20">
                                          </ng-template>
                                        
                                          
                                      </td>
                                           </tr>		
 
                                          </tbody>
                                          </table>
                                          <small style="font-weight: 500;">Para mayor información, comuníquese al correo: <a href = "mailto: recursoshumanos@emapahuaral.com.pe">recursoshumanos@emapahuaral.com.pe</a></small>
                                          </div>
                                      </div>
                                    </div>
                                    </div>	
                                    
                                    
                                     <!-- Accordion card -->
                                     <div class="card" style="background-color: #F9F9F9 !important;
                                     border: none !important;">
                                      <div class="card-header" role="tab" id="headingThree7">
                                        <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree7" aria-expanded="false" aria-controls="collapseThree7">
                                        <h5 class="mb-0" style="font-weight: bold;">
                                          Convocatorias 2022 <i class="fas fa-chevron-circle-down rotate-icon"></i>
                                        </h5>
                                        </a>
                                      </div>
                                      <div id="collapseThree7" class="collapse" role="tabpanel" aria-labelledby="headingThree7" data-parent="#accordionEx" >
                                        <div class="card-body px-0">
                                          <div class="table-responsive">
                                            <table class="table table-sm table-bordered table-striped table-hover stacktable small-only">
                                              <tbody *ngFor="let item of items2022">
                                              <tr class="  ">
                                                <td class="st-key" style="font-weight: 500;">N°</td>
                                                <td class="st-val " style="font-weight: 500;">
                                                  N°{{item.id}}
                                                </td>
                                              </tr>
                                              <tr class="">
                                                <td class="st-key">Descripcion</td>
                                                <td class="st-val " style="font-weight: 500;">
                                                  {{item.nombre}}
                                              </td>
                                            </tr>
                                            <tr class="">
                                              <td class="st-key" style="font-weight: 500;">Publicado</td>
                                              <td class="st-val " style="font-weight: 500;">
                                                  {{item.created_at|date:"dd/MM/yyyy"}}
                                            
                                              </td>
                                            </tr>
                                            <tr class="">
                                              <td class="st-key">Bases</td>
                                              <td class="st-val ">
                                                <a *ngIf="item.url_bases_convocatoria; else templateName" target="_blank" href="{{path + item.url_bases_convocatoria}}">
                                                  <img src="assets/img/pdf.png" width="20">
                                              </a>
                                              <ng-template #templateName>
                                                   <img src="assets/img/remove.png" width="20">
                                              </ng-template>
                                            
                                              </td>
                                            </tr>
                                       
                                           <tr class=""><td class="st-key">Evaluación Curricular</td>
                                            <td class="st-val ">
                                              <a *ngIf="item.url_evaluacion_hojavida; else templateName" target="_blank" href="{{path + item.url_evaluacion_hojavida}}">
                                                <img src="assets/img/pdf.png" width="20">
                                            </a>
                                            <ng-template #templateName>
                                                 <img src="assets/img/remove.png" width="20">
                                            </ng-template>
                                              </td></tr><tr class=""><td class="st-key">Entrevista Personal</td>
                                                <td class="st-val ">
                                                  <a *ngIf="item.url_evaluacion_entrevista; else templateName" target="_blank" href="{{path + item.url_evaluacion_entrevista}}">
                                                    <img src="assets/img/pdf.png" width="20">
                                                </a>
                                                <ng-template #templateName>
                                                     <img src="assets/img/remove.png" width="20">
                                                </ng-template>
                                              
                                              </td></tr><tr class=""><td class="st-key">Resultados Finales</td>
                                                <td class="st-val ">
                                                  <a *ngIf="item.url_resultados_convocatoria; else templateName" target="_blank" href="{{path + item.url_resultados_convocatoria}}">
                                                    <img src="assets/img/pdf.png" width="20">
                                                </a>
                                                <ng-template #templateName>
                                                     <img src="assets/img/remove.png" width="20">
                                                </ng-template>
                                              
                                              </td></tr>
                                              <tr><th colspan="2" class="st-head-row">
                                               
                                              </th></tr>
                                            </tbody>
                                            </table>
                                              <table summary="Esta tabla muestra las convocatorias vigentes del Decreto legislativo número 728 junto a sus documentos" id="tabla1" class="table table-sm table-bordered table-striped table-hover stacktable large-only">
                                            <thead class="thead-dark">
                                              <tr>
                                                <th style="font-weight: bold;">N°</th>
                                                <th style="font-weight: bold;">Descripción</th>
                                                <th style="font-weight: bold;">Publicado</th>
                                                <th style="font-weight: bold;">Bases</th>
                                                <th style="font-weight: bold;">Evaluación Curricular</th>
                                                <th style="font-weight: bold;">Entrevista Personal</th>
                                                <th style="font-weight: bold;">Resultados Finales</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                            <tr *ngFor="let item of items2022">
                                              <td style="font-weight: 500;">
                                                N°{{item.id}}
                                              </td>
                                              <td style="font-weight: 500;">
                                                {{item.nombre}}
                                              </td>
                                              <td style="font-weight: 500;">
                                                {{item.created_at|date:"dd/MM/yyyy"}}
                                              </td>
                                                <td style="text-align: center;">
                                                  <a *ngIf="item.url_bases_convocatoria; else templateName" target="_blank" href="{{path + item.url_bases_convocatoria}}">
                                                      <img src="assets/img/pdf.png" width="20">
                                                  </a>
                                                  <ng-template #templateName>
                                                       <img src="assets/img/remove.png" width="20">
                                                  </ng-template>
                                                
                                                  
                                              </td>
                                              <td style="text-align: center;">
                                                <a *ngIf="item.url_evaluacion_hojavida; else templateName" target="_blank" href="{{path + item.url_evaluacion_hojavida}}">
                                                    <img src="assets/img/pdf.png" width="20">
                                                </a>
                                                <ng-template #templateName>
                                                     <img src="assets/img/remove.png" width="20">
                                                </ng-template>
                                              
                                                
                                            </td>
                                            <td style="text-align: center;">
                                              <a *ngIf="item.url_evaluacion_entrevista; else templateName" target="_blank" href="{{path + item.url_evaluacion_entrevista}}">
                                                  <img src="assets/img/pdf.png" width="20">
                                              </a>
                                              <ng-template #templateName>
                                                   <img src="assets/img/remove.png" width="20">
                                              </ng-template>
                                            
                                              
                                          </td>
                                          <td style="text-align: center;">
                                            <a *ngIf="item.url_resultados_convocatoria; else templateName" target="_blank" href="{{path + item.url_resultados_convocatoria}}">
                                                <img src="assets/img/pdf.png" width="20">
                                            </a>
                                            <ng-template #templateName>
                                                 <img src="assets/img/remove.png" width="20">
                                            </ng-template>
                                          
                                            
                                        </td>
                                             </tr>		
   
                                            </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                      </div>

                                    <!-- Accordion card -->
                                    <div class="card" style="background-color: #F9F9F9 !important;
                                    border: none !important;">
                                    <div class="card-header" role="tab" id="headingThree8">
                                      <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree8" aria-expanded="false" aria-controls="collapseThree8">
                                      <h5 class="mb-0" style="font-weight: bold;">
                                        Convocatorias 2021 <i class="fas fa-chevron-circle-down rotate-icon"></i>
                                      </h5>
                                      </a>
                                    </div>
                                    <div id="collapseThree8" class="collapse" role="tabpanel" aria-labelledby="headingThree8" data-parent="#accordionEx" >
                                      <div class="card-body px-0">
                                        <div class="table-responsive">
                                          <table class="table table-sm table-bordered table-striped table-hover stacktable small-only">
                                            <tbody *ngFor="let item of items2021">
                                            <tr class="  ">
                                              <td class="st-key" style="font-weight: 500;">N°</td>
                                              <td class="st-val " style="font-weight: 500;">
                                                N°{{item.id}}
                                              </td>
                                            </tr>
                                            <tr class="">
                                              <td class="st-key" style="font-weight: 500;">Descripcion</td>
                                              <td class="st-val " style="font-weight: 500;">
                                                {{item.nombre}}
                                            </td>
                                          </tr>
                                          <tr class="">
                                            <td class="st-key" style="font-weight: 500;">Publicado</td>
                                            <td class="st-val " style="font-weight: 500;">
                                              {{item.created_at|date:"dd/MM/yyyy"}}
                                          </td>
                                        </tr>
                                          <tr class="">
                                            <td class="st-key">Bases</td>
                                            <td class="st-val ">
                                              <a *ngIf="item.url_bases_convocatoria; else templateName" target="_blank" href="{{path + item.url_bases_convocatoria}}">
                                                <img src="assets/img/pdf.png" width="20">
                                            </a>
                                            <ng-template #templateName>
                                              <img src="assets/img/remove.png" width="20">
                                            </ng-template>
                                            </td>
                                          </tr>
                                         <tr class=""><td class="st-key">Evaluación Curricular</td>
                                          <td class="st-val ">
                                            <a *ngIf="item.url_evaluacion_hojavida; else templateName" target="_blank" href="{{path + item.url_evaluacion_hojavida}}">
                                              <img src="assets/img/pdf.png" width="20">
                                          </a>
                                          <ng-template #templateName>
                                               <img src="assets/img/remove.png" width="20">
                                          </ng-template>
                                            </td></tr><tr class=""><td class="st-key">Entrevista Personal</td>
                                              <td class="st-val ">
                                                <a *ngIf="item.url_evaluacion_entrevista; else templateName" target="_blank" href="{{path + item.url_evaluacion_entrevista}}">
                                                  <img src="assets/img/pdf.png" width="20">
                                              </a>
                                              <ng-template #templateName>
                                                   <img src="assets/img/remove.png" width="20">
                                              </ng-template>
                                            
                                            </td></tr><tr class=""><td class="st-key">Resultados Finales</td>
                                              <td class="st-val ">
                                                <a *ngIf="item.url_resultados_convocatoria; else templateName" target="_blank" href="{{path + item.url_resultados_convocatoria}}">
                                                  <img src="assets/img/pdf.png" width="20">
                                              </a>
                                              <ng-template #templateName>
                                                   <img src="assets/img/remove.png" width="20">
                                              </ng-template>
                                            
                                            </td></tr>
                                            <tr><th colspan="2" class="st-head-row">
                                             
                                            </th></tr>
                                          </tbody>
                                          </table>
                                         
                                            <table summary="Esta tabla muestra las convocatorias vigentes del Decreto legislativo número 728 junto a sus documentos" id="tabla1" class="table table-sm table-bordered table-striped table-hover stacktable large-only">
                                          <thead class="thead-dark">
                                            <tr>
                                            <th>N°</th>
                                            <th>Descripción</th>
                                            <th>Bases</th>
                                            <th>Evaluación Curricular</th>
                                            <th>Entrevista Personal</th>
                                            <th>Resultados Finales</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          <tr *ngFor="let item of items2021">
                                            <td style="font-weight: 500;">
                                              N°{{item.id}}
                                            </td>
                                            <td style="font-weight: 500;">
                                              {{item.nombre}}
                                            </td>

                                              <td style="text-align: center;">
                                                <a *ngIf="item.url_bases_convocatoria; else templateName" target="_blank" href="{{path + item.url_bases_convocatoria}}">
                                                    <img src="assets/img/pdf.png" width="20">
                                                </a>
                                                <ng-template #templateName>
                                                     <img src="assets/img/remove.png" width="20">
                                                </ng-template>
                                            </td>
                                            <td style="text-align: center;">
                                              <a *ngIf="item.url_evaluacion_hojavida; else templateName" target="_blank" href="{{path + item.url_evaluacion_hojavida}}">
                                                  <img src="assets/img/pdf.png" width="20">
                                              </a>
                                              <ng-template #templateName>
                                                   <img src="assets/img/remove.png" width="20">
                                              </ng-template>
                                          </td>
                                          <td style="text-align: center;">
                                            <a *ngIf="item.url_evaluacion_entrevista; else templateName" target="_blank" href="{{path + item.url_evaluacion_entrevista}}">
                                                <img src="assets/img/pdf.png" width="20">
                                            </a>
                                            <ng-template #templateName>
                                                 <img src="assets/img/remove.png" width="20">
                                            </ng-template>                                         
                                        </td>
                                        <td style="text-align: center;">
                                          <a *ngIf="item.url_resultados_convocatoria; else templateName" target="_blank" href="{{path + item.url_resultados_convocatoria}}">
                                              <img src="assets/img/pdf.png" width="20">
                                          </a>
                                          <ng-template #templateName>
                                               <img src="assets/img/remove.png" width="20">
                                          </ng-template>                                                                                                                                                                
                                        </td>
                                           </tr>		
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <!--<div class="card" style="background-color: #F9F9F9 !important;
                                    border: none !important;">
                                      <div class="card-header" role="tab" id="headingThree9">
                                        <a class="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapseThree9" aria-expanded="false" aria-controls="collapseThree9">
                                        <h5 class="mb-0" style="font-weight: bold;">
                                          Convocatorias 2020 <i class="fas fa-chevron-circle-down rotate-icon"></i>
                                        </h5>
                                        </a>
                                      </div>
                                      <div id="collapseThree9" class="collapse" role="tabpanel" aria-labelledby="headingThree9" data-parent="#accordionEx" >
                                        <div class="card-body px-0">
                                         <div class="table-responsive">
                                          <table class="table table-sm table-bordered table-striped table-hover stacktable small-only">
                                            <tbody *ngFor="let item of items2020">
                                            <tr class="  ">
                                              <td class="st-key">N°</td>
                                              <td class="st-val ">
                                                N°{{item.id}}
                                              </td>
                                            </tr>
                                            <tr class="">
                                              <td class="st-key">Descripcion</td>
                                              <td class="st-val ">
                                                {{item.nombre}}
                                            </td>
                                          </tr>
                                          <tr class="">
                                            <td class="st-key">Bases</td>
                                            <td class="st-val ">
                                              <a *ngIf="item.url_bases_convocatoria; else templateName" target="_blank" href="{{path + item.url_bases_convocatoria}}">
                                                <img src="assets/img/pdf.png" width="20">
                                            </a>
                                            <ng-template #templateName>
                                                 <img src="assets/img/remove.png" width="20">
                                            </ng-template>
                                          
                                            </td>
                                          </tr>
                                         <tr class=""><td class="st-key">Evaluación Curricular</td>
                                          <td class="st-val ">
                                            <a *ngIf="item.url_evaluacion_hojavida; else templateName" target="_blank" href="{{path + item.url_evaluacion_hojavida}}">
                                              <img src="assets/img/pdf.png" width="20">
                                          </a>
                                          <ng-template #templateName>
                                               <img src="assets/img/remove.png" width="20">
                                          </ng-template>
                                            </td></tr><tr class=""><td class="st-key">Entrevista Personal</td>
                                              <td class="st-val ">
                                                <a *ngIf="item.url_evaluacion_entrevista; else templateName" target="_blank" href="{{path + item.url_evaluacion_entrevista}}">
                                                  <img src="assets/img/pdf.png" width="20">
                                              </a>
                                              <ng-template #templateName>
                                                   <img src="assets/img/remove.png" width="20">
                                              </ng-template>
                                            
                                            </td></tr><tr class=""><td class="st-key">Resultados Finales</td>
                                              <td class="st-val ">
                                                <a *ngIf="item.url_resultados_convocatoria; else templateName" target="_blank" href="{{path + item.url_resultados_convocatoria}}">
                                                  <img src="assets/img/pdf.png" width="20">
                                              </a>
                                              <ng-template #templateName>
                                                   <img src="assets/img/remove.png" width="20">
                                              </ng-template>
                                            
                                            </td></tr>
                                            <tr><th colspan="2" class="st-head-row">
                                             
                                            </th></tr>
                                          </tbody>
                                          </table>
                                            <table summary="Esta tabla muestra las convocatorias vigentes del Decreto legislativo número 728 junto a sus documentos" id="tabla1" class="table table-sm table-bordered table-striped table-hover stacktable large-only">
                                          <thead class="thead-dark">
                                            <tr>
                                            <th>N°</th>
                                            <th>Descripción</th>
                                            <th>Bases</th>
                                            <th>Evaluación Curricular</th>
                                            <th>Entrevista Personal</th>
                                            <th>Resultados Finales</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                          <tr *ngFor="let item of items2020">
                                            <td>
                                              N°{{item.id}}
                                            </td>
                                            <td>
                                              {{item.nombre}}
                                            </td>

                                              <td style="text-align: center;">
                                                <a *ngIf="item.url_bases_convocatoria; else templateName" target="_blank" href="{{path + item.url_bases_convocatoria}}">
                                                    <img src="assets/img/pdf.png" width="20">
                                                </a>
                                                <ng-template #templateName>
                                                     <img src="assets/img/remove.png" width="20">
                                                </ng-template>
                                                                
                                            </td>
                                            <td style="text-align: center;">
                                              <a *ngIf="item.url_evaluacion_hojavida; else templateName" target="_blank" href="{{path + item.url_evaluacion_hojavida}}">
                                                  <img src="assets/img/pdf.png" width="20">
                                              </a>
                                              <ng-template #templateName>
                                                   <img src="assets/img/remove.png" width="20">
                                              </ng-template>
                                            
                                              
                                          </td>
                                          <td style="text-align: center;">
                                            <a *ngIf="item.url_evaluacion_entrevista; else templateName" target="_blank" href="{{path + item.url_evaluacion_entrevista}}">
                                                <img src="assets/img/pdf.png" width="20">
                                            </a>
                                            <ng-template #templateName>
                                                 <img src="assets/img/remove.png" width="20">
                                            </ng-template>
                                          
                                            
                                        </td>
                                        <td style="text-align: center;">
                                          <a *ngIf="item.url_resultados_convocatoria; else templateName" target="_blank" href="{{path + item.url_resultados_convocatoria}}">
                                              <img src="assets/img/pdf.png" width="20">
                                          </a>
                                          <ng-template #templateName>
                                               <img src="assets/img/remove.png" width="20">
                                          </ng-template>
                                        
                                          
                                      </td>
                                           </tr>		
 
                                          </tbody>
                                          </table>
                                          </div>
                                        </div>
                                      </div>
                                      </div>-->
                                           </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer>
  
</app-footer>