<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2><i routerLink="/control_interno" style="cursor:pointer" class="bx bx-arrow-back"> </i> Control Interno</h2>
        </div>
    </div>
</section>

<section class="faq-area ptb-100 bg-f9f9f9">


    <div class="container">
        <div class="row">
            <div class="col-4">
                <ul class="list-group">
                    <a style="cursor:pointer" (click)="getSeccion(1)">
                        <li class="list-group-item">
                            <h6>Que es?</h6>
                        </li>
                    </a>
                    <a style="cursor:pointer" (click)="getSeccion(2)">
                        <li class="list-group-item">
                            <h6>Normativa</h6>
                        </li>
                    </a>
                    <a style="cursor:pointer" (click)="getSeccion(3)">
                        <li class="list-group-item">
                            <h6>Cumplimiento a la fecha</h6>
                        </li>
                    </a>
                </ul>
            </div>
            <div class="col-8">
                <div *ngIf="seccion == 1">
                    <div class="card">
                        <div class="card-body">
                            <div class="text-center">
                                <h4><b>SISTEMA DE CONTROL INTERNO (SCI)</b></h4>
                            </div>

                            <div class="row mt-5">
                                <div class="col-7">
                                    <h5><b>¿Qué es sistema de control interno (SCI)?</b></h5>
                                    <br>
                                    <p style="text-align: justify;">Es el conjunto de acciones, actividades, planes, políticas, normas, registros, organización, procedimientos y métodos, incluyendo las actitudes de las autoridades y el personal, organizadas e instituidas en cada entidad
                                        del Estado, que contribuyen al cumplimiento de los objetivos institucionales y promueven una gestión eficaz, eficiente, ética y transparente. </p>
                                </div>
                                <div class="col-5">
                                    <img src="assets/img/control_interno/quees2.png">
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12">
                                    <h5><b>Objetivos del control interno</b></h5>
                                    <br>
                                </div>
                                <div class="col-2 text-center">
                                    <img width="60" src="assets/img/control_interno/icon2.jpg">
                                </div>
                                <div class="col-10">
                                    Cuidar y resguardar los recursos y bienes del Estado, contra todo hecho irregular o situación perjudicial que pudiera afectarlos.
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-2 text-center">
                                    <img width="60" src="assets/img/control_interno/icon3.jpg">
                                </div>
                                <div class="col-10">
                                    Garantizar la confiabilidad y oportunidad de la información.
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-2 text-center">
                                    <img width="60" src="assets/img/control_interno/icon5.jpg">
                                </div>
                                <div class="col-10">
                                    Promover que los funcionarios y servidores del Estado cumplan con rendir cuentas por los fondos y bienes públicos que administra.
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-2 text-center">
                                    <img width="60" src="assets/img/control_interno/icon1.jpg">
                                </div>
                                <div class="col-10">
                                    Promover y optimizar, la eficiencia, eficacia, ética, transparencia y economía de las operaciones de la entidad, y la calidad de los servicios públicos que presta.
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-2 text-center">
                                    <img width="60" src="assets/img/control_interno/icon4.jpg">
                                </div>
                                <div class="col-10">
                                    Cumplir la normatividad aplicable a la entidad y sus operaciones.
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12 text-center">
                                    <h5><b> Actividades a realizar para la implementación del Sistema de Control Interno en la Entidad</b></h5>

                                </div>
                                <div class="col-12 ">
                                    <p style="text-align: justify;"> Con la nueva Directiva N° 006-2019-CG/INTEG vigente a partir del lunes 20 de mayo de 2019, se establece un nuevo procedimiento para la Implementación del Sistema de Control Interno, con pasos a seguir para cada uno
                                        de los tres (3) ejes (nuevos), los mismos que agrupan a los cinco (5) componentes conocidos, que a su vez incluyen a los diecisiete (17) principios tomados del COSO 2013. En el siguiente gráfico se muestra el esquema
                                        del nuevo procedimiento: </p>
                                </div>
                                <div class="col-12 text-center">
                                    <img src="assets/img/control_interno/quees3.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="seccion == 2">
                    <div class="card">
                        <div class="card-body">
                            <div class="text-center">
                                <h4><b>NORMATIVA</b></h4>
                            </div>
                            <div class="row mt-5">
                                <div class="col-7">
                                    <h5><b>¿Qué es sistema de control interno (SCI)?</b></h5>
                                    <br>
                                    <p style="text-align: justify;"><i class="fa fa-check text-danger" aria-hidden="true"></i> Ley Nº 30879 - Ley de Presupuesto del Sector Público 2019 (06.diciembre.2018), en la 122° Disposición Complementaria Final, establece la obligación de implementar
                                        el SCI en un plazo de 18 meses, bajo responsabilidad funcional. </p>
                                    <p style="text-align: justify;"><i class="fa fa-check text-danger" aria-hidden="true"></i> Resolución de Contraloría N° 146-2019-CG (15.mayo.2019), aprueba Directiva N° 006-2019-CG/INTEG, denominada “Implementación del SCI en las entidades del estado”.
                                    </p>
                                    <p style="text-align: justify;"><i class="fa fa-check text-danger" aria-hidden="true"></i> Resolución de Contraloría N° 130-2020-CG (06.mayo.2020), modifican Quinta Disposición Complementaria Transitoria de la Directiva N° 006-2019-CG/INTEG. </p>
                                </div>
                                <div class="col-5">
                                    <img src="assets/img/control_interno/libros.jpg">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="seccion == 3">
                    <div class="card">
                        <div class="card-body">
                            <h5><b>Cumplimientos a la fecha</b></h5>
                            <br>
                            <table class="table" border=1 cellspacing=0 cellpadding=0 style='margin-left:5.4pt;border-collapse:collapse;border:none'>
                                <tr>
                                    <td style='border:solid windowtext 1.0pt;background:#D5DCE4;padding:0cm 5.4pt 0cm 5.4pt'>
                                        <p class=MsoNormal align=center style='text-align:center;vertical-align:baseline'><b><span
                                    lang=ES-PE style='font-size:10.5pt;line-height:115%;font-family:"Arial",sans-serif'>EJES</span></b></p>
                                    </td>
                                    <td style='border:solid windowtext 1.0pt;border-left:none;background:#D5DCE4;
                                    padding:0cm 5.4pt 0cm 5.4pt'>
                                        <p class=MsoNormal align=center style='text-align:center;vertical-align:baseline'><b><span
                                    lang=ES-PE style='font-size:10.5pt;line-height:115%;font-family:"Arial",sans-serif'>ENTREGABLES</span></b></p>
                                    </td>
                                    <td style='border:solid windowtext 1.0pt;border-left:none;background:#D5DCE4;
                                    padding:0cm 5.4pt 0cm 5.4pt'>
                                        <p class=MsoNormal align=center style='text-align:center;vertical-align:baseline'><b><span
                                    lang=ES-PE style='font-size:10.5pt;line-height:115%;font-family:"Arial",sans-serif'>PLAZOS</span></b></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan=2 style='border:solid windowtext 1.0pt;border-top:none;
                                    padding:0cm 5.4pt 0cm 5.4pt'>
                                        <p class=MsoNormal style='vertical-align:baseline'><span lang=ES-PE style='font-size:10.5pt;line-height:115%;font-family:"Arial",sans-serif;
                                    color:#404040'>Eje Cultura Organizacional</span></p>
                                    </td>
                                    <td style='border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;
                                    border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
                                        <p class=MsoNormal style='vertical-align:baseline'><span lang=ES style='font-size:10.5pt;line-height:115%;font-family:"Arial",sans-serif;
                                    color:#404040'>Diagnóstico del estado situacional de la Cultura
                                    Organizacional.</span></p>
                                    </td>
                                    <td style='border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;
                                    border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
                                        <p class=MsoNormal style='vertical-align:baseline'><span lang=ES-PE style='font-size:10.5pt;line-height:115%;font-family:"Arial",sans-serif;
                                    color:#404040'>Hasta el último día hábil del mes de <b>setiembre </b>de 2019.</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style='border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;
                                    border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
                                        <p class=MsoNormal style='vertical-align:baseline'><span lang=ES style='font-size:10.5pt;line-height:115%;font-family:"Arial",sans-serif;
                                    color:#404040'>Plan de Acción Anual - Sección Medidas de Remediación.</span></p>
                                    </td>
                                    <td style='border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;
                                    border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
                                        <p class=MsoNormal style='vertical-align:baseline'><span lang=ES-PE style='font-size:10.5pt;line-height:115%;font-family:"Arial",sans-serif;
                                    color:#404040'>Hasta el último día hábil del mes de <b>octubre</b> de 2019.</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan=2 style='border:solid windowtext 1.0pt;border-top:none;
                                    padding:0cm 5.4pt 0cm 5.4pt'>
                                        <p class=MsoNormal style='vertical-align:baseline'><span lang=ES-PE style='font-size:10.5pt;line-height:115%;font-family:"Arial",sans-serif;
                                    color:#404040'>Eje Gestión de Riesgos</span></p>
                                    </td>
                                    <td style='border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;
                                    border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
                                        <p class=MsoNormal style='vertical-align:baseline'><span lang=ES style='font-size:10.5pt;line-height:115%;font-family:"Arial",sans-serif;
                                    color:#404040'>Plan de Acción Anual – Sección Medidas de Control.</span></p>
                                    </td>
                                    <td style='border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;
                                    border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
                                        <p class=MsoNormal style='vertical-align:baseline'><span lang=ES-PE style='font-size:10.5pt;line-height:115%;font-family:"Arial",sans-serif;
                                    color:#404040'>Hasta el último día hábil del mes <b>noviembre</b> de 2019.</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style='border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;
                                    border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
                                        <p class=MsoNormal style='vertical-align:baseline'><span lang=ES style='font-size:10.5pt;line-height:115%;font-family:"Arial",sans-serif;
                                    color:#404040'>Plan de Acción Anual – Sección Medidas de Control. (2°
                                    Producto)</span></p>
                                    </td>
                                    <td style='border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;
                                    border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
                                        <p class=MsoNormal style='vertical-align:baseline'><span lang=ES-PE style='font-size:10.5pt;line-height:115%;font-family:"Arial",sans-serif;
                                    color:#404040'>Hasta el último día hábil del mes de <b>Junio de 2020.</b></span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style='border:solid windowtext 1.0pt;border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
                                        <p class=MsoNormal style='vertical-align:baseline'><span lang=ES-PE style='font-size:10.5pt;line-height:115%;font-family:"Arial",sans-serif;
                                    color:#404040'>Eje Supervisión</span></p>
                                    </td>
                                    <td style='border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;
                                    border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
                                        <p class=MsoNormal style='vertical-align:baseline'><span lang=ES-PE style='font-size:10.5pt;line-height:115%;font-family:"Arial",sans-serif;
                                    color:#404040'>Reporte de Seguimiento de la Ejecución del Plan de Acción
                                    Anual.</span></p>
                                    </td>
                                    <td style='border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;
                                    border-right:solid windowtext 1.0pt;padding:0cm 5.4pt 0cm 5.4pt'>
                                        <p class=MsoNormal style='vertical-align:baseline'><span lang=ES-PE style='font-size:10.5pt;line-height:115%;font-family:"Arial",sans-serif;
                                    color:#404040'>Hasta el último día hábil del mes de <b>octubre de 2020</b>,
                                    con información obtenida con fecha corte al 30 de setiembre de 2020.</span></p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>