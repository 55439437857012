<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2><i routerLink="/" style="cursor:pointer" class="bx bx-arrow-back"> </i>  Galería Fotográfica</h2>
        </div>
    </div>
</section>


<div class="galeria">
  <div id="preloader-galeria-imagenes" class="preloader-iframe" *ngIf="isLoading"></div>

  <div class="contenedor-imagenes" *ngIf="!isLoading">
    <div class="imagen" *ngFor="let item of galerias">
      <img  src="{{path + item.src_imagen_referencial}}" alt="{{item.titulo}}">
        <div class="overlay" (click) ="getData(item);">
          <h3>{{item.titulo}}</h3>
          <button class="btn btn-sm btn-overlay"> Ver Galería</button>
        </div> 
    </div>    
  </div>
</div>

<app-footer></app-footer>