import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BeliService } from 'src/app/services/beli.service';

@Component({
  selector: 'app-normatividad',
  templateUrl: './normatividad.component.html',
  styleUrls: ['./normatividad.component.scss']
})
export class NormatividadComponent implements OnInit {
  
  isLoading: boolean = false;
  normatividad :any;
  path = this.beli.path();
  
  constructor(
    private beli: BeliService,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData () {
    this.isLoading  = true;
    this.beli.get('page/normatividad').then( data => { 
      this.normatividad = data.success ? data.info : [];
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }

}
