import { Component, OnInit } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormsModule, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BeliService } from 'src/app/services/beli.service';

@Component({
  selector: 'app-form-atencion-cliente',
  templateUrl: './form-atencion-cliente.component.html',
  styleUrls: ['./form-atencion-cliente.component.scss']
})
export class FormAtencionClienteComponent implements OnInit {
  
  formModel;
  listProblems = [];

  isSending = false;
  default: string = '';
  response =  {
    success: false,
    message: ''
  }

  nombres = '';
  paterno = '';
  materno = '';
  full_name = '';
  dni ;
  constructor(private formBuilder : FormBuilder, private beli: BeliService,) { 

  }
  
  ngOnInit(): void {
    this.getListProblems();
    this.formModel = this.formBuilder.group({
      codigo_solicitud: [''],
      numero_suministro: ['',Validators.required],
      modalidad_atencion: ['',Validators.required],
      fecha: [this.formatDate()],
      hora:[this.formatTime()],
      apellido_paterno: ['',Validators.required],
      apellido_materno: ['',Validators.required],
      nombres:['',Validators.required],
      documento_identidad : ['',Validators.required],
      razon_social: [''],
      ubicacion: [''],
      ubicacion_numero: [''],
      ubicacion_manzana: [''],
      ubicacion_lote: [''],
      urbanizacion : [''],
      distrito :[''],
      provincia:[''],
      telefono: [''],
      email: [''],
      tipo_problema: [''],
      descripcion_problema: ['']
    });
    this.formModel.controls['tipo_problema'].setValue(this.default, {onlySelf: true});
    this.formModel.controls['nombres'].valueChanges.subscribe(change => {
        this.nombres = change;
        this.full_name = this.nombres + ' ' + this.paterno +' ' + this.materno;
    });

    this.formModel.controls['apellido_paterno'].valueChanges.subscribe(change => {
          this.paterno = change;
          this.full_name = this.nombres + ' ' + this.paterno +' ' + this.materno;
    });

    this.formModel.controls['apellido_materno'].valueChanges.subscribe(change => {
      this.materno = change;
        this.full_name =this.nombres + ' ' + this.paterno +' ' + this.materno;
    });

    this.formModel.controls['documento_identidad'].valueChanges.subscribe(change => {
        this.dni =change;
    });
  }

  submit() {
    this.sendForm(this.formModel.value);
    setTimeout(() =>{ 
      this.response.success = false;
    },10000);
  }

    sendForm (data) {
      this.isSending = true;
      this.beli.post('page/form_atencion',data).then( data => { 
        this.response = data.success ? data : [];
        this.isSending = false;
        this.formModel.reset();
      });
  }

  getListProblems () {
    this.beli.get('page/problems').then( data => { 
      this.listProblems = data.success ? data.info : [];
      this.isSending = false;
    });
  };

   formatDate() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  formatTime (){
    var d = new Date();
    return d.toLocaleTimeString();
  }

 
}
