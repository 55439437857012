import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppRoutes } from './app.routes';

import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { InicioComponent } from './components/pagina/inicio/inicio.component';
import { QuienesSomosComponent } from './components/pagina/nosotros/quienes-somos/quienes-somos.component';
import { DirectorioComponent } from './components/pagina/nosotros/directorio/directorio.component';
import { AccionistasComponent } from './components/pagina/nosotros/accionistas/accionistas.component';
import { GerenteLineaComponent } from './components/pagina/nosotros/gerente-linea/gerente-linea.component';
import { ValoresMaximosComponent } from './components/pagina/servicio-cliente/valores-maximos/valores-maximos.component';
import { EstructuraTarifariaComponent } from './components/pagina/servicio-cliente/estructura-tarifaria/estructura-tarifaria.component';
import { ServiciosColateralesComponent } from './components/pagina/servicio-cliente/servicios-colaterales/servicios-colaterales.component';
import { NotasComponent } from './components/pagina/prensa/notas/notas.component';
import { GFotograficaComponent } from './components/pagina/prensa/g-fotografica/g-fotografica.component';
import { GAudiovisualComponent } from './components/pagina/prensa/g-audiovisual/g-audiovisual.component';
import { ConsultaDeudaComponent } from './components/pagina/pago/consulta-deuda/consulta-deuda.component';
import { CentroAtencionComponent } from './components/pagina/pago/centro-atencion/centro-atencion.component';
import { DocumentosGestionComponent } from './components/pagina/documentos-gestion/documentos-gestion.component';
import { TransparenciaComponent } from './components/pagina/transparencia/transparencia.component';
import { ResolucionesGerencialesComponent } from './components/pagina/documentos-gestion/resoluciones-gerenciales/resoluciones-gerenciales.component';
import { ResolucionesGafComponent } from './components/pagina/documentos-gestion/resoluciones-gaf/resoluciones-gaf.component';
import { ConveniosComponent } from './components/pagina/documentos-gestion/convenios/convenios.component';
import { DirectivasComponent } from './components/pagina/documentos-gestion/directivas/directivas.component';
import { NormatividadComponent } from './components/pagina/documentos-gestion/normatividad/normatividad.component';
import { OrganigramaComponent } from './components/pagina/nosotros/organigrama/organigrama.component';
import { IndicadoresGestionComponent } from './components/pagina/nosotros/indicadores-gestion/indicadores-gestion.component';
import { ControlInternoComponent } from './components/pagina/nosotros/control-interno/control-interno.component';
import { ConvocatoriasComponent } from './components/pagina/nosotros/convocatorias/convocatorias.component';
import { SafePipe } from './security-resource.pipe';
import { NoticiaComponent } from './components/pagina/prensa/notas/noticia/noticia.component';

import { FormAtencionClienteComponent } from './components/pagina/inicio/form-atencion-cliente/form-atencion-cliente.component';

import { FormReclamacionesComponent } from './components/pagina/inicio/form-reclamaciones/form-reclamaciones.component';

import { GerencialesItemComponent } from './components/pagina/documentos-gestion/resoluciones-gerenciales/gerenciales-item/gerenciales-item.component';
import { GafItemComponent } from './components/pagina/documentos-gestion/resoluciones-gaf/gaf-item/gaf-item.component';
import { ConveniosItemComponent } from './components/pagina/documentos-gestion/convenios/convenios-item/convenios-item.component';
import { DirectivasItemComponent } from './components/pagina/documentos-gestion/directivas/directivas-item/directivas-item.component';
import { NormatividadItemComponent } from './components/pagina/documentos-gestion/normatividad/normatividad-item/normatividad-item.component';
import { AtencionClienteComponent } from './components/pagina/inicio/atencion-cliente/atencion-cliente.component';
import { FormComercialComponent } from './components/pagina/inicio/form-comercial/form-comercial.component';
//import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { ControlEticoPdfComponent } from './components/pagina/nosotros/reportes/control-etico-pdf/control-etico-pdf.component';
import { ControlInternoSeccionComponent } from './components/pagina/nosotros/control-interno/control-interno-seccion/control-interno-seccion.component';
// Nuevo-----------------------------------------------------------------------------------------
import { SolicitudReclamacionesComponent } from './components/pagina/inicio/solicitud-reclamaciones/solicitud-reclamaciones.component';
import { BeliService } from './services/beli.service';
import { CommonModule } from '@angular/common';
import { ReciboDigitalComponent } from './components/pagina/inicio/recibo-digital/recibo-digital.component';
import { SolicitudQuejasComponent } from './components/pagina/inicio/solicitud-quejas/solicitud-quejas.component';
import { ComunicadosComponent } from './components/pagina/prensa/comunicados/comunicados.component';
import { ComunicadoComponent } from './components/pagina/prensa/comunicados/comunicado/comunicado.component';
import { ReportesComponent } from './components/pagina/reportes/reportes.component';
import { EncuestaComponent } from './encuesta/encuesta.component';
import { GraciasComponent } from './encuesta/gracias/gracias.component';
import { IncidenciasComponent } from './components/pagina/incidencias/incidencias.component';
import { DecretoComponent } from './components/pagina/nosotros/convocatorias/decreto/decreto.component';
import { PracticasComponent } from './components/pagina/nosotros/convocatorias/practicas/practicas.component';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    FooterComponent,
    HeaderComponent,
    ErrorComponent,
    InicioComponent,
    QuienesSomosComponent,
    DirectorioComponent,
    AccionistasComponent,
    GerenteLineaComponent,
    ValoresMaximosComponent,
    EstructuraTarifariaComponent,
    ServiciosColateralesComponent,
    NotasComponent,
    GFotograficaComponent,
    GAudiovisualComponent,
    ConsultaDeudaComponent,
    CentroAtencionComponent,
    DocumentosGestionComponent,
    TransparenciaComponent,
    ResolucionesGerencialesComponent,
    ResolucionesGafComponent,
    ConveniosComponent,
    DirectivasComponent,
    NormatividadComponent,
    OrganigramaComponent,
    IndicadoresGestionComponent,
    ControlInternoComponent,
    ConvocatoriasComponent,
    SafePipe,
    NoticiaComponent,
    FormAtencionClienteComponent,
    FormReclamacionesComponent,
    GerencialesItemComponent,
    GafItemComponent,
    ConveniosItemComponent,
    DirectivasItemComponent,
    NormatividadItemComponent,
    AtencionClienteComponent,
    FormComercialComponent,
  
    ControlEticoPdfComponent,
    ControlInternoSeccionComponent,
    // 
    SolicitudReclamacionesComponent,
    ReciboDigitalComponent,
    SolicitudQuejasComponent,
    ComunicadosComponent,
    ComunicadoComponent,
    ReportesComponent,
    EncuestaComponent,
    GraciasComponent,
    IncidenciasComponent,
    DecretoComponent,
    PracticasComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutes,
    HttpClientModule,
    ReactiveFormsModule,
    PdfViewerModule,
    CommonModule
  ],
  providers: [BeliService],
  bootstrap: [AppComponent]
})
export class AppModule { }
