import { Component, OnInit } from '@angular/core';
import { BeliService } from 'src/app/services/beli.service';

declare var $: any;

@Component({
  selector: 'app-g-fotografica',
  templateUrl: './g-fotografica.component.html',
  styleUrls: ['./g-fotografica.component.scss']
})

export class GFotograficaComponent implements OnInit {

  galerias :any; 
  isLoading = false;
  path = this.beli.path();

  constructor(
    private beli :BeliService
  ) { }
  
  ngOnInit(): void {
    this.getGalerias();
    
  }


  getGalerias() {
    this.isLoading  = true;
    this.beli.get('page/galerias').then( data => { 
      this.galerias = data.success ? data.info : [];
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }


  setObject (object) {
    let aux = [];
    object.forEach(element => {
      aux.push({
          src:this.path + element.src_imagen,
          thumb:this.path + element.src_imagen
      });
    });
    return aux;
  }

  lightbox :any 
  
  getData(item) {
    let tmp = this.setObject(item.items);
    if ( $(this).data('lightGallery') )
      $(this).data('lightGallery').destroy(true)
      
    setTimeout(() => {
        $(this).lightGallery({
          dynamic: true,
          dynamicEl: tmp
        });
    }, 300);
    
  }
  
   
}
