import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormBuilder } from '@angular/forms';
import { BeliService } from 'src/app/services/beli.service';

@Component({
  selector: 'app-form-comercial',
  templateUrl: './form-comercial.component.html',
  styleUrls: ['./form-comercial.component.scss']
})
export class FormComercialComponent implements OnInit {

  formModel;
  listProblems = [];

  isSending = false;
  default: string = '';
  response =  {
    success: false,
    message: ''
  }

  constructor(private formBuilder : FormBuilder, private beli: BeliService,) { 
    this.formModel = this.formBuilder.group({
      codigo_solicitud: [''],
      numero_suministro: ['',Validators.required],
      apellido_paterno: ['',Validators.required],
      apellido_materno: ['',Validators.required],
      nombres:['',Validators.required],
      documento_identidad : ['',Validators.required],
      razon_social: [''],
      ubicacion: [''],
      ubicacion_numero: [''],
      ubicacion_manzana: [''],
      ubicacion_lote: [''],
      urbanizacion : [''],
      distrito :[''],
      provincia:[''],
      telefono: [''],
      email: [''],
      tipo_problema: [''],
      domicilio_procesal :[''],
      domicilio_procesal_manzana : [''],
      domicilio_procesal_numero: [''],
      domicilio_procesal_lote : [''],
      domicilio_procesal_urbanizacion : [''],
      domicilio_procesal_provincia : [''],
      domicilio_procesal_distrito :[''],
      codigo_postal : [''],
      telefono_celular : [''],
      descripcion: [''],
      descripcion_detalle:[''],
      cartilla_informativa : [''],
      declaracion_reclamante : [''],
      atendido_por : [''],
      sucursal_zonal : [''],
      relacion_pruebas_1 : [''],
      relacion_pruebas_2 : [''],
      relacion_pruebas_3 : [''],
    });
  }
  
  ngOnInit(): void {
    
   
    this.getListProblems();
  }

  submit() {
    this.sendForm(this.formModel.value);
    setTimeout(() =>{ 
      this.response.success = false;
    },10000);
  }

    sendForm (data) {
      this.isSending = true;
      this.beli.post('page/form_comercial',data).then( data => { 
        this.response = data.success ? data : [];
        this.isSending = false;
        this.formModel.reset();
      });
  }

  getListProblems () {
    this.beli.get('page/problems').then( data => { 
      this.listProblems = data.success ? data.info : [];
      this.isSending = false;
    });
  };

   formatDate() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  formatTime (){
    var d = new Date();
    return d.toLocaleTimeString();
  }


}
