import { Component, OnInit } from '@angular/core';
import { BeliService } from 'src/app/services/beli.service';
import * as moment from 'moment';

@Component({
  selector: 'app-comunicados',
  templateUrl: './comunicados.component.html',
  styleUrls: ['./comunicados.component.scss']
})
export class ComunicadosComponent implements OnInit {

  isLoading: boolean = false;
  comunicados :any;
  path = this.beli.path();

  constructor(
    private beli: BeliService,
  ) { }

  ngOnInit(): void {
    moment.locale('es');
    this.getData();
  }

  getData () {
    this.isLoading  = true;
    this.beli.get('page/comunicados').then( data => { 
      this.comunicados = data.success ? data.info : [];
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }

  setFecha(fecha) {
    return  moment(fecha).format('LL');
  }

  goToComunicado (item) {
    localStorage.setItem('comunicado',JSON.stringify(item));
  }

}
