import { Component, OnInit } from '@angular/core';
import { environment } from "src/environments/environment";
import { BeliService } from 'src/app/services/beli.service';

declare let $ : any;

@Component({
  selector: 'app-servicios-colaterales',
  templateUrl: './servicios-colaterales.component.html',
  styleUrls: ['./servicios-colaterales.component.scss']
})
export class ServiciosColateralesComponent implements OnInit {

  url: string;
  isLoading: boolean = false;
  servicios_colaterales :{
    src_documento : string
  };
  
  path = this.beli.path();

  constructor(
    private beli: BeliService,
  ) { }

  ngOnInit(): void {
    this.getData();
  }
 
  getData () {
    this.isLoading  = true;
    this.beli.get('page/servicios_colaterales').then( data => { 
      this.servicios_colaterales = data.success ? data.info : [];
      this.url = this.path + this.servicios_colaterales.src_documento;
      console.log("url",this.url)
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }

  displayTest(){
    setTimeout( () => { $("#preloader-servicios")[0].style.display = "none"; }, 2500);
    setTimeout( () => { $("#iframe-servicios")[0].style.display = "block"; },2500);
  }

}
