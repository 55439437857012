import { Injectable } from '@angular/core';
import {TipoReclamo,SubtipoReclamo} from '../models/tipoReclamo.interface';
@Injectable()

export class TipoReclamosDataService {
  private tipoReclamo:TipoReclamo[]=[
    {
      id:1,
      name:'1A.- Problemas en el régimen de facturación y el nivel de consumo'
    },
    {
      id:2,
      name:'1B.- Problemas en la tarifa aplicada al usuario'
    },
    {
      id:3,
      name:'1C.- Problemas en otros conceptos'
    },
    // 
    {
      id:4,
      name:'2A.- Problemas relativos al acceso al servicio'
    },
    {
      id:5,
      name:'2B.- Problemas relativos a la micromedición'
    },
    {
      id:6,
      name:'2C.- Problemas relativos a cortes indebidos'
    },
    {
      id:7,
      name:'2D.- Falta de entrega de recibo'
    },
    {
      id:8,
      name:'2E.- Problemas relativos a la información'
    },
    // 
    {
      id:9,
      name:'3A.- Filtraciones'
    },
    {
      id:10,
      name:'3B.- Problemas en el servicio de agua potable'
    },
    {
      id:11,
      name:'3C.- Problemas en el servicio de alcantarillado'
    },
  ];
  private subtipoReclamo:SubtipoReclamo[]=[
    // TIPO 1A
    {
      id:1,
      tipoReclamoId:1,
      name:'1A1.- Consumo medido'
    },
    {
      id:2,
      tipoReclamoId:1,
      name:'1A2.- Consumo Promedio'
    },
    {
      id:3,
      tipoReclamoId:1,
      name:'1A3.- Asignación de Consumo: el usuario'
    },
    {
      id:4,
      tipoReclamoId:1,
      name:'1A4.- Consumo no facturado'
    },
    {
      id:5,
      tipoReclamoId:1,
      name:'1A5.- Consumo no realizado por servicio cerrado'
    },
    {
      id:6,
      tipoReclamoId:1,
      name:'1A6.- Consumo atribuible a usuario anterior del suministro'
    },
    {
      id:7,
      tipoReclamoId:1,
      name:'1A7.- Consumo atribuible a otro suministro'
    },
    {
      id:8,
      tipoReclamoId:1,
      name:'1A8.- Refacturación'
    },
    // TIPO 1B
    {
      id:9,
      tipoReclamoId:2,
      name:'1B1.- Tipo de tarifa'
    },
    // TIPO 1C
    {
      id:10,
      tipoReclamoId:3,
      name:'1C1.- Conceptos emitidos'
    },
    {
      id:11,
      tipoReclamoId:3,
      name:'1C2.- Numeros de unidades de uso'
    },
    // ==========================================================================================
    // TIPO 2A
    {
      id:12,
      tipoReclamoId:4,
      name:'2A1.- La instalación de conexión domiciliaria ...'
    },
    {
      id:13,
      tipoReclamoId:4,
      name:'2A2.- Desacuerdo con informe negativo ...'
    },
    {
      id:14,
      tipoReclamoId:4,
      name:'2A3.- No se admite a trámite la solicitud ...'
    },
    {
      id:15,
      tipoReclamoId:4,
      name:'2A4.- El servicio prestado no responde a las	...'
    },
    {
      id:16,
      tipoReclamoId:4,
      name:'2A5.- No se suscribe contrato de prestación deservicios'
    },
    {
      id:17,
      tipoReclamoId:4,
      name:'2A6.- Otros problemas relativos al contrato.'
    },
    {
      id:18,
      tipoReclamoId:4,
      name:'2A7.- EPS no emite informe de factibilidad dentrodel plazo'
    },
    // TIPO 2B
    {
      id:19,
      tipoReclamoId:5,
      name:'2B1.- El reclamante adquiere un medidor ...'
    },
    {
      id:20,
      tipoReclamoId:5,
      name:'2B2.- La reinstalación del medidor no se ha realizado ...'
    },
    {
      id:21,
      tipoReclamoId:5,
      name:'2B3.- El medidor ha sido retirado sin previa ...'
    },
    {
      id:22,
      tipoReclamoId:5,
      name:'2B4.- El medidor ha sido retirado por razones ...'
    },
    {
      id:23,
      tipoReclamoId:5,
      name:'2B5.- EPS instala medidor sin aferición inicial ...'
    },
    // TIPO 2C
    {
      id:24,
      tipoReclamoId:6,
      name:'2C1.- El corte o la suspensión del servicio ...'
    },
    {
      id:25,
      tipoReclamoId:6,
      name:'2C2.- La rehabilitación de un servicio cerrado ...'
    },
    // TIPO 2D
    {
      id:26,
      tipoReclamoId:7,
      name:'2D1.- Falta de entrega de recibo '
    },
    // TIPO 2E
    {
      id:27,
      tipoReclamoId:8,
      name:'2E1.- "No entregar al usuario la información ...'
    },
    // ==========================================================================================
    // TIPO 3A
    {
      id:28,
      tipoReclamoId:9,
      name:'3A1.- Filtración de agua externas hacia el predio	'
    },
    // TIPO 3B
    {
      id:29,
      tipoReclamoId:10,
      name:'3B1.- Fugas en conexión domiciliaria ...'
    },
    {
      id:30,
      tipoReclamoId:10,
      name:'3B2.- Negativa de la EPS a realizar mantenimiento ...'
    },
    {
      id:31,
      tipoReclamoId:10,
      name:'3B3.- Negativa de la EPS ...'
    },
    {
      id:32,
      tipoReclamoId:10,
      name:'3B4.- Negativa de la EPS a realizar ...'
    },
    // TIPO 3C
    {
      id:33,
      tipoReclamoId:11,
      name:'3C1.- Atoro en conexión de alcantarillado'
    },
    {
      id:34,
      tipoReclamoId:11,
      name:'3C2.- Negativa de la EPS a realizar mantenimiento ...'
    },
    {
      id:35,
      tipoReclamoId:11,
      name:'3C3.- Negativa de la EPS a realizar ampliación ...'
    },
    {
      id:36,
      tipoReclamoId:11,
      name:'3C4.- Negativa de la EPS a realizar la reubicación ...'
    },
  ];

  getTipoReclamos():TipoReclamo[]{
    return this.tipoReclamo;
  }
  getSubtipoReclamos():SubtipoReclamo[]{
    return this.subtipoReclamo;
  }
}
