import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-transparencia',
  templateUrl: './transparencia.component.html',
  styleUrls: ['./transparencia.component.scss']
})
export class TransparenciaComponent implements OnInit {

  urlViewFile: SafeResourceUrl;

  constructor(
    private _domSanador: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.urlViewFile = this._domSanador.bypassSecurityTrustResourceUrl("http://www.transparencia.gob.pe/enlaces/pte_transparencia_enlaces.aspx?id_entidad=11588&id_tema=1&ver=D#.XQvrC6_Py01");
  }

}
