import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { BeliService } from 'src/app/services/beli.service';

@Component({
  selector: 'app-form-reclamaciones',
  templateUrl: './form-reclamaciones.component.html',
  styleUrls: ['./form-reclamaciones.component.scss']
})
export class FormReclamacionesComponent implements OnInit {

  formModel;

  isSending = false;
  isDisable:boolean;
  isExists:boolean;
  datos:any;
  mensaje : string;
  deshabilitado: boolean = true;
  message : string;
  codigo:any;
  codigo_ficha : string;
  valor : string;
  Ocultar:boolean = false;
  propietario : String;
  sedes :any ;
  respuesta : [];
  response =  {
    success: false,
    message: ''
  }
  tipo_documento = [
    {id:1,nombre: 'DNI'},
    {id:2,nombre: 'RUC'},
    {id:3,nombre: 'Ce'},
  ];



  constructor(private formBuilder : FormBuilder, private beli: BeliService,) { 
    this.getSedes();

    
    this.formModel = this.formBuilder.group({
      hoja_reclamacion: [''],
      fecha: [this.formatDate()],
      cut_numero: ['', [
        Validators.required,
        Validators.compose([Validators.required,
        Validators.max(999999999999999)]),
        Validators.pattern(/^[0-9]+(\.?[7-7]+)?$/)
      ]],
      tipo_persona_id: ['',Validators.required],
      documento_identidad_id:['', Validators.required],
      fecha_nacimiento:['',Validators.required],
      hora:[this.formatTime()],
      documento_identidad : ['',[
        Validators.compose([Validators.required,
          Validators.max(999999999999999)]),
        Validators.required,
        Validators.pattern(/^[0-9]+(\.?[7-7]+)?$/)
      ]
    ],
      nombres:['',Validators.required],
      apellido_paterno: ['',Validators.required],
      apellido_materno: ['',Validators.required],
      departamento : [''],
      provincia:[''],
      distrito :[''],
      ubicacion: ['', Validators.required],
      telefono:['',[
        Validators.required,
        Validators.compose([Validators.required,
          Validators.min(100000000),
          Validators.max(999999999)]),
        Validators.pattern(/^[0-9]+(\.?[7-7]+)?$/)
      ]],
      telefono_fijo: [''],
      sede_id:new FormControl(null),
      email: ['',[
        Validators.required,
        Validators.maxLength(80),
        Validators.pattern(
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        )
      ]
    ],
    descripcion_problema: ['',[
      Validators.required,
      Validators.maxLength(1000),
      Validators.pattern(/[A-Za-z0-9]{1,20}/)
    ]
  ],
    });
    

   

  }
  
  ngOnInit(): void {
    
  }

  get formValidate(){
    return this.formModel.controls;
  }

  

  submit() {
    this.sendForm(this.formModel.value);
    setTimeout(() =>{ 
      this.response.success = false;
    },10000);
  }

  autenticar():any {
    try {
      var formData: any = new FormData();
        formData.append("cut_numero", this.formModel.get('cut_numero').value);
        formData.append("tipo_persona_id", this.formModel.get('tipo_persona_id').value);
        formData.append("documento_identidad_id", this.formModel.get('documento_identidad_id').value);
        formData.append("fecha", this.formModel.get('fecha').value);
        formData.append("fecha_nacimiento", this.formModel.get('fecha_nacimiento').value);
        formData.append("apellido_paterno", this.formModel.get('apellido_paterno').value);
        formData.append("apellido_materno", this.formModel.get('apellido_materno').value);
        formData.append("nombres", this.formModel.get('nombres').value);
        formData.append("documento_identidad", this.formModel.get('documento_identidad').value);
        formData.append("ubicacion", this.formModel.get('ubicacion').value);
        formData.append("departamento", this.formModel.get('departamento').value);
        formData.append("distrito", this.formModel.get('distrito').value);
        formData.append("provincia", this.formModel.get('provincia').value);
        formData.append("telefono", this.formModel.get('telefono').value);
        formData.append("telefono_fijo", this.formModel.get('telefono_fijo').value);
        formData.append("email", this.formModel.get('email').value);
        formData.append("descripcion_problema", this.formModel.get('descripcion_problema').value);
      
      
      this.sendForm(formData);
        setTimeout(() =>{ 
          this.response.success = false;
        },10000);
      
    } catch (error) {

    }
    
  }

    sendForm (data) {
      this.isSending = true;
      this.beli.post('page/form_reclamos',data).then( data => { 
      this.response = data.success ? data : [];
      this.isSending = false;
      console.log(data);
      Swal.fire({
        icon: 'success',
        title: 'Se ha enviado la solicitud satisfactoriamente',
        timer: 4000,
        showConfirmButton: false,
      }).then(function(){
        location.href = '/'
      });
      this.formModel.reset();
    })
  }

   formatDate() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  getSedes(){
    this.beli.get('page/sedes').then( data => { 
    this.sedes = data.success ? data.info : [];
    });
  }

  formatTime (){
    var d = new Date();
    return d.toLocaleTimeString();
  }

  onChange(newVal) {
    this.formModel.get('sede_id').value = newVal;
  }

  mostrarReciboDeEjemplo(){
    Swal.fire({
      text: 'Recibo de ejemplo',
      imageUrl: 'assets/img/inicio/solicitud-reclamos/reciboEjemplo.png',
      imageHeight: 500,
      imageWidth: 800
    })
  }

  validarSuministro(){
    if(this.formModel.get('cut_numero').value == ''){
      this.capturar();
    }else if (this.formModel.get('cut_numero').value == null){
      this.capturar();
    }else{
      this.capturar();
      this.beli.get("page/consulta_usuario?idsucursal=1&textbusqueda="+this.formModel.get('cut_numero').value).then( data => {
        this.respuesta = data.success ? data.info : [];
        if(this.respuesta.length > 0){
          Swal.fire({
            title: '<small><b>¡SUMINISTRO CORRECTO!</b></small>',
            icon: 'success',
            html: '<p style=font-family: Georgia, Times New Roman, Times, serif;>A continuación llene correctamente el formulario del libro de observaciones.</p>',
              
            focusConfirm: false,
            confirmButtonText:
              '<i class="fa fa-thumbs-up"></i> Continuar',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            cancelButtonText:
              '<i class="fa fa-thumbs-down"></i>',
            cancelButtonAriaLabel: 'Thumbs down'
          })
          this.datos= Object.values(this.respuesta);
          this.propietario = (this.datos[0]['propietario']);
          this.isExists = true;
          this.isDisable = true;
          this.Ocultar = true;
        }else{
          this.capturar();
          Swal.fire({
             title: '<small><b>¡ADVERTENCIA!</b></small>',
            icon: 'warning',
            html:
                '<p>El número de suministro ingresado <b>NO EXISTE!&nbsp;&nbsp;</b> Por favor asegurese de haber escrito correctamente su número de suministro y vuelva a intentarlo.</p>' +
                '<small><b>Para mayor información consulte al botón de ayuda <i class="fa fa-question-circle"></i></b></small>',
            allowOutsideClick: false,
            confirmButtonText:
              '<i class="fa fa-arrow-left"></i> Volver',
          })
          this.formModel.get('cut_numero').reset();
        }
    })
    .catch();
    }
  }

  capturar(){
    if(this.formModel.get('cut_numero').value == null){
       this.mensaje = 'Ingrese su número de suministro correctamente. Consulte el botón de ayuda al lado derecho.';
    }else if(this.formModel.get('cut_numero').value == ''){
       this.mensaje = 'Ingrese su número de suministro por favor.';
    }else{
      this.mensaje = '';
    }
  }

  
}
