<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2> <i routerLink="/notas_prensa" style="cursor:pointer" class="bx bx-arrow-back"> </i> Noticia </h2>
        </div>
    </div>
</section>

<section class="hg_section pt-50 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-9 order-lg-1">
                <div  class="preloader-iframe"  [hidden]="setNoticiaflat"></div>
                <div id="th-content-post" [hidden]="!setNoticiaflat">
                    <h1 class="page-title black">
                        {{noticia?.titulo }}
                    </h1>
            
                    <div class="itemView eBlog">
                        <div class="itemHeader">
                            <div class="post_details">
                                <span *ngIf="noticia?.autor != ''" class="itemAuthor">
                                    por <a href="#"><strong>{{ noticia?.autor }}</strong></a>
                                </span>
                                
                                <span class="infSep"> / </span>
                                <span class="itemDateCreated">
                                    <span class="far fa-calendar-alt"></span> 
                                    {{setFecha(noticia?.fecha_publicacion) }}</span>
                            </div>
                        </div>

                        <div class="itemBody">
                            <a data-lightbox="image" class="hoverBorder float-left" style="margin-right: 20px; margin-bottom:4px;">
                                <span class="hoverBorderWrapper">
                                    <img  class="image-noticia" src="{{path + noticia?.src_imagen }}" loading="lazy" alt="{{ noticia?.titulo }}">
                                    <span class="theHoverBorder"></span>
                                </span>
                            </a>
                            <p [innerHTML]="noticia?.texto" >
                            
                            </p>
                        </div>

                    </div>
                </div>
                <div class="clear"></div>
            </div>	
            
            <div class="col-sm-12 col-md-12 col-lg-3">
                <div id="sidebar-widget" class="sidebar">
                    <div class="widget widget_recent_entries">
                        <div class="latest_posts style3">
                            <h3 class="widgettitle title">
                              <strong>  Últimas publicaciones </strong>
                            </h3>
                            <ul class="posts scrollbar" id="style-1">
                                <li class="lp-post" *ngFor="let item of noticias; let i = index" (click)="setNoticia(item)">
                                    <a class="hoverBorder float-left">
                                        <span class="hoverBorderWrapper">
                                            <img class="thumb-noticia" src="{{path + item?.src_imagen }}" alt="{{item?.titulo}}" title="{{item?.titulo}}">
                                            <span class="theHoverBorder"></span>
                                        </span>
                                    </a>
                                    <h4 class="title">
                                        <a   title="How to build a website with Kallyas">
                                           {{ item?.titulo }}
                                        </a>
                                    </h4>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<app-footer></app-footer>