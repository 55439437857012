<body>
    <div class="pageEncuesta">
         <div class="boxContainer">
              <div class="contentBanner relative">
                   <div class="boxLogoFlotante">
                        <a href="/">
                             <img src="https://image.info-enel.com/lib/fe9213727c61027d75/m/11/22370d7d-a8ef-443b-bbe6-a2d8d5f2533e.png" alt="Enel">
                        </a>
                   </div>
                   <div class="fullImages">
                        <img src="https://image.info-enel.com/lib/fe9213727c61027d75/m/11/3cba3187-4a73-4e1f-86c0-596b61068b44.jpg" alt="">
                   </div>
              </div>
              <section class="contentHome">
                   <div class="">
                        <div class="boxContent">
                             <form id="formEncuesta" method="POST" (submit)="submit()" [formGroup]="formModel" name="formEncuesta">
                                <input type="hidden" id="taskIdInput" name="task" value="00T0600005zaw2XEAQ">
        <input type="hidden" id="accion" name="accion" value="Enviar">
                                  <div class="boxTitle">
                                       <h1 class="txtCenter" style="color: #088A85;">
                                            TU OPINIÓN NOS AYUDA<br>
                                            A SEGUIR MEJORANDO
                                       </h1>
                                  </div>
                                  <div class="boxDescription separate">
                                       <div class="vineta v1">
                                            <p style="font-weight: bold;">
                                                 ¿Qué tan satisfecho estás con la entrega de tu recibo digital?
                                            </p>
                                            <small class="bold mt-2">
                                                 Utiliza la siguiente escala de 1 al 5, donde  1 es muy  insatisfecho y 5 muy satisfecho.
                                            </small>
                                       </div>
                                  </div>
                                  <div class="boxCirculoEncuesta mt-4 mb-4">
                                       <div class="d-flex justify-content-between align-items-strech">
                                            <a (click)="selectprimero()" data-puntaje="Muy Insatisfecho" data-id="1" id="circulo-1" class="btnSeleccionar d-flex justify-content-start align-items-center flex-column">
                                                 <div  id="valor1" class="boxCircle d-flex justify-content-center align-items-center flex-column">
                                                      <p>1</p>
                                                 </div>
                                                 <h3>
                                                      MUY <br>
                                                      INSATISFECHO
                                                 </h3>
                                            </a>
                                            <a (click)="selectsegundo()" data-puntaje="Insatisfecho" data-id="2" id="circulo-2" class="btnSeleccionar d-flex justify-content-start align-items-center flex-column">
                                                 <div id="valor2" class="boxCircle d-flex justify-content-center align-items-center flex-column">
                                                      <p>2</p>
                                                 </div>
                                            </a>
                                            <a (click)="selecttercero()" data-puntaje="Neutro" data-id="3" id="circulo-3" class="btnSeleccionar d-flex justify-content-start align-items-center flex-column">
                                                 <div id="valor3" class="boxCircle color2 d-flex justify-content-center align-items-center flex-column">
                                                      <p>3</p>
                                                 </div>
                                            </a>
                                            <a (click)="selectcuarto()" data-puntaje="Satisfecho" data-id="4" id="circulo-4" class="btnSeleccionar d-flex justify-content-start align-items-center flex-column">
                                                 <div id="valor4"  class="boxCircle color3 d-flex justify-content-center align-items-center flex-column">
                                                      <p>4</p>
                                                 </div>
                                            </a>
                                            <a (click)="selectquinto()" data-puntaje="Muy Satisfecho" data-id="5" id="circulo-5" class="btnSeleccionar d-flex justify-content-start align-items-center flex-column">
                                                 <div id="valor5"  class="boxCircle color3 d-flex justify-content-center align-items-center flex-column">
                                                      <p>5</p>
                                                 </div>
                                                 <h3>
                                                      MUY <br>
                                                      SATISFECHO
                                                 </h3>
                                            </a>
                                       </div>
                                    <p style="color:red;"></p>
                                  </div>
                               
                                  <div class="boxDescription mb-4">
                                       <div class="vineta v2">
                                            <p style="font-weight: bold;">
                                                 Favor indicar si en este mes, recibiste tu recibo digital en tu correo electrónico, es decir, si llegó o no tu recibo:
                                            </p>
                                       </div>
                                       <div class="form-check" style="padding-left: 2.25rem !important;">
                                        <input class="form-check-input" type="radio" name="confirmacion" value="Si recibio" formControlName="confirmacion">
                                        <label class="form-check-label" for="flexRadioDefault1">
                                          Sí
                                        </label>
                                      </div>
                                      <div class="form-check" style="padding-left: 2.25rem !important;">
                                        <input class="form-check-input" type="radio" name="confirmacion" value="No recibio" formControlName="confirmacion">
                                        <label class="form-check-label" for="flexRadioDefault2">
                                          No
                                        </label>
                                      </div>
                                  </div>

                                  <div class="boxDescription mb-4">
                                       <div class="vineta v3">
                                            <p style="font-weight: bold;">
                                                 ¿Qué podemos hacer en EMAPA HUARAL para mejorar tu expectativa o experiencia respecto al contenido del recibo digital?
                                            </p>
                                       </div>
                                       <div class="boxTextArea mt-3">
                                            <textarea name="motivos" id="motivos" cols="30" rows="10" formControlName="motivos" placeholder="Escribe aquí..."></textarea>
                                       </div>
                                  </div>
                                  
                                  <div class="d-flex justify-content-center align-items-center">
                                       <div class="boxButton large" style="text-align: center;">
                                        <button  type="submit" class="btn btn-info btn-lg botonEnv" style="width: 180px;" data-toggle="modal" data-target="#exampleModalCenter" data-placement="top" title="Completa todos los campos"
                                        [disabled]="formModel.invalid || invalid"> ENVIAR ENCUESTA</button>
                                       </div>
                                  </div>
                                  <input type="hidden" name="puntaje" id="puntaje" value="Muy Satisfecho">
                             </form>
                        </div>
                   </div>
              </section>
         </div>
    </div>


</body>