<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2><i routerLink="/" style="cursor:pointer" class="bx bx-arrow-back"> </i> Accionistas</h2>
        </div>
    </div>
</section>

<section class="doctors-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-md-12" *ngIf="isLoading">Presentación del Reclamo
                Cargando...
            </div> 
            <div class="col-md-12" *ngIf="!isLoading">
                <div class="row">
                    <div class="col-md-12" *ngIf="accionistas?.length == 0">
                        No hay Accionistas
                    </div>
                    <div class="col-lg-12 col-sm-12 col-md-12">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead class="thead-inverse">
                                    <tr>
                                        <th>Nombres y Apellidos</th>
                                        <th>Cargo</th>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of accionistas">
                                        <td>{{item.nombre}}</td>
                                        <td>{{item.cargo.nombre}}</td>
                                        <td><a href="mailto:{{item.email}}">{{item.email}}</a></td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>