import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { BeliService } from 'src/app/services/beli.service';

@Component({
  selector: 'app-gerenciales-item',
  templateUrl: './gerenciales-item.component.html',
  styleUrls: ['./gerenciales-item.component.scss']
})
export class GerencialesItemComponent implements OnInit {

  bankName:string;
  data : any;
  isLoading = false; 
  path = this.beli.path();

  constructor(private route:ActivatedRoute,private beli: BeliService,) { }

  ngOnInit(): void {
    this.bankName = this.route.snapshot.params['param'];
    this.getDocumentosPorAnio(this.bankName);
  
  }

  getDocumentosPorAnio(anio) {
    this.isLoading = true;
    this.beli.get('page/documentos/resoluciones_gerenciales/'+anio).then( data => { 
      this.data = data.success ? data.info : [];
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }

}
