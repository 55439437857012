<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2><i routerLink="/" style="cursor:pointer" class="bx bx-arrow-back"> </i> Notas de prensa</h2>
        </div>
    </div>
</section>

<section class="faq-area ptb-100 bg-f9f9f9">
    <div class="container">
        <div class="row">
            <div id="preloader-notas" class="preloader-iframe" *ngIf="isLoading"></div>
            <div class="col-sm-12 col-md-6 col-lg-4" *ngFor="let item of notas_prensa">

                <div class="box image-boxes imgboxes_style4 kl-title_style_left">
                    <a class="imgboxes4_link imgboxes-wrapper" routerLink="/notas_prensa/noticia" (click)="goToNoticia(item)">
                        <img loading="lazy" [src]=" path + item.src_imagen"  class="img-fluid imgbox_image cover-fit-img" alt="RESCUE SUPPORT" title="RESCUE SUPPORT">
                        <span class="imgboxes-border-helper"></span>
                        <h3 class="m_title imgboxes-title">
                           {{ item.titulo }}
                        </h3> 
                    </a>
                    <p>
                       {{item.titulo_informativo}} 
                    </p>
                    <div class="container notas-assets">
                        <div>
                            <em class="latest_posts-acc-details">
                                {{setFecha(item.fecha_publicacion)}} por {{ item.autor }}
                            </em>
                        </div>
                        <div>
                            <a routerLink="/notas_prensa/noticia" (click)="goToNoticia(item)" class="latest_posts-acc-more">
                                LEER MÁS +
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>