<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content"  *ngIf="comunicado?.id < 10">
            <h2> <i routerLink="/comunicados" style="cursor:pointer" class="bx bx-arrow-back"> </i> Comunicado N°0{{ comunicado?.id }} - 2022</h2>
        </div>
        <div class="page-title-content"  *ngIf="comunicado?.id >= 10">
            <h2> <i routerLink="/comunicados" style="cursor:pointer" class="bx bx-arrow-back"> </i> Comunicado N°{{ comunicado?.id }} - 2022</h2>
        </div>
    </div>
</section>

<section class="hg_section pt-50 pb-100">
    <div class="container-xl">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-9 order-lg-1">
                <div  class="preloader-iframe"  [hidden]="setComunicadoflat"></div>
                <div id="th-content-post" [hidden]="!setComunicadoflat">
                    <h5 class="page-title black" style="text-align:center;">
                        {{comunicado?.titulo }}
                    </h5>
            
                    <div class="itemView eBlog">
                        <div class="itemBody">
                            <!--<a data-lightbox="image" class="hoverBorder float-left" style="margin-right: 20px; margin-bottom:4px;">
                                <span class="hoverBorderWrapper">
                                    <img  class="image-noticia" src="{{path + comunicado?.src_imagen }}" loading="lazy" alt="">
                                    <span class="theHoverBorder"></span>
                                </span>
                            </a>-->
                            <p [innerHTML]="comunicado?.texto" >
                            
                            </p>
                            <div class="itemHeader">
                                <div class="post_details">
                                    <span *ngIf="comunicado?.autor != ''" class="itemAuthor">
                                       publicado por <a href="#"><strong>{{ comunicado?.autor }}</strong></a>
                                    </span>
                                    
                                    <span class="infSep"> / </span>
                                    <span class="itemDateCreated">
                                        <span class="far fa-calendar-alt"></span> 
                                        {{setFecha(comunicado?.fecha_publicacion) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
	
            
            <div class="col-sm-12 col-md-12 col-lg-3">
                <div id="sidebar-widget" class="sidebar">
                    <div class="widget widget_recent_entries">
                        <div class="latest_posts style3">
                            <h3 class="widgettitle title">
                              <strong>  Últimos Comunicados </strong>
                            </h3>
                            <ul class="posts scrollbar" id="style-1">
                                <li class="lp-post" *ngFor="let item of comunicados; let i = index" (click)="setComunicado(item)">
                                    <a class="hoverBorder float-left">
                                        <span class="hoverBorderWrapper">
                                            <img class="thumb-noticia" src="{{path + item?.src_imagen }}" alt="{{item?.titulo}}" title="{{item?.titulo}}">
                                            <span class="theHoverBorder"></span>
                                        </span>
                                    </a>
                                    <h5 class="title">
                                        <a title="How to build a website with Kallyas">
                                           {{ item?.titulo }}
                                        </a>
                                    </h5>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>