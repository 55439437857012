<header class="header-area">


    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <ul class="top-header-contact-info">
                        <li class="mr-0"><a href="https://www.facebook.com/huaralemapa/" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li class="mr-0"><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li class="mr-0"><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li class="mr-0"><a href="https://www.youtube.com/channel/UCzaLLCoQ_KVwxWNugvUGtGg" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>

                <div class="col-lg-8 col-md-12">
                    <ul class="top-header-contact-info">
                        <li><i class='bx bx-map-alt'></i> Planta Principal: Av. Huando S/N</li>
                        <li><i class='bx bx-map'></i> Oficina Comercial: Av. Calle derecha N° 808</li>
                        <li><i class='bx bx-phone-call'></i> Telefóno: <a href="tel:+05101775399">(01) 7439070</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div> 
        
    <!-- End Top Header -->
    <div class="top-header p-0 header-emapa">        
        <div>
            <img src="assets/img/i_header.jpg" alt="logo" width="600">
        </div>
        <div class="option-item header-logo">
            <a class="default-btn p-1" style="border: none;">
                <img class="logo-img" src="./assets/img/logo_header.png" width="140">
            </a>
        </div>
    </div>

</header>
    
    
    
    
    
    
    
    
    
    
    
    