<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2><i routerLink="/" style="cursor:pointer" class="bx bx-arrow-back"> </i> Control Interno y Ética</h2>
        </div>
    </div>
</section>

<section class="faq-area ptb-100 bg-f9f9f9">


    <div class="container">
        <div class="row justify-content-center">

            <a routerLink="/control_etico_pdf" class="col-md-4 files">
                <i class="bx bx-folder file icon"></i> <br>
                <p><b>Código Ético</b></p>
            </a>
            <a routerLink="/control_interno_secciones" class="col-md-4 files">
                <i class="bx bx-folder file icon"></i> <br>
                <p><b>Control Interno</b>
                </p>
            </a>
        </div>
    </div>
</section>

<app-footer></app-footer>
