import { Component, OnInit } from '@angular/core';
import { Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import { BeliService } from 'src/app/services/beli.service';
import * as moment from 'moment';

@Component({
  selector: 'app-comunicado',
  templateUrl: './comunicado.component.html',
  styleUrls: ['./comunicado.component.scss']
})
export class ComunicadoComponent implements OnInit {

  location: Location;
  bankName:string;
  isLoading: boolean = false;
  comunicado : any;
  comunicados : any;
  path = this.beli.path();

  constructor(private route:ActivatedRoute, location: Location,private beli: BeliService,) {
    this.location = location;
   }

  ngOnInit(): void {
    this.getData();
    this.getComunicados();
    moment.locale('es');
  }

  getComunicado (id) {
    this.isLoading  = true;
    this.beli.get('page/comunicado/'+id).then( data => { 
      this.comunicado = data.success ? data.info : [];
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }



  getComunicados() 
  {
    this.isLoading  = true;
    this.beli.get('page/comunicados').then( data => { 
      this.comunicados = data.success ? data.info : [];
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }

  getData() {
      this.bankName = this.route.snapshot.params['param'];
      this.getComunicado(this.bankName);
    
  }
  setComunicadoflat = true;

  setComunicado(item) {
    this.setComunicadoflat = false;
    
    this.getComunicado(item.id);
    window.scrollTo(0, 135);
    setTimeout( () => {
      this.setComunicadoflat = true;
    } ,1500);

  }

  setFecha(fecha) {
    return  moment(fecha).format('LL');
  }

}
