<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 style="text-align: left;"> REPORTES DE SEGURIDAD</h2>
        </div>
    </div>
</section>

<section class="doctors-area pt-100 pb-70">
    <div class="shadow mx-2 border border-blue border-darken-4 black">
        <div class="card-header card-head-inverse bg-blue bg-darken-4" style="text-align: center;">
            <h6 class="card-title" style="font-weight: 900; margin-bottom: 0px;"> DATOS DEL AGENTE DE SEGURIDAD </h6>
        </div>
        <div class="card-content collapse show" style="text-align: center;">
            <table class="table">
                <tbody>
                  <tr>
                    <th scope="row" style="padding: 1rem !important;     vertical-align: middle !important;">NOMBRES Y APELLIDOS: </th>
                    <td colspan="3">                                        <input type="text" class="form-control" style="border: none;"
                        formControlName="apellidos_maternos" placeholder="Ingrese Apellido Materno"></td>
                  </tr>
                  <tr>
                    <th scope="row">EDAD: </th>
                    <td colspan="3">                                        <input type="text" class="form-control" style="border: none;"
                        formControlName="apellidos_maternos" placeholder="Ingrese Apellido Materno"></td>
                  </tr>
                  <tr>
                    <th scope="row">DNI: </th>
                    <td colspan="3">                                        <input type="text" class="form-control" style="border: none;"
                        formControlName="apellidos_maternos" placeholder="Ingrese Apellido Materno"></td>
                  </tr>
                  <tr>
                    <th scope="row">CELULAR: </th>
                    <td colspan="3">                                        <input type="text" class="form-control" style="border: none;"
                        formControlName="apellidos_maternos" placeholder="Ingrese Apellido Materno"></td>
                  </tr>
                  <tr>
                    <th scope="row">CARGO: </th>
                    <td colspan="3">                                        <input type="text" class="form-control" style="border: none;"
                        formControlName="apellidos_maternos" placeholder="Ingrese Apellido Materno"></td>
                  </tr>
                   <tr>
                    <th scope="row">TURNO: </th>
                    <td colspan="3">                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
                        <label class="form-check-label" for="inlineRadio1">DIA</label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
                        <label class="form-check-label" for="inlineRadio1">NOCHE</label></td>
                  </tr>
                  <tr>
                    <th scope="row">SEDE: </th>
                    <td colspan="3"> 
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
                            <label class="form-check-label" for="exampleRadios1">
                              PLANTA PRINCIPAL&nbsp;&nbsp;
                            </label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
                            <label class="form-check-label" for="exampleRadios2">
                              LOCAL COMERCIAL&nbsp;&nbsp;
                            </label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option3">
                            <label class="form-check-label" for="exampleRadios3">
                              ALMACEN EXTERNO
                            </label>
                          </div>
                    </td>
                  </tr>
                </tbody>
              </table>
        </div>
        <div class="card-header card-head-inverse bg-blue bg-darken-4" style="text-align: center;">
            <h6 class="card-title" style="font-weight: 900; margin-bottom: 0px;"> DETALLE DEL SERVICIO REALIZADO </h6>
        </div>
        <div class="card-content collapse show">
            <div class="card-body">
                
            </div>
        </div>
</div>
</section>

