import { ResolucionesGafComponent } from './components/pagina/documentos-gestion/resoluciones-gaf/resoluciones-gaf.component';
import { ResolucionesGerencialesComponent } from './components/pagina/documentos-gestion/resoluciones-gerenciales/resoluciones-gerenciales.component';
import { RouterModule, Routes } from "@angular/router";

import { InicioComponent } from './components/pagina/inicio/inicio.component';
import { QuienesSomosComponent } from './components/pagina/nosotros/quienes-somos/quienes-somos.component';
import { DirectorioComponent } from './components/pagina/nosotros/directorio/directorio.component';
import { AccionistasComponent } from './components/pagina/nosotros/accionistas/accionistas.component';
import { GerenteLineaComponent } from './components/pagina/nosotros/gerente-linea/gerente-linea.component';
import { ValoresMaximosComponent } from './components/pagina/servicio-cliente/valores-maximos/valores-maximos.component';
import { EstructuraTarifariaComponent } from './components/pagina/servicio-cliente/estructura-tarifaria/estructura-tarifaria.component';
import { ServiciosColateralesComponent } from './components/pagina/servicio-cliente/servicios-colaterales/servicios-colaterales.component';
import { NotasComponent } from './components/pagina/prensa/notas/notas.component';
import { GFotograficaComponent } from './components/pagina/prensa/g-fotografica/g-fotografica.component';
import { GAudiovisualComponent } from './components/pagina/prensa/g-audiovisual/g-audiovisual.component';
import { ConsultaDeudaComponent } from './components/pagina/pago/consulta-deuda/consulta-deuda.component';
import { CentroAtencionComponent } from './components/pagina/pago/centro-atencion/centro-atencion.component';
//import { DocumentosGestionComponent } from './components/pagina/documentos-gestion/documentos-gestion.component';
import { TransparenciaComponent } from './components/pagina/transparencia/transparencia.component';
import { ConveniosComponent } from './components/pagina/documentos-gestion/convenios/convenios.component';
import { DirectivasComponent } from './components/pagina/documentos-gestion/directivas/directivas.component';
import { NormatividadComponent } from './components/pagina/documentos-gestion/normatividad/normatividad.component';
import { OrganigramaComponent } from './components/pagina/nosotros/organigrama/organigrama.component';
import { IndicadoresGestionComponent } from './components/pagina/nosotros/indicadores-gestion/indicadores-gestion.component';
import { ControlInternoComponent } from './components/pagina/nosotros/control-interno/control-interno.component';
import { ConvocatoriasComponent } from './components/pagina/nosotros/convocatorias/convocatorias.component';
import {NoticiaComponent} from './components/pagina/prensa/notas/noticia/noticia.component';
import {FormAtencionClienteComponent} from './components/pagina/inicio/form-atencion-cliente/form-atencion-cliente.component';
import {FormReclamacionesComponent} from './components/pagina/inicio/form-reclamaciones/form-reclamaciones.component';
import {GerencialesItemComponent } from './components/pagina/documentos-gestion/resoluciones-gerenciales/gerenciales-item/gerenciales-item.component'
import { GafItemComponent } from './components/pagina/documentos-gestion/resoluciones-gaf/gaf-item/gaf-item.component';
import { ConveniosItemComponent } from './components/pagina/documentos-gestion/convenios/convenios-item/convenios-item.component';
import { DirectivasItemComponent } from './components/pagina/documentos-gestion/directivas/directivas-item/directivas-item.component';
import { NormatividadItemComponent } from './components/pagina/documentos-gestion/normatividad/normatividad-item/normatividad-item.component';
import { AtencionClienteComponent } from './components/pagina/inicio/atencion-cliente/atencion-cliente.component';
import { FormComercialComponent } from './components/pagina/inicio/form-comercial/form-comercial.component';
import { ReciboDigitalComponent } from './components/pagina/inicio/recibo-digital/recibo-digital.component';
import { ControlEticoPdfComponent } from './components/pagina/nosotros/reportes/control-etico-pdf/control-etico-pdf.component';
import { ControlInternoSeccionComponent } from './components/pagina/nosotros/control-interno/control-interno-seccion/control-interno-seccion.component';
import { SolicitudReclamacionesComponent } from './components/pagina/inicio/solicitud-reclamaciones/solicitud-reclamaciones.component';
import { SolicitudQuejasComponent } from './components/pagina/inicio/solicitud-quejas/solicitud-quejas.component';
import { ComunicadosComponent } from './components/pagina/prensa/comunicados/comunicados.component';
import { ComunicadoComponent } from './components/pagina/prensa/comunicados/comunicado/comunicado.component';
import { ReportesComponent } from './components/pagina/reportes/reportes.component';
import { EncuestaComponent } from './encuesta/encuesta.component';
import { GraciasComponent } from './encuesta/gracias/gracias.component';
import { IncidenciasComponent } from './components/pagina/incidencias/incidencias.component';
import { DecretoComponent } from './components/pagina/nosotros/convocatorias/decreto/decreto.component';
import { PracticasComponent } from './components/pagina/nosotros/convocatorias/practicas/practicas.component';

//Lista de rutas de la aplicacion
const APP_ROUTES: Routes = [
    { path: '', component: InicioComponent },
    { path: 'quienes_somos', component: QuienesSomosComponent },
    { path: 'organigrama', component: OrganigramaComponent },
    { path: 'directorio&id_tema', component: DirectorioComponent },
    { path: 'accionistas&id_tema', component: AccionistasComponent },
    { path: 'gerente_linea&id_tema', component: GerenteLineaComponent },
    { path: 'indicadores_gestion', component: IndicadoresGestionComponent },
    { path: 'control_interno', component: ControlInternoComponent },
    { path: 'control_etico_pdf', component: ControlEticoPdfComponent },
    { path: 'control_interno_secciones', component: ControlInternoSeccionComponent },
    { path: 'convocatorias', component: ConvocatoriasComponent },
    { path: 'recibo_digital', component: ReciboDigitalComponent},
    { path: 'solicitud_reclamaciones', component: SolicitudReclamacionesComponent },
    { path: 'solicitud_quejas', component: SolicitudQuejasComponent },   
    // { path: 'documentos-gestion/:id', component: DocumentosGestionComponent },
    { path: 'resoluciones_gerenciales&id_tema', component: ResolucionesGerencialesComponent },
    { path: 'resoluciones_gaf&id_tema', component: ResolucionesGafComponent },
    { path: 'convenios&id_tema', component: ConveniosComponent },
    { path: 'directivas&id_tema', component: DirectivasComponent },
    { path: 'normatividad&id_tema', component: NormatividadComponent },
    { path: 'valores_maximos', component: ValoresMaximosComponent },
    { path: 'estructura_tarifaria', component: EstructuraTarifariaComponent },
    { path: 'servicios_colaterales', component: ServiciosColateralesComponent },
    { path: 'notas_prensa&id_tema', component: NotasComponent },
    { path: 'notas_prensa/noticia', component: NoticiaComponent },
    { path: 'galeria_fotografica', component: GFotograficaComponent },
    { path: 'galeria_audiovisual', component: GAudiovisualComponent },
    { path: 'consulta_deuda', component: ConsultaDeudaComponent },
    { path: 'centro_atenciones', component: CentroAtencionComponent },
    { path: 'transparencia', component: TransparenciaComponent },
    { path: 'atencion_clientes/operacional',component:FormAtencionClienteComponent },
    { path: 'form_reclamaciones', component:FormReclamacionesComponent},
    { path: 'resoluciones_gerenciales/:param',component :GerencialesItemComponent},
    { path: 'resoluciones_gaf/:param',component :GafItemComponent},
    { path: 'convenios/:param',component :ConveniosItemComponent},
    { path: 'directivas/:param',component :DirectivasItemComponent},
    { path: 'normatividad/:param',component :NormatividadItemComponent},
    { path: 'atencion_clientes',component :AtencionClienteComponent},
    { path: 'atencion_clientes/comercial',component:FormComercialComponent},

    { path: 'comunicados&id_tema',component:ComunicadosComponent},
    { path: 'comunicados/comunicado/:param',component:ComunicadoComponent},
    { path: 'reportes/seguridad', component:ReportesComponent},
    { path: 'encuesta/recibo-digital', component:EncuestaComponent},
    { path: 'encuesta/gracias', component:GraciasComponent },
    { path: 'incidencias', component:IncidenciasComponent },
    { path: 'convocatorias/decreto', component:DecretoComponent },
    { path: 'convocatorias/practicas', component:PracticasComponent },

        
    {
        path: '',
    component: InicioComponent,
    },
    // {path:'**', redirectTo:"/"}
    
];

export const AppRoutes = RouterModule.forRoot(APP_ROUTES, { useHash: true });
