import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-control-interno-seccion',
  templateUrl: './control-interno-seccion.component.html',
  styleUrls: ['./control-interno-seccion.component.scss']
})
export class ControlInternoSeccionComponent implements OnInit {
  seccion: number = 1;
  constructor() { }

  ngOnInit(): void {
  }
    getSeccion(sec){
        this.seccion = sec;
    }
}
