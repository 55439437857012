import { Component, OnInit } from '@angular/core';


import { BeliService } from 'src/app/services/beli.service';

declare let $ :any;

@Component({
  selector: 'app-organigrama',
  templateUrl: './organigrama.component.html',
  styleUrls: ['./organigrama.component.scss']
})
export class OrganigramaComponent implements OnInit {

  url: string;
  isLoading: boolean = false;
  
  organigrama :{
    src_documento : string
  };
  
  path = this.beli.path();
 
  constructor(
    private beli: BeliService,
  ) { }

  ngOnInit(): void {
    this.getData();
  }
 
  getData () {
    this.isLoading  = true;
    this.beli.get('page/organigrama').then( data => { 
      this.organigrama = data.success ? data.info : [];
      this.url = this.path + this.organigrama.src_documento;
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }

  onLoadIframe () {
    
    setTimeout( () => { $("#preloader-organigrama")[0].style.display = "none"; }, 2500);
    setTimeout( () => { $("#iframe-organigrama")[0].style.display = "block"; }, 2500);
  }
}
