<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 style="text-align: left;"> Consulta tu deuda</h2>
        </div>
    </div>
</section>

<!--<section class="faq-area ptb-100 bg-f9f9f9">
    <div class="container">
        <div class="row my-4 justify-content-center">
            <div class="col-md-10 card shadow mx-2 border" style="padding-left: 0px !important; padding-right: 0% !important;">
                <iframe src="http://e-siincoweb.emapahuaral.com.pe/e-siincoweb/emapahuaral/consultas/" style="border: none; height: 60vh;"></iframe>
            </div>
        </div>
    </div>
</section>-->
<section class="faq-area ptb-100 bg-f9f9f9">
    <div class="container">
        <div class="row my-4 justify-content-center">
            
            <div class="col-md-10 card shadow mx-2 border" style="padding: 0px;"> 
                <div class="container" style="padding: 0;">
                    <div class="alert alert-primary alert-dismissable" style="background-color: #088A85 !important; color: white;">
                        <i class="fas fa-question-circle" style="font-size: 17px; cursor: pointer;" (click)="mostrarReciboDeEjemplo()"> <strong style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif"> ¿Dónde ubico el número de suministro?</strong></i>
                    </div>
              
            
            </div>
                <form class="border-form" method="POST" (submit)="submit()" [formGroup]="formModel" class="p-4">   
                    <div class="form-group row">            
                    <div class="col-sm-12">
                        <h6 for="select-beast-selectized">INGRESE EL NÚMERO DE SUMINISTRO: </h6>
                    </div>
                    <div class="col-sm-12">
                        <input type="text" id="textbusqueda" name="textbusqueda" class="form-control form-control" formControlName="numero_suministro" placeholder="Ejem: 9128">
                        <small class="text-danger mt-1 px-2 py-1 rounded"
                        *ngIf="(formValidate.numero_suministro.dirty || formValidate.numero_suministroed) 
                        && formValidate.numero_suministro.errors"
                        >
                        <ng-container [ngSwitch]="formValidate.numero_suministro.value">
                            <ng-container *ngSwitchCase="''">
                                ¡Por favor debe de ingresar su número de suministro!
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                ¡El formato ingresado es inválido!
                            </ng-container>
                        </ng-container>
                        </small>
                    </div>
                </div>
                <div class="col-sm-12 text-center">
                    <button *ngIf="!isSending" type="submit" class="btn btn-info btn-lg botonEnv" style="width: 140px;" data-toggle="modal" data-target="#exampleModalCenter" data-placement="top" title="Completa todos los campos"
                    [disabled]="formModel.invalid"><i class="fas fa-search" style="font-size: 12px;"></i> CONSULTAR</button>
                </div>
                </form>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>
<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="text-right">
                <button class="close pr-1" type="button" data-dismiss="modal" aria-label="Close" data-original-title="" title=""><span aria-hidden="true" class="text-danger">×</span></button>
            </div>
             <div *ngIf="isLoading" id="preloader-pagos" class="preloader-iframe"></div>
             <div class="modal-body" *ngIf="isExists && !isLoading && isDeuda">
                 <ul class='list-group'><li class='list-group-item list-group-item-warning font-weight-bold'><span><i class="fa fa-exclamation-triangle" ></i> ¡Registra deuda pendiente a la fecha! </span></li></ul>
                 <ul class='list-group'><li class='list-group-item'><span>Código de suministro: </span><span class='float-right'>{{nrosuministro}}</span></li></ul>
                 <ul class='list-group'><li class='list-group-item'><span>Nombre del cliente: </span><span class='float-right'>{{propietario}}</span></li></ul>
                 <ul class='list-group'><li class='list-group-item'><span>Número del recibo: </span><span class='float-right'>{{recibo}}
                    <a href="{{url}}" target="_blank" style="color: rgba(190, 28, 28, 0.778);" ><i class='fas fa-file-pdf fa-sm' style="color: rgba(190, 28, 28, 0.778);" title='Descargar'></i> DESCARGAR</a></span></li></ul>
                 <ul class='list-group'><li class='list-group-item list-group-item-dark font-weight-bold'><span><u>Total Deuda </u></span><span class='float-right'>S/{{total}}</span></li></ul>
             </div>
             
        <div class="modal-body pt-0" *ngIf="isExists && !isLoading && !isDeuda">
            <div class="alert alert-success d-flex align-items-center" role="alert">
                <i class="fa fa-check-circle"></i><br>
                <div class="container">
                  <p>¡El número de suministro ingresado, no registra deudas a la fecha!</p>
                </div>
            </div>
        </div>
        
        <div class="modal-body" *ngIf="!isExists && !isLoading">
            <div class="alert alert-danger d-flex align-items-center" role="alert">
                <i class="fa fa-exclamation-triangle"></i><br>
                <div class="container">
                  <p>¡El número de suministro ingresado, no existe! Por favor vuelva a intentarlo.</p>
                </div>
            </div>
        </div>
      </div>
    </div>        
</div>
