import { Component, OnInit } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { BeliService } from 'src/app/services/beli.service';

@Component({
  selector: 'app-accionistas',
  templateUrl: './accionistas.component.html',
  styleUrls: ['./accionistas.component.scss']
})
export class AccionistasComponent implements OnInit {


  isLoading: boolean = false;
  accionistas :any;

  constructor(
    private beli: BeliService,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData () {
    this.isLoading  = true;
    this.beli.get('page/accionistas').then( data => { 
      this.accionistas = data.success ? data.info : [];
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }


}
