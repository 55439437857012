import { Component, OnInit } from '@angular/core';
import { environment } from "src/environments/environment";
import { BeliService } from 'src/app/services/beli.service';

declare let $ : any;

@Component({
  selector: 'app-estructura-tarifaria',
  templateUrl: './estructura-tarifaria.component.html',
  styleUrls: ['./estructura-tarifaria.component.scss']
})
export class EstructuraTarifariaComponent implements OnInit {

  url: string;
  isLoading: boolean = false;
  estructura_tarifaria :{
    src_documento : string
  };
  
  path = this.beli.path();

  constructor(
    private beli: BeliService,
  ) { }

  ngOnInit(): void {
    this.getData();
  }
 
  getData () {
    this.isLoading  = true;
    this.beli.get('page/estructura_tarifaria').then( data => { 
      this.estructura_tarifaria = data.success ? data.info : [];
      this.url = this.path + this.estructura_tarifaria.src_documento;
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }

  displayTest(){
    setTimeout( () => { $("#preloader-estructura")[0].style.display = "none"; },2500);
    setTimeout( () => { $("#iframe-estructura")[0].style.display = "block"; },2500);
 }

}
