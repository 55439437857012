import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { BeliService } from 'src/app/services/beli.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-recibo-digital',
  templateUrl: './recibo-digital.component.html',
  styleUrls: ['./recibo-digital.component.scss']
})
export class ReciboDigitalComponent implements OnInit {

  formModel;
  isSending = false;
  enviarCorreoSolicitud:any;
  mensaje:any;
  respuesta : [];
  isExists:boolean;
  propietario : String;
  isDisable:boolean;
  Ocultar:boolean = false;
  MostrarAfiliar:boolean = false;
  isVacio:boolean =  true;
  isVerSuscripcion:boolean = false;
  datos : any;
  dataSuscripcion:any;
  dataEmail:String;
  dataApellidoP:String;
  dataApellidoM:String;
  dataNombre:String;
  dataDNI:String;
  dataFecha:String;
  response =  {
  success: false,
  message: ''
  }

  soy_tipo =[
    {nombre:'Titular'},
    {nombre:'Propietario del Predio'},
    {nombre:'Usuario Efectivo del Servicio'},
    {nombre:'Representante Legal'},
    {nombre:'Otro'},
  ];
  // 
  tipo_documento = [
    {nombre: 'DNI'},
    {nombre: 'RUC'},
    {nombre: 'Ce'},
  ];

  constructor(private formBuilder : FormBuilder, private beli: BeliService,) { 

    this.formModel = this.formBuilder.group({
      fecha: [this.formatDate()],
      numero_suministro: ['', [
        Validators.required,
        Validators.compose([Validators.required,
        Validators.max(9999999999999999)]),
        Validators.pattern(/^[0-9]+(\.?[7-7]+)?$/)
      ]],
      propietario: ['', [
      ]],
      soy: [null,Validators.required],
      dni: [null,Validators.required],
      numero_documento: ['',[
        Validators.compose([Validators.required,
          Validators.max(999999999999999)]),
        Validators.required,
        Validators.pattern(/^[0-9]+(\.?[7-7]+)?$/)
      ]],
      nombres: ['',[
        Validators.required,
        Validators.maxLength(60),
        Validators.pattern(/[A-Za-z0-9]{1,20}/),
        Validators.pattern(/^[a-z áãâäàéêëèíîïìóõôöòúûüùçñ]+$/i)
      ]],
      apellidos_paternos: ['',[
        Validators.required,
        Validators.maxLength(60),
        Validators.pattern(/[A-Za-z0-9]{1,20}/),
        Validators.pattern(/^[a-z áãâäàéêëèíîïìóõôöòúûüùçñ]+$/i)
      ]],
      apellidos_maternos: ['',[
        Validators.required,
        Validators.maxLength(60),
        Validators.pattern(/[A-Za-z0-9]{1,20}/),
        Validators.pattern(/^[a-z áãâäàéêëèíîïìóõôöòúûüùçñ]+$/i)
      ]],
      direccion: ['', [
        Validators.required
      ]],
      email: ['', [
        Validators.required,
        Validators.maxLength(80),
        Validators.pattern(
         /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)
      ]],
      telefono_celular: ['', [
        Validators.required,
        Validators.compose([Validators.required,
        Validators.min(100000000),
        Validators.max(999999999)]),
        Validators.pattern(/^[0-9]+(\.?[7-7]+)?$/)
      ]],
      telefono_fijo: ['',[Validators.maxLength(15)
      ]],

      
  });

  }

  ngOnInit(): void {
    this.Ocultar = false;
  }
  get formValidate(){
    return this.formModel.controls;
  }

  submit() {
    this.sendForm(this.formModel.value);
    setTimeout(() =>{ 
      this.response.success = false;
    },10000);
  }

  validar() {
   this.function_validar(this.formModel.value);
  }

  autenticar():any {
    try {
      var formData: any = new FormData();
        formData.append("numero_suministro", this.formModel.get('numero_suministro').value)
        formData.append("fecha", this.formModel.get('fecha').value);
        formData.append("soy", this.formModel.get('soy').value);
        formData.append("dni", this.formModel.get('dni').value);
        formData.append("numero_documento", this.formModel.get('numero_documento').value);
        formData.append("nombres", this.formModel.get('nombres').value);
        formData.append("apellidos_paternos", this.formModel.get('apellidos_paternos').value);
        formData.append("apellidos_maternos", this.formModel.get('apellidos_maternos').value);
        formData.append("email", this.formModel.get('email').value);
        formData.append("telefono_celular", this.formModel.get('telefono_celular').value);
        formData.append("telefono_fijo", this.formModel.get('telefono_fijo').value);
        formData.append("direccion", this.formModel.get('direccion').value);
      
      
      this.sendForm(formData);
        setTimeout(() =>{ 
          this.response.success = false;

        },10000);
      
    } catch (error) {

    }
    
  }

  sendForm (data) {
    this.isSending = true;
    this.beli.post('page/recibo_digital',data).then( data => { 
      this.response = data.success ? data : [];
      this.isSending = false;
      this.sendMail(this.enviarCorreoSolicitud);
      Swal.fire({
        icon: 'success',
        title: '<small><b>Se ha enviado su solicitud de Afiliación al Recibo digital</b></small>',
        html: '<p>Revise la bandeja de entra de su correo electrónico. Spam u Otros</p><br><p>En breve será redirigido a la página principal. Gracias</p>',
        timer: 6000,
        showConfirmButton: false,
      })
      
      .then(function(){
        location.href = '/'
      });
      this.formModel.reset();
    })
  }
  sendMail(enviarCorreoSolicitud){
    this.beli.get('page/enviar_correo').then(data => {
      this.enviarCorreoSolicitud = data.success ? data.info : [];
      console.log(this.enviarCorreoSolicitud);
      }, error => console.error(error));
      return enviarCorreoSolicitud;
  }

  formatDate() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  mostrarReciboDeEjemplo(){
    Swal.fire({
      imageUrl: 'assets/img/inicio/solicitud-reclamos/recibo.jpeg',
      imageHeight: 500,
      imageWidth: 800,
      padding: 0,
    })
  }

  capturar(){
    if(this.formModel.get('numero_suministro').value === '' || this.formModel.get('numero_suministro').value === null){
      this.isVacio=true;
      this.mensaje = 'Debe ingresar su código de suministro.';
    }else{
      this.mensaje = '';
      this.isVacio=false;
    }
  }


  
  function_validar(formdata){
    if(this.formModel.get('numero_suministro').value === '' || this.formModel.get('numero_suministro').value === null){
      
    }else{
    this.beli.post('page/consulta_duplicado',formdata).then( data => { 
      if(data.success){
        Swal.fire({
          title: '<small><b>¡SUMINISTRO CORRECTO!</b></small>',
          icon: 'success',
          html: '<p>A continuación llene el formulario para afiliarse al recibo digital.</p>',
            
          focusConfirm: false,
          confirmButtonText:
            '<i class="fa fa-thumbs-up"></i> Continuar',
          confirmButtonAriaLabel: 'Thumbs up, great!',
          cancelButtonText:
            '<i class="fa fa-thumbs-down"></i>',
          cancelButtonAriaLabel: 'Thumbs down'
        })
        this.datos= Object.values(data.message);
        this.propietario = (this.datos[0]['propietario']);
        this.isExists = true;
        this.isDisable = true;
        this.Ocultar = true;
        this.MostrarAfiliar = true;
      }else{
       if(Object.values(data.message).length > 0){
        this.Ocultar = true;
        this.dataSuscripcion= Object.values(data.message);
        this.isVerSuscripcion = true;
        var numero = this.dataSuscripcion[0]['numero_documento'];
        var numeroParcial = "*****" + numero.substring(5, 8);
        this.dataNombre = this.dataSuscripcion[0]['nombres'].replace('an', (c) => c.substr(0, 2) + c.split('').slice(2, -1).map(v => '*'));
        this.dataDNI = numeroParcial;
        this.dataApellidoP = (this.dataSuscripcion[0]['apellidos_paternos']);
        this.dataApellidoM = (this.dataSuscripcion[0]['apellidos_maternos']);
        let chars = 4; // Cantidad de caracters visibles
        this.dataEmail = this.dataSuscripcion[0]['email'].replace(/[a-z0-9\-_.]+@/ig, (c) => c.substr(0, chars) + c.split('').slice(chars, -1).map(v => '*').join('') + '@');
        var anio = this.dataSuscripcion[0]['fecha'].substring(0, 4);
        var mes = this.dataSuscripcion[0]['fecha'].substring(5, 7);
        var dia = this.dataSuscripcion[0]['fecha'].substring(8, 10);
        this.dataFecha = dia + '/' + mes + '/' + anio;
       }else{
        Swal.fire({
          icon: 'error',
          html: '<p>El código de suministro ingresado, <b> NO EXISTE</b>. Por favor verifique. </p>'+'<small>Si no sabe dónde esta el código de suministro de su recibo, <b> consulte al boton de ayuda en la parte superior</b>. </small>',
            
          focusConfirm: false,
          confirmButtonText:
            'VOLVER',
          confirmButtonAriaLabel: 'Thumbs up, great!',
          cancelButtonText:
            '<i class="fa fa-thumbs-down"></i>',
          cancelButtonAriaLabel: 'Thumbs down'
        })
       }
       
      };
    })
  }}

  validarSuministro(){

    this.beli.get("page/consulta_usuario?idsucursal=1&textbusqueda="+this.formModel.get('numero_suministro').value).then( data => {
      this.respuesta = data.success ? data.info : [];
      if(this.respuesta.length > 0){
        Swal.fire({
          title: '<small><b>¡SUMINISTRO CORRECTO!</b></small>',
          icon: 'success',
          html: '<p>A continuación llene el formulario para afiliarse al recibo digital.</p>',
            
          focusConfirm: false,
          confirmButtonText:
            '<i class="fa fa-thumbs-up"></i> Continuar',
          confirmButtonAriaLabel: 'Thumbs up, great!',
          cancelButtonText:
            '<i class="fa fa-thumbs-down"></i>',
          cancelButtonAriaLabel: 'Thumbs down'
        })
        this.datos= Object.values(this.respuesta);
        this.propietario = (this.datos[0]['propietario']);
        this.isExists = true;
        this.isDisable = true;
        this.Ocultar = true;

      }else{
        Swal.fire({
           title: '<small><b>¡ADVERTENCIA!</b></small>',
          icon: 'warning',
          html:
              '<p>El número de suministro ingresado <b>NO EXISTE!&nbsp;&nbsp;</b> Por favor asegurese de haber escrito correctamente su número de suministro y vuelva a intentarlo.</p>' +
              '<small><b>Para mayor información consulte al botón de ayuda <i class="fa fa-question-circle"></i></b></small>',
          allowOutsideClick: false,
          confirmButtonText:
            '<i class="fa fa-arrow-left"></i> Volver',
        })
        this.formModel.get('numero_suministro').reset();
      }
  })
  .catch();
  }

  salir(){
    location.reload();
  }
}
