<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>  <i routerLink="/atencion_clientes" style="cursor:pointer" class="bx bx-arrow-back"> </i>  
                Formulario Atención al Cliente - Comercial </h2>
        </div>
    </div>
</section>

<section class="doctors-area pt-100 pb-70">
    <div class="container">
        <form class="border-form" (submit)="submit()" [formGroup]="formModel">
            <div class="row border-section">
                <div class="noticia">
                    <img class="izquierda" src="assets/img/emapa.png" width="60px">
                    <aside class="cabecera-titulo">
                        Formato 2<strong>(Reclamo Comercial)</strong><br>
                        Presentación del Reclamo<br>
                    </aside>
                    <div class="reset"></div>
                </div>
            </div>
            <hr>
            <div class="form-group row">
                <label class="col-sm-8 col-form-label text-end col-form-label-sm">
                    <strong>CÓDIGO SOLICITUD N°</strong>
                </label>
                <div class="col-sm-4">
                  <input type="text" class="form-control form-control-sm" disabled formControlName="codigo_solicitud">
                </div>
            </div>
            
            <div class="form-group row">
                <label class="col-sm-5 col-form-label col-form-label-sm">
                  <strong>NÚMERO DE SUMINISTRO </strong>  
                </label>
                <div class="col-sm-7">
                  <input type="text" class="form-control form-control-sm" formControlName="numero_suministro">
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-6 col-form-label col-form-label-sm">
                    <strong>NOMBRE DEL SOLICITANTE O REPRESENTANTE</strong>
                </label>
                <div class="col-sm-6 form-group row">
                    <label class="col-sm-4 col-form-label col-form-label-sm">
                        <strong>TELÉFONO</strong>
                    </label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control form-control-sm" formControlName="telefono">
                      </div>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-sm-4">
                  <input type="text" class="form-control form-control-sm"  formControlName="apellido_paterno">
                  <small class="form-text text-center text-muted">Apellido Paterno</small>
                </div>
                <div class="col-sm-4">
                    <input type="text" class="form-control form-control-sm" formControlName="apellido_materno">
                    <small class="form-text text-center text-muted">Apellido Materno</small>
                </div>
                <div class="col-sm-4">
                    <input type="text" class="form-control form-control-sm" formControlName="nombres">
                    <small class="form-text text-center text-muted">Nombres</small>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label col-form-label-sm">
                    NÚMERO DEL DOCUMENTO DE IDENTIDAD
                </label>
                <div class="col-sm-8">
                    <input type="text" class="form-control form-control-sm" formControlName="documento_identidad">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label col-form-label-sm">
                   RAZÓN SOCIAL
                </label>
                <div class="col-sm-8">
                    <input type="text" class="form-control form-control-sm" formControlName="razon_social">
                  </div>
            </div>
            <hr>
            <div class="form-group row">
                <label class="col-sm-12 col-form-label col-form-label-sm">
                   <strong>UBICACIÓN DEL PREDIO</strong>
                </label>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                  <input type="text" class="form-control form-control-sm" formControlName="ubicacion">
                  <small class="form-text text-center text-muted">(Calle, Jirón, Avenida)</small>
                </div>
                <div class="col-sm-2">
                    <input type="text" class="form-control form-control-sm" formControlName="ubicacion_numero">
                    <small class="form-text text-center text-muted">N°</small>
                </div>
                <div class="col-sm-2">
                    <input type="text" class="form-control form-control-sm" formControlName="ubicacion_manzana">
                    <small class="form-text text-center text-muted">MZ</small>
                </div>
                <div class="col-sm-2">
                    <input type="text" class="form-control form-control-sm" formControlName="ubicacion_lote">
                    <small class="form-text text-center text-muted">Lote</small>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-4">
                  <input type="text" class="form-control form-control-sm" formControlName="urbanizacion">
                  <small class="form-text text-center text-muted">Urbanización</small>
                </div>
                <div class="col-sm-4">
                    <input type="text" class="form-control form-control-sm" formControlName="distrito">
                    <small class="form-text text-center text-muted">Distrito</small>
                </div>
                <div class="col-sm-4">
                    <input type="text" class="form-control form-control-sm" formControlName="provincia">
                    <small class="form-text text-center text-muted">Provincia</small>
                </div>
            </div>

            <hr>
            <div class="form-group row">
                <label class="col-sm-12 col-form-label col-form-label-sm">
                   <strong>DOMICILIO PROCESAL</strong>
                </label>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                  <input type="text" class="form-control form-control-sm" formControlName="domicilio_procesal">
                  <small class="form-text text-center text-muted">(Calle, Jirón, Avenida)</small>
                </div>
                <div class="col-sm-2">
                    <input type="text" class="form-control form-control-sm" formControlName="domicilio_procesal_numero">
                    <small class="form-text text-center text-muted">N°</small>
                </div>
                <div class="col-sm-2">
                    <input type="text" class="form-control form-control-sm" formControlName="domicilio_procesal_manzana">
                    <small class="form-text text-center text-muted">MZ</small>
                </div>
                <div class="col-sm-2">
                    <input type="text" class="form-control form-control-sm" formControlName="domicilio_procesal_lote">
                    <small class="form-text text-center text-muted">Lote</small>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-4">
                  <input type="text" class="form-control form-control-sm" formControlName="domicilio_procesal_urbanizacion">
                  <small class="form-text text-center text-muted">Urbanización</small>
                </div>
                <div class="col-sm-4">
                    <input type="text" class="form-control form-control-sm" formControlName="domicilio_procesal_distrito">
                    <small class="form-text text-center text-muted">Distrito</small>
                </div>
                <div class="col-sm-4">
                    <input type="text" class="form-control form-control-sm" formControlName="domicilio_procesal_provincia">
                    <small class="form-text text-center text-muted">Provincia</small>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-4">
                  <input type="text" class="form-control form-control-sm" formControlName="telefono_celular">
                  <small class="form-text text-center text-muted">Teléfono/Celular</small>
                </div>
                <div class="col-sm-4">
                    <input type="text" class="form-control form-control-sm" formControlName="email">
                    <small class="form-text text-center text-muted">Correo electrónico</small>
                </div>
                <div class="col-sm-4">
                </div>
            </div>
            <hr>

            <div class="form-group row">
                <label class="col-sm-12 col-form-label col-form-label-sm">
                   <strong>INFORMACIÓN DE LA SOLICITUD</strong>
                </label>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label col-form-label-sm">
                    TIPO DEL PROBLEMA 
                </label>
                <div class="col-sm-8 form-group">
                    <input type="text" class="form-control form-control-sm" formControlName="tipo_problema">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-12 col-form-label col-form-label-sm">
                    BREVE DESCRIPCIÓN DEL RECLAMO (meses reclamados, montos, etc. en lo aplicable)
                </label>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <textarea col="4" class="form-control form-control-sm" formControlName="descripcion"></textarea>
                </div>
            </div>
            <!--
            <hr>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label col-form-label-sm">
                    <strong>SUCURSAL / ZONAL </strong>
                </label>
                <div class="col-sm-8">
                    <input type="text" disabled="true" class="form-control form-control-sm" formControlName="sucursal_zonal">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-4 col-form-label col-form-label-sm">
                    <strong>ATENDIDO POR </strong>
                </label>
                <div class="col-sm-8">
                    <input type="text" disabled="true" class="form-control form-control-sm" formControlName="atendido_por">
                </div>
            </div>
            -->
            <hr>
            <div class="form-group row">
                <label class="col-sm-12 col-form-label col-form-label-sm">
                    FUNDAMENTOS DEL RECLAMO
                </label>
            </div>
            <div class="form-group row">
                <div class="col-sm-12">
                    <textarea rows="8" class="form-control form-control-sm" formControlName="descripcion_detalle"></textarea>
                </div>
            </div>
            
            <hr>
            <!--
            <div class="form-group row">
                <label class="col-sm-12 col-form-label col-form-label-sm">
                    RELACIÓN DE PRUEBAS QUE SE PRESENTAN ADJUNTAS
                </label>
            </div>
            <div class="form-group row">
                <div class="col-sm-4">
                     <input type="text" disabled="true" class="form-control form-control-sm" formControlName="relacion_pruebas_1">
                </div>
                <div class="col-sm-4">
                     <input type="text" disabled="true" class="form-control form-control-sm" formControlName="relacion_pruebas_2">
                </div>
                <div class="col-sm-4">
                     <input type="text" disabled="true" class="form-control form-control-sm" formControlName="relacion_pruebas_3">
                </div>
            </div>
            -->
            <div class="form-group row">
                <label for="colFormLabelSm" class="col-sm-8 col-form-label col-form-label-sm">
                    LA EPS ENTREGA CARTILLA INFORMATIVA 
                </label>
                <div class="col-sm-4">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" formControlName="cartilla_informativa" id="inlineRadio1" value="true">
                        <label class="form-check-label" for="inlineRadio1">Si </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" formControlName="cartilla_informativa" id="inlineRadio2" value="false">
                        <label class="form-check-label" for="inlineRadio2">No</label>
                      </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="colFormLabelSm" class="col-sm-8 col-form-label col-form-label-sm">
                    DECLARACIÓN DEL RECLAMANTE (aplicable a reclamos por consumo medido): <br>
                    Solicito la realización de prueba de contrastación y acepto asumir su costo, si el 
                    resultado de la prueba indica que el medidor no sobreregistra.
                </label>
                <div class="col-sm-4">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" formControlName="declaracion_reclamante" id="inlineRadio2" value="true">
                        <label class="form-check-label" for="inlineRadio2">Si </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" formControlName="declaracion_reclamante" id="inlineRadio3" value="false">
                        <label class="form-check-label" for="inlineRadio3">No</label>
                      </div>
                </div>
            </div>
            <hr>

            <div class="form-group row">
                <div class="col-sm-12 text-center">
                    <button *ngIf="!isSending" type="submit" class="btn btn-success" [disabled]="formModel.invalid" > Enviar </button>
                    <button *ngIf="isSending" class="btn btn-success" [disabled]="isSending"> enviando </button>   
                </div>
            </div>
            
        </form>
    </div>

</section>

 
<app-footer></app-footer>