

<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2> <i routerLink="/normatividad&id_tema" style="cursor:pointer" class="bx bx-arrow-back"> </i> 
                Normatividad - {{ bankName }}</h2>
        </div>
    </div>
</section>

<section class="faq-area ptb-100 bg-f9f9f9">
            <div class="col-md-12" *ngIf="isLoading">
                Cargando...
            </div>
            <div class="col-md-12" *ngIf="!isLoading">
                <div class="row">
                    <div class="col-md-12" *ngIf="data?.length == 0">
                        No hay archivos registrados
                    </div>
                    <div class="col-md-12" *ngIf="data?.length > 0">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th style="font-size: smaller;">Número</th>
                                        <th style="font-size: smaller;">Descripción</th>
                                        <th style="font-size: smaller;">Ver</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of data">
                                        <td scope="row" style="font-size: smaller;">
                                            N°{{item.numero}} - {{item.anio}}
                                        </td>
                                        <td style="font-size: smaller;">
                                            {{item.descripcion}}
                                        </td>
                                        <td>
                                            <a target="_blank" href="{{path + item.src_documento}}">
                                                <img src="assets/img/pdf.png" width="20">
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
</section>

<app-footer></app-footer>