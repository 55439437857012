import { Component, OnInit } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { BeliService } from 'src/app/services/beli.service';

@Component({
  selector: 'app-directorio',
  templateUrl: './directorio.component.html',
  styleUrls: ['./directorio.component.scss']
})
export class DirectorioComponent implements OnInit {
  
  isLoading: boolean = false;
  directorio :any;

  constructor(
    private beli: BeliService,
  ) { } 

  ngOnInit(): void {
    this.getData();
  }

  getData () {
    this.isLoading  = true;
    this.beli.get('page/directorio').then( data => { 
      this.directorio = data.success ? data.info : [];
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }

}
