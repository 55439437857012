<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2><i routerLink="/" style="cursor:pointer" class="bx bx-arrow-back"> </i> Resoluciones GAF</h2>
        </div>
    </div>
</section>

<section class="faq-area ptb-100 bg-f9f9f9">
    <div class="container"> 
        <div class="row">
            <div class="col-md-12" *ngIf="isLoading">
                Cargando...
            </div>

            <div class="col-md-12" *ngIf="!isLoading">
                <div class="row"  *ngIf="resoluciones_gaf?.length == 0">
                    No hay archivos registrados
                </div>
                <div class="row" *ngIf="resoluciones_gaf?.length > 0" >    
                    <a routerLink="/resoluciones_gaf/{{i}}" class="col-md-4 files" *ngFor="let i of resoluciones_gaf">
                        <i class="bx bx-folder file icon"></i> <br>
                        {{i}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>


<app-footer></app-footer>
