import { Component, OnInit } from '@angular/core';
import { BeliService } from 'src/app/services/beli.service';


@Component({
  selector: 'app-decreto',
  templateUrl: './decreto.component.html',
  styleUrls: ['./decreto.component.scss']
})
export class DecretoComponent implements OnInit {

  items2024: Array<any> = [
    // { numero: '10', anio: 2020, descripcion: 'CONVOCATORIA PARA DESARROLLADOR DE SOFTWARE Y APLICACIONES EN LA EMPRESA EMAPA HUARAL', url: 'archivos' }
  ];
  items2023: Array<any> = [
    // { numero: '10', anio: 2020, descripcion: 'CONVOCATORIA PARA DESARROLLADOR DE SOFTWARE Y APLICACIONES EN LA EMPRESA EMAPA HUARAL', url: 'archivos' }
  ];
  concluidas2024: Array<any> = [
    // { numero: '10', anio: 2020, descripcion: 'CONVOCATORIA PARA DESARROLLADOR DE SOFTWARE Y APLICACIONES EN LA EMPRESA EMAPA HUARAL', url: 'archivos' }
  ];
  items2022: Array<any> = [
    // { numero: '10', anio: 2020, descripcion: 'CONVOCATORIA PARA DESARROLLADOR DE SOFTWARE Y APLICACIONES EN LA EMPRESA EMAPA HUARAL', url: 'archivos' }
  ];
  items2021: Array<any> = [
    // { numero: '10', anio: 2020, descripcion: 'CONVOCATORIA PARA DESARROLLADOR DE SOFTWARE Y APLICACIONES EN LA EMPRESA EMAPA HUARAL', url: 'archivos' }
  ];
  items2020: Array<any> = [
    // { numero: '10', anio: 2020, descripcion: 'CONVOCATORIA PARA DESARROLLADOR DE SOFTWARE Y APLICACIONES EN LA EMPRESA EMAPA HUARAL', url: 'archivos' }
  ];
  path = this.beli.path();
  nodata: boolean = false;

  isLoading: boolean = true;

  constructor(
    private beli: BeliService,
  ) { }

  ngOnInit(): void {
    this.getData24();
    this.getData23();
    this.getDataConcluidas24();
    this.getData22();
    this.getData21();
    this.getData20();
  }
  getData24 () {
    this.beli.get('page/convocatorias2024').then( data => { 
     this.items2024 = data.success ? data.info : [];
     this.isLoading = false;
   });
 }
  getData23 () {
     this.beli.get('page/convocatorias2023').then( data => { 
      this.items2023 = data.success ? data.info : [];
      this.isLoading = false;
    });
  }
  
  getDataConcluidas24 () {
    this.beli.get('page/concluidas2024').then( data => { 
     this.concluidas2024 = data.success ? data.info : [];
     this.isLoading = false;
     console.log(this.concluidas2024);
   });
 }

  getData22 () {
    this.beli.get('page/convocatorias2022').then( data => { 
     this.items2022 = data.success ? data.info : [];
     this.isLoading = false;
   });
 }
 getData21 () {
  this.beli.get('page/convocatorias2021').then( data => { 
   this.items2021 = data.success ? data.info : [];
   this.isLoading = false;
 });
}
getData20 () {
  this.beli.get('page/convocatorias2020').then( data => { 
   this.items2020 = data.success ? data.info : [];
   this.isLoading = false;
   this.nodata = true;
 });
}
}