import { Component, OnInit } from '@angular/core';
import { BeliService } from 'src/app/services/beli.service';

@Component({
  selector: 'app-convocatorias',
  templateUrl: './convocatorias.component.html',
  styleUrls: ['./convocatorias.component.scss']
})
export class ConvocatoriasComponent implements OnInit {

  items2023: Array<any> = [
    // { numero: '10', anio: 2020, descripcion: 'CONVOCATORIA PARA DESARROLLADOR DE SOFTWARE Y APLICACIONES EN LA EMPRESA EMAPA HUARAL', url: 'archivos' }
  ];
  concluidas2023: Array<any> = [
    // { numero: '10', anio: 2020, descripcion: 'CONVOCATORIA PARA DESARROLLADOR DE SOFTWARE Y APLICACIONES EN LA EMPRESA EMAPA HUARAL', url: 'archivos' }
  ];
  items2022: Array<any> = [
    // { numero: '10', anio: 2020, descripcion: 'CONVOCATORIA PARA DESARROLLADOR DE SOFTWARE Y APLICACIONES EN LA EMPRESA EMAPA HUARAL', url: 'archivos' }
  ];
  items2021: Array<any> = [
    // { numero: '10', anio: 2020, descripcion: 'CONVOCATORIA PARA DESARROLLADOR DE SOFTWARE Y APLICACIONES EN LA EMPRESA EMAPA HUARAL', url: 'archivos' }
  ];
  items2020: Array<any> = [
    // { numero: '10', anio: 2020, descripcion: 'CONVOCATORIA PARA DESARROLLADOR DE SOFTWARE Y APLICACIONES EN LA EMPRESA EMAPA HUARAL', url: 'archivos' }
  ];
  path = this.beli.path();

  isLoading: boolean = true;

  constructor(
    private beli: BeliService,
  ) { }

  ngOnInit(): void {
    this.getData23();
    this.getDataConcluidas23();
    this.getData22();
    this.getData21();
    this.getData20();
  }

  getData23 () {
     this.beli.get('page/convocatorias2023').then( data => { 
      this.items2023 = data.success ? data.info : [];
      this.isLoading = false;
    });
  }
  getDataConcluidas23 () {
    this.beli.get('page/concluidas2023').then( data => { 
     this.concluidas2023 = data.success ? data.info : [];
     this.isLoading = false;
     console.log(this.concluidas2023);
   });
 }
  getData22 () {
    this.beli.get('page/convocatorias2022').then( data => { 
     this.items2022 = data.success ? data.info : [];
     this.isLoading = false;
   });
 }
 getData21 () {
  this.beli.get('page/convocatorias2021').then( data => { 
   this.items2021 = data.success ? data.info : [];
   this.isLoading = false;
 });
}
getData20 () {
  this.beli.get('page/convocatorias2020').then( data => { 
   this.items2020 = data.success ? data.info : [];
   console.log(this.items2020)
   this.isLoading = false;
 });
}
}
