import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { CrudService } from 'src/app/services/crud.service';
import { BeliService } from 'src/app/services/beli.service';

declare let $ : any;

@Component({
  selector: 'app-indicadores-gestion',
  templateUrl: './indicadores-gestion.component.html',
  styleUrls: ['./indicadores-gestion.component.scss']
})
export class IndicadoresGestionComponent implements OnInit {

  urlViewFile: SafeResourceUrl;
  ROOT_SERVER:any = environment.ROOT_SERVER;
  url: string;
  isLoading: boolean = false;
  
  indicadores_gestion :{
    src_documento : string
  };
  
  path = this.beli.path();

  constructor(
    private _domSanador: DomSanitizer,
    private _crud: CrudService,
    private beli: BeliService,
  ) { }

  ngOnInit(): void {
    this.getData();
  }
 
  getData () {
    this.isLoading  = true;
    this.beli.get('page/indicadores_gestion').then( data => { 
      this.indicadores_gestion = data.success ? data.info : [];
      this.url = this.path + this.indicadores_gestion.src_documento;
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }

  displayTest(){
    setTimeout( () => { $("#preloader-indicadores")[0].style.display = "none"; }, 2500);
    setTimeout( () => { $("#iframe-indicadores-gestion")[0].style.display = "block"; }, 2500);
 }
}
