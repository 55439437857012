<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2><i routerLink="/" style="cursor:pointer" class="bx bx-arrow-back"> </i> Galería Audiovisual</h2>
        </div>
    </div>
</section>



<div class="galeria">

    <div id="preloader-audiovisual" class="preloader-iframe" *ngIf="isLoading"></div>

    <div class="contenedor-imagenes" id="video-gallery" [hidden]="isLoading">
        <a class="imagen" *ngFor="let item of videos" target="_blank" href="{{item.video_link}}" attr.data-poster="{{path + item.imagen_referencial}}">
            <img src="{{path + item.imagen_referencial}}" />
            <div class="overlay">
                <h3 >{{item.titulo}}</h3>
                <button class="btn btn-sm btn-overlay"> Ver video </button>
            </div> 
        </a>   
    </div>
</div>


<!--
<div class="galeria">
    <div class="contenedor-imagenes">
      <div class="imagen" *ngFor="let item of videos">
        <div  style="height: 16.5em;" (click) ="getData(item);" >
            <img *ngIf="!item.isVisible" src="{{path + item.imagen_referencial}}" alt="{{item.titulo}}">
            <video [hidden]="!item.isVisible" id="video-{{item.id}}" class="lg-video-object lg-html5" controls preload="none">
                <source src="{{path + item.src_video}}" type="video/mp4">
            </video>
            <div class="overlay" >
                <h3  [hidden]="item.isVisible">{{item.titulo}}</h3>
                <button class="btn btn-sm btn-overlay"> Ver video </button>
              </div> 
        </div>
        
      </div>    
    </div>
</div>

-->

<app-footer></app-footer>  