import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { BeliService } from 'src/app/services/beli.service';
import Swal from 'sweetalert2';
declare let $ : any;
@Component({
  selector: 'app-consulta-deuda',
  templateUrl: './consulta-deuda.component.html',
  styleUrls: ['./consulta-deuda.component.scss']
})
export class ConsultaDeudaComponent implements OnInit {
formModel;
isLoading: boolean = true;
isExists: boolean;
alert:boolean = true;
isDeuda: boolean;
respuesta : [];
deuda : [];
datos : any;
deudas : any;
nrosuministro : String;
propietario : String;
codsuc : String;
codciclo : String;
total : String;
recibo: String;
error : String;
url : String;
isSending = false;
response =  {
  success: false,
  message: ''
}

constructor(private formBuilder : FormBuilder, private beli: BeliService,) {
  this.formModel = this.formBuilder.group({
    numero_suministro: ['', [
      Validators.required,
      Validators.compose([Validators.required,
      Validators.max(999999999999999)]),
      Validators.pattern(/^[0-9]+(\.?[7-7]+)?$/)
    ]],
  });
 }

ngOnInit(): void {
  this.limpiar();
}

limpiar(){
  this.isLoading = true;
  this.formModel.reset();
}

get formValidate(){
  return this.formModel.controls;
}


getDeuda(){
  this.isLoading = true;
  this.beli.get("page/consulta_usuario?idsucursal=1&textbusqueda="+this.formModel.get('numero_suministro').value).then( data => {
    this.respuesta = data.success ? data.info : [];
    if(this.respuesta.length > 0){
      this.isLoading = false;
      this.isExists = true;
      this.alert = true;
      this.datos= Object.values(this.respuesta);
      this.codsuc = (this.datos[0]['codsuc']);
      this.codciclo = (this.datos[0]['codciclo']);
      localStorage.setItem('nrosuministro', this.formModel.get('numero_suministro').value);
      localStorage.setItem('codsuc', (this.datos[0]['codsuc']));
      localStorage.setItem('codciclo', (this.datos[0]['codciclo'])); 
      this.beli.get("page/consulta_deuda?idsucursal=1&textbusqueda="+this.formModel.get('numero_suministro').value).then( data => {
        this.deuda = data.success ? data.info : [];
        this.deudas= Object.values(this.deuda);
        this.isLoading = true;
        if(this.deudas.length > 0){
          this.isLoading = false;
          this.isDeuda = true;
          this.nrosuministro = (this.deudas[0]);
          this.propietario = (this.deudas[1]);
          this.recibo= (this.deudas[2]);
          this.total= (this.deudas[3]);
          this.url = "http://e-siincoweb.emapahuaral.com.pe/e-siincoweb/emapahuaral/consultas/duplicado.php?nroinscripcion="+this.nrosuministro+"&codsuc=1&codciclo=1&tipo=1"
          this.formModel.reset();
        }else{
          this.isLoading = false;
          this.isDeuda = false;
          this.formModel.reset();
        }

      })
      this.isLoading = true;
    }else{
      this.isLoading = false;
      this.isExists = false;
      this.alert = false;
      this.formModel.reset();
    }
})
.catch();
}

submit() {
  this.getDeuda();
  this.isSending = false;
}

autenticar():any {
  try {
    var formData: any = new FormData();
      formData.append("numero_suministro", this.formModel.get('numero_suministro').value);
    
  } catch (error) {

  }
}
descargarRecibo(){
  this.beli.get("http://e-siincoweb.emapahuaral.com.pe/e-siincoweb/emapahuaral/consultas/duplicado.php?nroinscripcion="+localStorage.getItem('nrosuministro')+"&codsuc=1&codciclo=1&tipo=1");

}

mostrarReciboDeEjemplo(){
  Swal.fire({
    text: 'Recibo de ejemplo',
    imageUrl: 'assets/img/inicio/solicitud-reclamos/reciboEjemplo.png',
    imageHeight: 500,
    imageWidth: 800
  })
}

}
