import { Component, OnInit,ViewChild, ElementRef  } from '@angular/core';
import { BeliService } from 'src/app/services/beli.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  url_consulta_deuda : String;

  
  hidden_portal : boolean = false;
  show_top : boolean = true;
  constructor(
    private beli: BeliService,
  ) { }

  ngOnInit(): void {
    
    this.getUrlConsultarDeuda();
  }

  getUrlConsultarDeuda() {
    this.beli.get('page/get_url_consulta_deuda')
    .then( data => {
      this.url_consulta_deuda = (data.success) ? data.info.url : 'undefined_url'; 
    })
    .catch();
  }
/*
  scroll(event: any) {
    if (event.target.scrollingElement.clientWidth < 760) {
        this.show_top = false;
    } else {
      this.show_top =true;
      if (event.target.scrollingElement.scrollTop > 160 )
        this.hidden_portal = true;
      else
        this.hidden_portal = false;
    }
  }
*/
}
