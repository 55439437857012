import { Component, OnInit } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { BeliService } from 'src/app/services/beli.service';

@Component({
  selector: 'app-gerente-linea',
  templateUrl: './gerente-linea.component.html',
  styleUrls: ['./gerente-linea.component.scss']
})
export class GerenteLineaComponent implements OnInit {

  isLoading: boolean = false;
  gerentesLinea :any;

  constructor(
    private beli: BeliService,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData () {
    this.isLoading  = true;
    this.beli.get('page/gerentes_linea').then( data => { 
      this.gerentesLinea = data.success ? data.info : [];
      this.isLoading = false;
    })
    .catch(() => {
      this.isLoading = false;
    });
  }


}
