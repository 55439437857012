import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { BeliService } from 'src/app/services/beli.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-solicitud-quejas',
  templateUrl: './solicitud-quejas.component.html',
  styleUrls: ['./solicitud-quejas.component.scss']
})
export class SolicitudQuejasComponent implements OnInit {
  
  formModel;
  isSending = false;
  isDisable:boolean;
  isDisableSearch : boolean = true;
  isExists:boolean;
  datos:any;
  mensaje : string;
  deshabilitado: boolean = true;
  message : string;
  codigo:any;
  codigo_ficha : string;
  valor : string;
  Ocultar:boolean = false;
  propietario : String;
  return : [];
  respuesta : [];
  response =  {
    success: false,
    message: ''
  }
  tipo_solicitud: Array<any> = [
    { nombre: 'QUEJA' },
    { nombre: 'SUGERENCIA'}
];
generar_codigo(){
  this.beli.get("page/generar_codigo").then( data => {
    this.respuesta = data.success ? data.info : [];
    this.codigo = Object.values(this.respuesta);
    this.codigo_ficha = this.codigo[0];
  })
  .catch();
}

tipo_documento = [
  {id:1,nombre: 'DNI'},
  {id:2,nombre: 'RUC'},
  {id:3,nombre: 'Ce'},
];

  constructor(private formBuilder : FormBuilder, private beli: BeliService,) { 
    
    this.formModel = this.formBuilder.group({
        cod_solicitud: [''],
        tipo_solicitud:[null, Validators.required],
        fecha: [this.formatDate()],
        cut_numero: ['', Validators.required],
        nombre: ['',[
          Validators.required,
          Validators.maxLength(60),
          Validators.pattern(/[A-Za-z0-9]{1,20}/),
          Validators.pattern(/^[a-z áãâäàéêëèíîïìóõôöòúûüùçñ]+$/i)
          ]
        ],
        propietario: ['', [
        ]],
        apellido_paterno: ['',[
          Validators.required,
          Validators.maxLength(60),
          Validators.pattern(/[A-Za-z0-9]{1,20}/),
          Validators.pattern(/^[a-z áãâäàéêëèíîïìóõôöòúûüùçñ]+$/i)
          ]
        ],
        apellido_materno: ['',[
          Validators.required,
          Validators.maxLength(60),
          Validators.pattern(/[A-Za-z0-9]{1,20}/),
          Validators.pattern(/^[a-z áãâäàéêëèíîïìóõôöòúûüùçñ]+$/i)
          ]  
        ],
        tipo_doc_identidad:[null,Validators.required],
        documento_identidad : ['',[
            Validators.required,
            Validators.pattern(/^[0-9]+(\.?[7-7]+)?$/)
          ]
        ],
        direccion: ['', Validators.required],
        telefono: [''],
        correo_electronico: ['',[
          Validators.required,
          Validators.maxLength(80),
          Validators.pattern(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
          )
        ]
      ],
        titulo_solicitud: ['', Validators.required],
        desc_solicitud: ['', Validators.required]
    });
  }
  
  ngOnInit(): void {
      this.generar_codigo();
  }
  get formValidate(){
    return this.formModel.controls;
  }

  submit() {
    this.sendForm(this.formModel.value);
    setTimeout(() =>{ 
      this.response.success = false;
    },10000);
  }
  
  autenticar():any {
    try {
      var formData: any = new FormData();
        formData.append("cod_solicitud", this.formModel.get('cod_solicitud').value);
        formData.append("tipo_solicitud", this.formModel.get('tipo_solicitante_id').value);
        formData.append("fecha", this.formModel.get('fecha').value);
        formData.append("cut_numero", this.formModel.get('cut_numero').value);
        formData.append("nombre", this.formModel.get('nombre').value);
        formData.append("apellido_paterno", this.formModel.get('apellido_paterno').value);
        formData.append("apellido_materno", this.formModel.get('apellido_materno').value);
        formData.append("direccion", this.formModel.get('direccion').value);
        formData.append("correo_electronico", this.formModel.get('correo_electronico').value);
        formData.append("titulo_solicitud", this.formModel.get('titulo_solicitud').value);
        formData.append("desc_solicitud", this.formModel.get('desc_solicitud').value);
      
      this.sendForm(formData);
        setTimeout(() =>{ 
          this.response.success = false;
        },10000);
    } catch (error) {

    }
  }
      
  sendForm (data) {
    this.isSending = true;
    console.log(data);
    this.beli.post('page/quejas_solicitud',data).then( data => { 
      this.response = data.success ? data : [];
      this.isSending = false;
      Swal.fire({
        icon: 'success',
        title: 'Su solicitud ha sido enviada',
        timer: 4000,
        showConfirmButton: false,
      }).then(function(){
        location.href = '/';
      });
      this.formModel.reset();
    })
  }
    
  formatDate() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  mostrarReciboDeEjemplo(){
    Swal.fire({
      text: 'Recibo de ejemplo',
      imageUrl: 'assets/img/inicio/solicitud-reclamos/reciboEjemplo.png',
      imageHeight: 500,
      imageWidth: 800
    })
  }

  validarSuministro(){
    if(this.formModel.get('cut_numero').value == ''){
      this.capturar();
    }else if (this.formModel.get('cut_numero').value == null){
      this.capturar();
    }else{
      this.capturar();
      this.beli.get("page/consulta_usuario?idsucursal=1&textbusqueda="+this.formModel.get('cut_numero').value).then( data => {
        this.respuesta = data.success ? data.info : [];
        if(this.respuesta.length > 0){
          Swal.fire({
            title: '<small><b>¡SUMINISTRO CORRECTO!</b></small>',
            icon: 'success',
            html: '<p style=font-family: Georgia, Times New Roman, Times, serif;>A continuación llene correctamente el formulario de solicitud de quejas o sugerencias.</p>',
              
            focusConfirm: false,
            confirmButtonText:
              '<i class="fa fa-thumbs-up"></i> Continuar',
            confirmButtonAriaLabel: 'Thumbs up, great!',
            cancelButtonText:
              '<i class="fa fa-thumbs-down"></i>',
            cancelButtonAriaLabel: 'Thumbs down'
          })
          this.datos= Object.values(this.respuesta);
          this.propietario = (this.datos[0]['propietario']);
          this.isExists = true;
          this.isDisable = true;
          this.Ocultar = true;
        }else{
          this.capturar();
          Swal.fire({
             title: '<small><b>¡ADVERTENCIA!</b></small>',
            icon: 'warning',
            html:
                '<p>El número de suministro ingresado <b>NO EXISTE!&nbsp;&nbsp;</b> Por favor asegurese de haber escrito correctamente su número de suministro y vuelva a intentarlo.</p>' +
                '<small><b>Para mayor información consulte al botón de ayuda <i class="fa fa-question-circle"></i></b></small>',
            allowOutsideClick: false,
            confirmButtonText:
              '<i class="fa fa-arrow-left"></i> Volver',
          })
          this.formModel.get('cut_numero').reset();
        }
    })
    .catch();
    }
  }

  capturar(){
    if(this.formModel.get('cut_numero').value == null){
       this.mensaje = 'Ingrese su número de suministro correctamente. Consulte el botón de ayuda al lado derecho.';
       this.isDisableSearch = true;
    }else if(this.formModel.get('cut_numero').value == ''){
       this.mensaje = 'Ingrese su número de suministro por favor.';
       this.isDisableSearch = true;
    }else{
      this.mensaje = '';
      this.isDisableSearch = false;
    }
  }

  capturarTipo(){
    if(this.formModel.get('tipo_doc_identidad').value == 'DNI'){
      this.valor = this.formModel.get('documento_identidad').value;
        if((this.valor.toString()).length < 8 || (this.valor.toString()).length > 8){
          this.message = 'El numero de DNI debe de contener 8 dígitos';
          this.deshabilitado = true;
        }else if(this.formModel.get('documento_identidad').value == null || this.formModel.get('documento_identidad').value == ''){
          this.message = 'Ingrese su número de DNI';
          this.deshabilitado = true;
        }
        else{
          this.message = '';
          this.deshabilitado = false;
        }
    }else if(this.formModel.get('tipo_doc_identidad').value == 'RUC'){
      this.valor = this.formModel.get('documento_identidad').value;
        if((this.valor.toString()).length < 11 || (this.valor.toString()).length > 11){
          this.message = 'El numero de RUC debe de contener 11 digitos';
          this.deshabilitado = true;        
       }else if(this.formModel.get('documento_identidad').value == null || this.formModel.get('documento_identidad').value == ''){
        this.message = 'Ingrese su número de RUC';
        this.deshabilitado = true;
       }
       else{
        this.message = '';
        this.deshabilitado = false;
       }
    }
  }

  capturarDni(){

    if(this.formModel.get('tipo_doc_identidad').value == null){
      this.message = 'Elija primero el tipo de documento';
      this.deshabilitado = true;
    }else if(this.formModel.get('tipo_doc_identidad').value == 'DNI'){
      if(this.formModel.get('documento_identidad').value == null || this.formModel.get('documento_identidad').value == ''){
        this.message = 'Ingrese su número de DNI';
        this.deshabilitado = true;
     }else{
        this.valor = this.formModel.get('documento_identidad').value;
        if((this.valor.toString()).length < 8 || (this.valor.toString()).length > 8){
          this.message = 'El numero de DNI debe de contener 8 dígitos';
          this.deshabilitado = true;
       }else{
        this.message = '';
        this.deshabilitado = false;
       }
     }
    }else if(this.formModel.get('tipo_doc_identidad').value == 'RUC'){
      this.message = ''
      if(this.formModel.get('documento_identidad').value == null || this.formModel.get('documento_identidad').value == ''){
        this.message = 'Ingrese su número de RUC';
        this.deshabilitado = true;
     }else{
        this.valor = this.formModel.get('documento_identidad').value;
        if((this.valor.toString()).length < 11 || (this.valor.toString()).length > 11){
          this.message = 'El numero de RUC debe de contener 11 digitos';
          this.deshabilitado = true;
          
       }else{
        this.message = '';
        this.deshabilitado = false;
       }
     }
    }else{
      this.message = '';
      this.deshabilitado = false;
    }
  }
}
