import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { BeliService } from 'src/app/services/beli.service';
import Swal from 'sweetalert2';
import { Gerencia,Oficina} from 'src/app/models/tipoGerencia.interface';
import { GerenciasServicesService} from 'src/app/services/gerencias-services.service';

@Component({
  selector: 'app-incidencias',
  templateUrl: './incidencias.component.html',
  styleUrls: ['./incidencias.component.scss'],
  providers:[GerenciasServicesService]
})
export class IncidenciasComponent implements OnInit {
  formModel;
  isSending = false;
  isDisable:boolean;
  isDisableSearch : boolean = true;
  isExists:boolean;
  datos:any;
  mensaje : string;
  deshabilitado: boolean = true;
  message : string;
  codigo:any;
  codigo_ficha : string;
  valor : string;
  Ocultar:boolean = true;
  Mostrar:boolean = true;
  propietario : String;
  return : [];
  respuesta : [];
  response =  {
    success: false,
    message: ''
  }
  tipo_solicitud: Array<any> = [
    { nombre: 'Baja' },
    { nombre: 'Media'},
    { nombre: 'Alta'}
];
generar_codigo(){
  this.beli.get("page/cod_incidencia").then( data => {
    this.respuesta = data.success ? data.info : [];
    this.codigo = Object.values(this.respuesta);
    this.codigo_ficha = this.codigo[0];
  })
  .catch();
}

tipo_documento = [
  {id:1,nombre: 'DNI'},
  {id:2,nombre: 'RUC'},
  {id:3,nombre: 'Ce'},
];
public selectedTipoReclamo:Gerencia={id:0, name:''};
public tipoReclamo:Gerencia[];
public subTipoReclamo:Oficina[];
  constructor(
    private formBuilder : FormBuilder, 
    private beli: BeliService,
    private dataTipoReclamos: GerenciasServicesService,
    ) { 
    
    this.formModel = this.formBuilder.group({
        cod_incidencia: [],
        fecha: [this.formatDate()],
        hora: [this.formatHour()],
        nombre: ['',[
          Validators.required,
          Validators.maxLength(60),
          Validators.pattern(/[A-Za-z0-9]{1,20}/),
          Validators.pattern(/^[a-z áãâäàéêëèíîïìóõôöòúûüùçñ]+$/i)
          ]
        ],
        gerencia:['',[Validators.required]],
        urgencia:[],
        oficina:[''],
        cargo: ['',[
          Validators.required,
          Validators.maxLength(60),
          Validators.pattern(/[A-Za-z0-9]{1,20}/),
          Validators.pattern(/^[a-z áãâäàéêëèíîïìóõôöòúûüùçñ]+$/i)
          ]  
        ],
        correo_electronico: ['',[
          Validators.required,
          Validators.maxLength(80),
          Validators.pattern(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
          )
        ]
      ],
        titulo: ['', Validators.required],
        descripcion: ['', Validators.required]
    });
  }
  
  ngOnInit(): void {
    this.tipoReclamo = this.dataTipoReclamos.getGerencias();
      this.generar_codigo();
  }
  get formValidate(){
    return this.formModel.controls;
  }

  onSelectTipoReclamo(id:number):void{
    this.subTipoReclamo=this.dataTipoReclamos.getOficinas()
    .filter(item=>item.gerenciaId==id);

   
        if(Object.keys(this.subTipoReclamo).length == 0){
          this.Mostrar = false;
        }else{
          this.Mostrar = true;
        }
   
  }

  submit() {
    this.sendForm(this.formModel.value);
    setTimeout(() =>{ 
      this.response.success = false;
    },10000);
  }
  
  autenticar():any {
    try {
      var formData: any = new FormData();
        formData.append("cod_incidencia", this.formModel.get('cod_incidencia').value);
        formData.append("urgencia", this.formModel.get('tipo_solicitante_id').value);
        formData.append("fecha", this.formModel.get('fecha').value);
        formData.append("cut_numero", this.formModel.get('cut_numero').value);
        formData.append("nombre", this.formModel.get('nombre').value);
        formData.append("cargo", this.formModel.get('cargo').value);
        formData.append("apellido_materno", this.formModel.get('apellido_materno').value);
        formData.append("direccion", this.formModel.get('direccion').value);
        formData.append("correo_electronico", this.formModel.get('correo_electronico').value);
        formData.append("titulo", this.formModel.get('titulo').value);
        formData.append("descripcion", this.formModel.get('descripcion').value);
      
      this.sendForm(formData);
        setTimeout(() =>{ 
          this.response.success = false;
        },10000);
    } catch (error) {

    }
  }

  generar(){
    this.Ocultar = false;
  }
      
  sendForm (data) {
    this.isSending = true;
    console.log(data);
    this.beli.post('page/incidencias_solicitud',data).then( data => { 
      this.response = data.success ? data : [];
      this.isSending = false;
      Swal.fire({
        icon: 'success',
        title: 'Su solicitud ha sido enviada',
        timer: 4000,
        showConfirmButton: false,
      }).then(function(){
        location.href = '/';
      });
      this.formModel.reset();
    })
  }
    
  formatDate() {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

 formatHour(){
  var d = new Date(),
  hora = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
  return [hora];
 }

}
