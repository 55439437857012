<app-header></app-header>

<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2  style="text-align: left;"> Solicitud de Reclamos </h2>
        </div>
    </div>
</section>

<section class="faq-area ptb-100 bg-f9f9f9" *ngIf="!Ocultar">
    <div class="container">
        <div class="container">
            <div class="container">
                <div class="alert alert-info alert-dismissable">
                    <button type="button" class="close" data-dismiss="alert"><b>x</b></button>
                    <i class="fas fa-exclamation-circle"></i> <strong style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif"><img src="assets/img/info.png" align="right" width="74px" alt="gotas"> ¡INFORMACIÓN!</strong>
                    <div class="container-sm">
                            <p style="font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; text-align: center;" class="mb-0">Estimado usuario, para acceder al formulario de <b>Solicitud de Reclamos</b> de EMAPA HUARAL S.A debemos validar su codigo de suministro, por favor ingrese su código de suministro y presione el botón <b>BUSCAR</b> para validar.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row my-4 justify-content-center">
            <div class="col-md-10 card shadow mx-2 border">
                <form class="border-form" [formGroup]="formModel" class="p-4">   
                    <div class="form-group row">            
                    <div class="col-sm-12">
                        <h6 style="font-family: Georgia, 'Times New Roman', Times, serif;" for="select-beast-selectized">NÚMERO DE SUMINISTRO </h6>
                    </div>
                    <div class="col-lg-11">
                        <div class="input-group">
                            <input type="number" class="form-control" 
                             formControlName="cut_numero" placeholder="Ingrese su número de suministro" [attr.disabled]="isDisable" (ngModelChange)="capturar()">
                             <button class="btn btn-secondary" type="button" [disabled]="isDisableSearch" (click)="validarSuministro()"> 
                                <span class="fas fa-search"></span> BUSCAR</button>
                            </div>
                        <small class="text-danger mt-1 px-2 py-1 rounded">
                            {{mensaje}}
                        </small>
                    </div>
                    <div class="col-lg-1">
                        <button type="button" class="btn btn-danger btn-sm borderReciboEjemplo"
                            (click)="mostrarReciboDeEjemplo()">
                            <span class="fa fa-question-circle fa-2x"></span></button>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>
</section>

<section class="doctors-area pt-100 pb-70" *ngIf="Ocultar">
    <div class="container">
        <div class="col-md-12">
            <div class="shadow mx-2 border border-blue border-darken-4 black">
                <!------- CABECERA ------->
                <div class="card-header card-head-inverse bg-blue bg-darken-4" style="text-align: center;">
                    <br>
                    <h4 class="card-title" style="font-family: Georgia, 'Times New Roman', Times, serif;"> FORMATO PARA LA PRESENTACIÓN DE RECLAMOS - EMAPA HUARAL S.A.</h4>
                </div>
                <!------- CUERPO --------->
                <div class="card-content collapse show">
                    <div class="card-body">
                        <div class="card-text" style="text-align: center;">
                            <small><b>*Llene los datos de este formulario cuidadosamente y con información fidedigna, para enviar correctamente su solicitud de quejas o sugerencias.</b></small>
                        </div>
                        <br>
                        <form class="border-form" (ngSubmit)="autenticar()" [formGroup]="formModel" id="solicitudForm">
                            <div class="form-body">
                                <fieldset  class="fieldset" style="border: 1px solid #088A85;">
                                    <legend class="legend-border"><h5 class="form-section" style="font-family: Georgia, 'Times New Roman', Times, serif;"><i class="far fa-file"></i> DATOS DE LA SOLICITUD</h5></legend>
                                    <div class="row">
                                        <!-- Número de Ficha de reclamo -->
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;">
                                                    FICHA NUMERO:
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true"> 
                                                    <span class="fas fa-hashtag fa-1x" style="color: #000;"></span></button><input type="text" [attr.disabled]="true" class="form-control" value="{{codigo_ficha}}" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Fecha del registro del reclamo -->
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;">
                                                    FECHA DEL REGISTRO:
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true"> 
                                                    <span class="fas fa-calendar fa-1x" style="color: #000;"></span></button><input type="date" [attr.disabled]="true" class="form-control" 
                                                    formControlName="fecha" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Número de suministro -->
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;">
                                                    CODIGO DE SUMINISTRO:
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true"> 
                                                    <span class="fas fa-list-ol fa-1x" style="color: #000;"></span></button><input type="number" class="form-control" 
                                                    formControlName="cut_numero" placeholder="N° de suministro" [attr.disabled]="isDisable" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Titular del recibo -->
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;">
                                                    TITULAR DEL RECIBO:
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true"> 
                                                    <span class="fas fa-user fa-1x" style="color: #000;"></span></button><input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="text" class="form-control" formControlName="propietario" [ngModel]="propietario"
                                                    [attr.disabled]="isDisable">
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </fieldset>
                                <br>

                                <!--I DATOS PERSONALES DEL SOLICITANTE -->
                                <fieldset  class="fieldset" style="border: 1px solid #088A85;">
                                    <legend class="legend-border"><h5 class="form-section" style="font-family: Georgia, 'Times New Roman', Times, serif;"><i class="far fa-user"></i> DATOS PERSONALES DEL SOLICITANTE</h5></legend>
                                    <small><b> *Los campos marcados con (*) son obligatorios. </b></small>
                                    <br>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="colFormLabelSm" style="font-family: Georgia, 'Times New Roman', Times, serif;">
                                                    TIPO DE SOLICITANTE
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true"> 
                                                    <span class="fas fa-address-book fa-1x" style="color: #000;"></span></button>
                                                    <select formControlName="tipo_solicitante_id" class="form-control" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
                                                        <option disabled value="null" selected> -- SELECCIONE TIPO DE SOLICITANTE -- </option>
                                                        <option *ngFor="let item of tipo_solicitante; let i=index" [ngValue]="item.id">
                                                            {{i+1}}. {{item.nombre}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.tipo_solicitante_id.dirty || formValidate.tipo_solicitante_id.touched) && formValidate.tipo_solicitante_id.errors">
                                                <ng-container [ngSwitch]="formValidate.tipo_solicitante_id.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        El tipo de solicitante es abligatorio
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Tipo de Solicitante inválido
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="colFormLabelSm" style="font-family: Georgia, 'Times New Roman', Times, serif;">
                                                    TIPO DE DOCUMENTO
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true"> 
                                                    <span class="fas fa-address-book fa-1x" style="color: #000;"></span></button>
                                                <select style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" formControlName="documento_identidad_id" class="form-control" (ngModelChange)="capturarTipo()">
                                                    <option disabled value="null" selected> -- SELECCIONE TIPO DE DOCUMENTO-- </option>
                                                    <option *ngFor="let item of tipo_documento ; let i = index" [ngValue]="item.id">
                                                        {{item.nombre }}
                                                    </option>
                                                </select>
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.documento_identidad_id.dirty || formValidate.documento_identidad_id.touched) && formValidate.documento_identidad_id.errors">
                                                <ng-container [ngSwitch]="formValidate.documento_identidad_id.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        Por favor seleccione el tipo de documento.
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Por favor seleccione el tipo de documento.
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    NUMERO DE DOCUMENTO
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true"> 
                                                        <span class="fas fa-address-card fa-1x" style="color: #000;"></span></button>
                                                    <input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="number" class="form-control" 
                                                    formControlName="documento_identidad" placeholder="Ingrese su número de documento" (ngModelChange)="capturarDni()">
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded">
                                                    {{message}}
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6" *ngIf="this.formModel.get('documento_identidad_id').value == '2'">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    RAZÓN SOCIAL
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="text" class="form-control" 
                                                    formControlName="razon_social" placeholder="Ingrese su razón social">
                                                    <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.razon_social.dirty || formValidate.razon_social.touched) && formValidate.razon_social.errors">
                                                    <ng-container [ngSwitch]="formValidate.razon_social.value">
                                                        <ng-container *ngSwitchCase="''">
                                                            La razon social es abligatoria
                                                        </ng-container>
                                                        <ng-container *ngSwitchDefault>
                                                            Razon social Inválido
                                                        </ng-container>
                                                    </ng-container>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    NOMBRES
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="text" class="form-control" 
                                                    formControlName="nombres" placeholder="Ingrese su nombre completo">
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.nombres.dirty || formValidate.nombres.touched) && formValidate.nombres.errors">
                                                <ng-container [ngSwitch]="formValidate.nombres.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        Los nombres son abligatorios
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Nombre inválido
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    APELLIDO PATERNO
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <input type="text" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" class="form-control" formControlName="apellido_paterno" placeholder="Ingrese su apellido paterno">
                                                <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.apellido_paterno.dirty || formValidate.apellido_paterno.touched) && formValidate.apellido_paterno.errors">
                                                <ng-container [ngSwitch]="formValidate.apellido_paterno.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        El apellido paterno es obligatorio.
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Apellido paterno inválido.
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    APELLIDO MATERNO
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <input type="text" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" class="form-control" formControlName="apellido_materno" placeholder="Ingrese su apellido materno" >
                                                <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.apellido_materno.dirty || formValidate.apellido_materno.touched) && formValidate.apellido_materno.errors">
                                                    <ng-container [ngSwitch]="formValidate.apellido_materno.value">
                                                        <ng-container *ngSwitchCase="''">
                                                            El apellido materno es obligatorio.
                                                        </ng-container>
                                                        <ng-container *ngSwitchDefault>
                                                            Apellido materno inválido.
                                                        </ng-container>
                                                    </ng-container>
                                                </small>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    CORREO ELECTRONICO
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true"> 
                                                    <span class="fas fa-envelope fa-1x" style="color: #000;"></span></button>
                                                    <input type="email" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" class="form-control" formControlName="email" placeholder="Ingrese su correo electrónico">
                                                </div>
                                                    <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.email.dirty || formValidate.email.touched) && formValidate.email.errors">
                                                        <ng-container [ngSwitch]="formValidate.email.value">
                                                            <ng-container *ngSwitchCase="''">
                                                                El correo electrónico es obligatorio.
                                                            </ng-container>
                                                             <ng-container *ngSwitchDefault>
                                                                Correo electronico es incorrecto.
                                                            </ng-container>
                                                        </ng-container>
                                                    </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    CELULAR
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true"> 
                                                    <span class="fas fa-mobile fa-1x" style="color: #000;"></span></button>
                                                <input type="number" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" class="form-control" formControlName="celular" placeholder="Ingrese su numero de celular">
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.celular.dirty || formValidate.celular.touched) && formValidate.celular.errors">
                                            <ng-container [ngSwitch]="formValidate.celular.value">
                                                <ng-container *ngSwitchCase="''">
                                                     El N° de celular es abligatorio
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    Numero incorrecto
                                                </ng-container>
                                            </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    TELEFONO
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <button class="btn btn-outline-secondary btn-sm" type="button" [attr.disabled]="true"> 
                                                    <span class="fas fa-mobile fa-1x" style="color: #000;"></span></button>
                                                <input type="number" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" class="form-control" formControlName="telefono" placeholder="Ingrese su numero de telefono">
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="formValidate.telefono.errors">
                                                    <ng-container >
                                                        Numero incorrecto
                                                    </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <br>

                                <!--II UBICACION DEL PREDIO -->
                                <fieldset  class="fieldset" style="border: 1px solid #088A85;">
                                    <legend class="legend-border"><h5 class="form-section" style="font-family: Georgia, 'Times New Roman', Times, serif;"><i class="fas fa-search-location"></i> UBICACION DEL PREDIO <button type="button" class="btn btn-danger btn-sm borderInfo"
                                        (click)="helpUbicacionPredio()">
                                        <span class="fa fa-info"></span>
                                    </button></h5> </legend>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    PROVINCIA
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="text" class="form-control" 
                                                    formControlName="provincia_predio" placeholder="Ingrese provincia">
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.provincia_predio.dirty || formValidate.provincia_predio.touched) && formValidate.provincia_predio.errors">
                                                <ng-container [ngSwitch]="formValidate.provincia_predio.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        La provincia es abligatoria
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Provincia incorrecta
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    DISTRITO
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="text" class="form-control" 
                                                    formControlName="distrito_predio" placeholder="Ingrese distrito">
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.distrito_predio.dirty || formValidate.distrito_predio.touched) && formValidate.distrito_predio.errors">
                                                <ng-container [ngSwitch]="formValidate.distrito_predio.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        El Distriro es abligatorio
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Distriro incorrecto
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    DIRECCION
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="text" class="form-control" 
                                                    formControlName="direccion_predio" placeholder="Ingrese direccion">
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.direccion_predio.dirty || formValidate.direccion_predio.touched) && formValidate.direccion_predio.errors">
                                                <ng-container [ngSwitch]="formValidate.direccion_predio.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        La direccion es abligatoria
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Direccion Inválida
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    TIPO (Av,Jr,Urb,etc)
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="text" class="form-control" 
                                                    formControlName="tipo_direccion_predio" placeholder="Tipo de direccion">
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.tipo_direccion_predio.dirty || formValidate.tipo_direccion_predio.touched) && formValidate.tipo_direccion_predio.errors">
                                                <ng-container [ngSwitch]="formValidate.tipo_direccion_predio.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        El Tipo de direccion es abligatorio
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Tipo de direccion incorrecto
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    N°
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="text" class="form-control" 
                                                    formControlName="n_direccion_predio" placeholder="Ingrese el n° de domicilio"> 
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.n_direccion_predio.dirty || formValidate.n_direccion_predio.touched) && formValidate.n_direccion_predio.errors">
                                                <ng-container [ngSwitch]="formValidate.n_direccion_predio.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        El N° es abligatorio
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        N° Inválido
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    Mz.
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="text" class="form-control" 
                                                    formControlName="mz_predio" placeholder="Ingrese n° de manzana">
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.mz_predio.dirty || formValidate.mz_predio.touched) && formValidate.mz_predio.errors">
                                                <ng-container [ngSwitch]="formValidate.mz_predio.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        La Mz. es abligatoria
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Mz. incorrecta
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    LOTE
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="text" class="form-control" 
                                                    formControlName="lote_predio" placeholder="Ingrese el Lote"> 
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.lote_predio.dirty || formValidate.lote_predio.touched) && formValidate.lote_predio.errors">
                                                <ng-container [ngSwitch]="formValidate.lote_predio.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        El lote es abligatorio
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Lote Inválido
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <br>

                                <!--II UBICACION DEL PREDIO -->
                                <fieldset  class="fieldset" style="border: 1px solid #088A85;">
                                    <legend class="legend-border"><h5 class="form-section" style="font-family: Georgia, 'Times New Roman', Times, serif;"><i class="fas fa-location-arrow"></i> DOMICILIO PROCESAL <button type="button" class="btn btn-danger btn-sm borderInfo"
                                        (click)="helpDomicilioProcesal()">
                                        <span class="fa fa-info"></span>
                                    </button> </h5></legend>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    PROVINCIA
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="text" class="form-control" 
                                                    formControlName="provincia_procesal" placeholder="ingrese la Provincia">
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.provincia_procesal.dirty || formValidate.provincia_procesal.touched) && formValidate.provincia_procesal.errors">
                                                <ng-container [ngSwitch]="formValidate.provincia_procesal.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        La provincia es abligatoria
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Provincia incorrecta
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    DISTRITO
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="text" class="form-control" 
                                                    formControlName="distrito_procesal" placeholder="Ingrese el Distrito">
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.distrito_procesal.dirty || formValidate.distrito_procesal.touched) && formValidate.distrito_procesal.errors">
                                                <ng-container [ngSwitch]="formValidate.distrito_procesal.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        El distrito es obligatorio
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        El distrito es incorrecto
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    DIRECCION
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="text" class="form-control" 
                                                    formControlName="direccion_procesal" placeholder="Ingrese Direccion Procesal"> 
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.direccion_procesal.dirty || formValidate.direccion_procesal.touched) && formValidate.direccion_procesal.errors">
                                                <ng-container [ngSwitch]="formValidate.direccion_procesal.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        La direccion es abligatoria
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Direccion Inválida
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    TIPO(Av,Jr,Urb,etc)
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="text" class="form-control" 
                                                    formControlName="tipo_direccion_procesal" placeholder="Ingrese el Tipo de Direccion">
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.tipo_direccion_procesal.dirty || formValidate.tipo_direccion_procesal.touched) && formValidate.tipo_direccion_procesal.errors">
                                                <ng-container [ngSwitch]="formValidate.tipo_direccion_procesal.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        El Tipo de Direccion es abligatorio
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Tipo de Direccion incorrecto
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    N°
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="text" class="form-control" 
                                                    formControlName="n_direccion_procesal" placeholder="Ingrese N°"> 
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.n_direccion_procesal.dirty || formValidate.n_direccion_procesal.touched) 
                                                && formValidate.n_direccion_procesal.errors"
                                                >
                                                <ng-container [ngSwitch]="formValidate.n_direccion_procesal.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        El N° es abligatorio
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        N° Inválido
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    Mz
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="text" class="form-control" 
                                                    formControlName="mz_procesal" placeholder="Manzana">
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.mz_procesal.dirty || formValidate.mz_procesal.touched) 
                                                && formValidate.mz_procesal.errors"
                                                >
                                                <ng-container [ngSwitch]="formValidate.mz_procesal.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        La Mz es abligatoria
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Mz incorrecta
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    LOTE
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="text" class="form-control" 
                                                    formControlName="lote_procesal" placeholder="Ingrese Lote">
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.lote_procesal.dirty || formValidate.lote_procesal.touched) 
                                                && formValidate.lote_procesal.errors"
                                                >
                                                <ng-container [ngSwitch]="formValidate.lote_procesal.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        El lote es abligatorio
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Lote Inválida
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                    CODIGO POSTAL
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" type="text" class="form-control" 
                                                    formControlName="cod_postal_procesal" placeholder="Codigo Postal">
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.cod_postal_procesal.dirty || formValidate.cod_postal_procesal.touched) 
                                                && formValidate.cod_postal_procesal.errors"
                                                >
                                                <ng-container [ngSwitch]="formValidate.cod_postal_procesal.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        El Codigo Postal es abligatorio
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Codigo Postal incorrecto
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <br>

                                <!--IV DOCUMENTOS ADJUNTOS -->
                                <fieldset  class="fieldset" style="border: 1px solid #088A85;">
                                    <legend class="legend-border"><h5 class="form-section" style="font-family: Georgia, 'Times New Roman', Times, serif;"><i class="far fa-file-pdf"></i> DOCUMENTOS ADJUNTOS (Solo PDF) </h5></legend>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                        COPIA DE DNI (Ambas caras)
                                                    <span class="advertenciaSolRec">
                                                        (*)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input  type="file" class="form-control"  formControlName="copia_dni_validar" (change)="onFileChange($event)" accept="application/pdf" class="textoAchivoSelecionado">
                                                        <label for="" class="col-sm-9 col-form-label sizeFile">
                                                            (2Mb maximos)
                                                        </label>
                                                    <ng-container *ngIf="invalidFile; else isSending">
                                                     <small class="text-danger mt-1 px-2 py-1 rounded">
                                                    <ng-container >
                                                        No es un archivo pdf o excede el tamaño requerido
                                                    </ng-container>
                                                </small> 
                                                    </ng-container>
                                                     <small class="text-danger mt-1 px-2 py-1 rounded"
                                                        *ngIf="(formValidate.copia_dni_validar.dirty || formValidate.copia_dni_validar.touched) 
                                                            && formValidate.copia_dni_validar.errors">
                                                        <ng-container [ngSwitch]="formValidate.copia_dni_validar.value">
                                                         <ng-container *ngSwitchCase="''">
                                                             La copia de DNI es abligatoria
                                                         </ng-container>
                                                        </ng-container>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                        CONTRATO DE ALQUILER VIGENTE
                                                    <small class="advertencia">
                                                        (Solo usuarios efectivos del servicio)
                                                    </small>
                                                </label>
                                                <div class="input-group">
                                                    <input type="file" formControlName="contrato_alquiler_validar" (change)="onFileContratoAlquiler($event)" accept="application/pdf" class="textoAchivoSelecionado">
                                                        <label for="" class="col-sm-9 col-form-label sizeFile">
                                                            (2Mb maximos)
                                                        </label>
                                                </div>
                                                <ng-container *ngIf="invalidContrato; else isSending">
                                                    <small class="text-danger mt-1 px-2 py-1 rounded">
                                                            <ng-container >
                                                                Solo subir archivos PDF
                                                            </ng-container>
                                                    </small> 
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                        DECLARACION JURADA
                                                    <span class="advertencia">
                                                        (Solo usuarios efectivos del servicio)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input type="file" formControlName="declaracion_jurada_validar" (change)="onFileDeclaracionJurada($event)" accept="application/pdf" class="textoAchivoSelecionado">
                                                        <label for="" class="col-sm-9 col-form-label sizeFile">
                                                            (2Mb maximos)
                                                        </label>
                                                </div>
                                                <ng-container *ngIf="invalidDeclaracion; else isSending">
                                                    <small class="text-danger mt-1 px-2 py-1 rounded">
                                                        <ng-container >
                                                            Solo subir archivos PDF
                                                        </ng-container>
                                                    </small> 
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif;" for="colFormLabelSm">
                                                        MEDIO PROBATORIO
                                                    <span class="advertencia">
                                                        (Opcional)
                                                    </span>
                                                </label>
                                                <div class="input-group">
                                                    <input  type="file" formControlName="medio_probatorio_validar" (change)="onFileMedioProbatorio($event)" accept="application/pdf" class="textoAchivoSelecionado">
                                                        <label for="" class="col-sm-9 col-form-label sizeFile">
                                                            (2Mb maximos)
                                                        </label>
                                                </div>
                                                <ng-container *ngIf="invalidMedioProbatorio; else isSending">
                                                    <small class="text-danger mt-1 px-2 py-1 rounded">
                                                        <ng-container >
                                                            Solo subir archivos PDF
                                                        </ng-container>
                                                    </small>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <br>

                                <!--II DETALLES DEL RECLAMO -->
                                <fieldset  class="fieldset" style="border: 1px solid #088A85;">
                                    <legend class="legend-border"><h5 class="form-section" style="font-family: Georgia, 'Times New Roman', Times, serif;"><i class="far fa-file-word"></i> DETALLES DEL RECLAMO </h5></legend>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif; text-align: center;" for="colFormLabelSm">
                                                    INFORMACION DE RECLAMOS
                                                    <button type="button" class="btn btn-succes btn-sm borderInfo" (click)="helpDetalleReclamo()">
                                                        <span class="fa fa-info"></span>
                                                    </button>
                                                </label>
                                                <div class="input-group" style="text-align: center;">
                                                    <button type="button" class="btn btn-outline-info  btn-lg btn-block fa fa-info" (click)="helpListaReclamos()">
                                                        Lista de Problemas
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif; text-align: center;" for="colFormLabelSm">
                                                    TIPO DE PROBLEMA
                                                    <button type="button" class="btn btn-danger btn-sm borderInfo" (click)="helpReclamoRojo()">
                                                        <span class="fa fa-info"></span>
                                                    </button>
                                                </label>
                                                <div class="input-group" style="text-align: center;">
                                                    <select formControlName="tipo_reclamo"  class="form-control" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" data-size="5" (change)="onSelectTipoReclamo($event.target.value)">
                                                        <option value="" disabled selected>Seleccione el problema</option>
                                                        <option *ngFor="let item of tipoReclamo" [value]="item.id" >
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.tipo_reclamo.dirty || formValidate.tipo_reclamo.touched) && formValidate.tipo_reclamo.errors">
                                                <ng-container [ngSwitch]="formValidate.tipo_reclamo.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        El tipo de Reclamo es abligatorio
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Tipo de Reclamo inválido
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif; text-align: center;" for="colFormLabelSm">
                                                    TIPO DE RECLAMO
                                                    <button type="button" class="btn btn-danger btn-sm borderInfo" (click)="helpReclamoAmarillo()">
                                                        <span class="fa fa-info"></span>
                                                    </button>
                                                </label>
                                                <div class="input-group" style="text-align: center;" *ngIf="subTipoReclamo?.length<1">
                                                    <span class="textoAchivoSelecionado">
                                                        Primero seleccione el Reclamo
                                                    </span>
                                                </div>
                                                <div class="input-group" style="text-align: center;" *ngIf="subTipoReclamo?.length>0">
                                                    <select formControlName="cod_reclamo" data-size="5" class="form-control" style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif">
                                                        <option value="" disabled selected>Seleccione el tipo de Reclamo</option>
                                                        <option *ngFor="let item of subTipoReclamo" [value]="item.id" title="Tipos">
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded"
                                                *ngIf="(formValidate.cod_reclamo.dirty || formValidate.cod_reclamo.touched) 
                                                && formValidate.cod_reclamo.errors"
                                                >
                                                <ng-container [ngSwitch]="formValidate.cod_reclamo.value">
                                                    <ng-container *ngSwitchCase="''">
                                                        Seleccione Reclamo es abligatorio
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        Seleccion de Reclamo inválido
                                                    </ng-container>
                                                </ng-container>
                                            </small>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label style="font-family: Georgia, 'Times New Roman', Times, serif; text-align: center;" for="colFormLabelSm">
                                                    DESCRIPCION DEL RECLAMO
                                                </label>
                                                <div class="input-group">
                                                    <textarea class="form-control form-control-sm" name="" id="" cols="6" rows="6" formControlName="descripcion_problema" placeholder="Ingrese la descripcion del Reclamo"></textarea>
                                                </div>
                                                <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.descripcion_problema.dirty || formValidate.descripcion_problema.touched) && formValidate.descripcion_problema.errors">
                                                    <ng-container [ngSwitch]="formValidate.descripcion_problema.value">
                                                        <ng-container *ngSwitchCase="''">
                                                            La descripción del reclamo es abligatoria
                                                        </ng-container>
                                                        <ng-container *ngSwitchDefault>
                                                            Texto incorrecto o limite excedido
                                                        </ng-container>
                                                    </ng-container>
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <br>

                                <!--II DETALLES DEL RECLAMO -->
                                <fieldset  class="fieldset" style="border: 1px solid #088A85;">
                                    <div class="row">
                                        <div class="col-md-12" style="text-align: center;">
                                            <div class="form-group">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" name="active" required
                                                    id="flexCheckIndeterminate" formControlName="aceptar_notificacion" >
                                                    <label class="form-check-label" for="flexCheckIndeterminate"  style="font-family: Georgia, 'Times New Roman', Times, serif;">
                                                        ACEPTO SER NOTIFICADO(A) AL CORREO ELECTRÓNICO INDICADO EN LA PRESENTE SOLICITUD
                                                        <span class="advertenciaSolRec">
                                                            *
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <small class="text-danger mt-1 px-2 py-1 rounded" *ngIf="(formValidate.aceptar_notificacion.dirty || formValidate.aceptar_notificacion.touched) && formValidate.aceptar_notificacion.errors">
                                            <ng-container [ngSwitch]="formValidate.aceptar_notificacion.value">
                                                <ng-container *ngSwitchCase="''">
                                                    Campo Obligatorio
                                                </ng-container>
                                            </ng-container>
                                        </small>
                                        </div>
                                    </div>
                                </fieldset>
                                <br>

                                <fieldset class="fieldset advertenciaSolRec"  style="font-family: Georgia, 'Times New Roman', Times, serif;">
                                    <ul>
                                        <li>
                                            Solicito que las notificaciones de los actos administrativos 
                                            del presente procedimiento de reclamo se realicen en 
                                            la dirección de correo electrónico consignado para lo cual brindo mi autorización expresa.
                                        </li>
                                    </ul>
                                </fieldset>
                                <br>
                                
                                <fieldset class="fieldset fieldsetBoton ">
                                    <div class="form-group row" >
                                        <div class="col-sm-12 text-center">
                                            <button *ngIf="!isSending" type="submit" class="btn btn-info botonEnv" 
                                            data-toggle="tooltip" data-placement="top" title="Completa todos los campos"
                                                [disabled]="formModel.invalid || deshabilitado" > Enviar                                 
                                            </button>
                                            <button *ngIf="isSending" class="btn btn-info botonEnv " 
                                                [disabled]="isSending"  routerLink="/solicitud_reclamaciones_pdf"> Enviar 
                                            </button>
                                        </div>
                                    </div> 
                                </fieldset>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>
